import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { FiSettings } from 'react-icons/fi';
import { RiArrowDownSLine, RiLogoutCircleLine, RiCouponLine } from 'react-icons/ri';
import { AiOutlineClose } from 'react-icons/all';

import ProfilePicture from '../ProfilePicture';

import { initializeUserSettingState, logOut } from '../../redux/user/UserAction';

import CONSTANTS from '../../utils/constants/CONSTANTS';

import './index.scss';

const ProfileIcon = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.user.currentUser);
  const userType = useSelector((state) => state.user.userType);

  const [showDropDown, setShowDropDown] = useState(false);

  /** separating out the profile and then extracting the gender and the profilePicture from it */
  const profile = currentUser ? currentUser.profile : null;
  const gender = profile ? profile.sex : null;
  const profilePicture = profile ? profile.profile_picture : null;
  const settingsUrl = currentUser?.user_type === CONSTANTS.CLIENT ? '/user/profile' : '/dashboard/settings';

  /** sign out function that sets the user to null so that a person becomes logged out */
  const signOut = () => dispatch(logOut());

  const initializeSettingsPage = () => {
    dispatch(initializeUserSettingState());
    setShowDropDown(!showDropDown);
  };

  const handleClose = () => {
    setShowDropDown(false);
  };

  return (
    <div className="profile-icon">
      <ProfilePicture gender={gender} profilePicture={profilePicture} />
      <div className="user-options">
        <RiArrowDownSLine style={CONSTANTS.BIG_ICON} onClick={() => setShowDropDown(!showDropDown)} />
        {showDropDown ? (
          <div className="drop-down">
            <div className="close-btn">
              <AiOutlineClose style={CONSTANTS.ICON_STYLES} onClick={handleClose} />
            </div>
            <h1 className="heading">User Options</h1>

            <Link to={settingsUrl} onClick={initializeSettingsPage}>
              <div className="option">
                <FiSettings style={CONSTANTS.ICON_STYLE_SPACE} />
                Account Settings
              </div>
            </Link>
            {userType === CONSTANTS.ADMIN ? (
              <Link to="/dashboard/couponCodes" onClick={initializeSettingsPage}>
                <div className="option">
                  <RiCouponLine style={CONSTANTS.ICON_STYLE_SPACE} />
                  Coupon Codes
                </div>
              </Link>
            ) : null}

            <div className="option" onClick={signOut}>
              <RiLogoutCircleLine style={CONSTANTS.ICON_STYLE_SPACE} />
              Logout
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProfileIcon;
