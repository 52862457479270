import CreateWorkoutActionTypes from './WorkoutActionTypes';

const INITIAL_STATE = {
  currentWorkout: null,
  allWorkout: {},
  isFetching: false,
  errorMessage: null,
  updateWorkoutError: null,
  saveWorkout: false,
  showPopup: false,
  popupData: {},
};

const WorkoutReducer = (state = INITIAL_STATE, action = { type: '' }) => {
  switch (action.type) {
    case CreateWorkoutActionTypes.SET_CURRENT_WORKOUT:
      return {
        ...state,
        currentWorkout: action.payload,
      };
    case CreateWorkoutActionTypes.FETCH_ALL_WORKOUT_START:
      return {
        ...state,
        isFetching: true,
      };
    case CreateWorkoutActionTypes.FETCH_ALL_WORKOUT_SUCCESS:
      return {
        ...state,
        allWorkout: action.payload.reduce((object, workout) => {
          return { ...object, [workout.uuid]: workout };
        }, {}),
        isFetching: false,
        errorMessage: null,
      };
    case CreateWorkoutActionTypes.FETCH_ALL_WORKOUT_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case CreateWorkoutActionTypes.DELETE_CURRENT_WORKOUT_START:
      return {
        ...state,
        isDeleting: true,
      };
    case CreateWorkoutActionTypes.DELETE_CURRENT_WORKOUT_SUCCESS:
      return {
        ...state,
        currentWorkout: null,
        isDeleting: false,
        allWorkout: action.payload,
      };
    case CreateWorkoutActionTypes.DELETE_CURRENT_WORKOUT_FAILURE:
      return {
        ...state,
        isDeleting: false,
        errorMessage: action.payload,
      };
    case CreateWorkoutActionTypes.SET_SAVE_WORKOUT:
      return {
        ...state,
        saveWorkout: action.payload,
      };
    case CreateWorkoutActionTypes.UPDATE_OR_ADD_NEW_WORKOUT:
      return {
        ...state,
        updateWorkoutError: null,
        allWorkout: action.payload,
      };
    case CreateWorkoutActionTypes.SET_SHOW_POPUP:
      return {
        ...state,
        showPopup: action.payload,
      };
    case CreateWorkoutActionTypes.UPDATE_WORKOUT_FAILURE:
      return {
        ...state,
        updateWorkoutError: action.payload,
      };
    case CreateWorkoutActionTypes.SET_POPUP_DATA:
      return {
        ...state,
        popupData: action.payload,
      };
    case CreateWorkoutActionTypes.INITIALIZE_WORKOUT_STATE:
      return {
        currentWorkout: null,
        allWorkout: {},
        isFetching: false,
        errorMessage: null,
        updateWorkoutError: null,
        saveWorkout: false,
        showPopup: false,
      };
    default:
      return state;
  }
};

export default WorkoutReducer;
