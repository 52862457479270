import SendPlanActionTypes from './SendPlanActionTypes';

import * as API from '../api';

import URLS from '../../utils/constants/URLS';
import CONSTANTS from '../../utils/constants/CONSTANTS';

import { fetchAllClientsStartAsync, fetchSingleClientStartAsync } from '../clients/ClientAction';
import { setSendPlanState } from '../workoutBuilder/WorkoutBuilderAction';

export const setSendPlanToClient = (state) => ({
  type: SendPlanActionTypes.SEND_NEW_PLAN_TO_CLIENT,
  payload: state,
});

export const setSelectedClientList = (clients) => ({
  type: SendPlanActionTypes.SET_SELECTED_CLIENT_LIST,
  payload: clients,
});

export const setCurrentPlanToBeSentToClient = (plan) => ({
  type: SendPlanActionTypes.SET_CURRENT_PLAN_TO_SEND,
  payload: plan,
});

export const setLaterPlanToBeSentToClient = (plan) => ({
  type: SendPlanActionTypes.SET_LATER_PLAN_TO_SEND,
  payload: plan,
});

export const setIsCurrentPlanAdded = (state) => ({
  type: SendPlanActionTypes.IS_CURRENT_PLAN_ADDED,
  payload: state,
});

export const setIsLaterPlanAdded = (state) => ({
  type: SendPlanActionTypes.IS_LATER_PLAN_ADDED,
  payload: state,
});

export const initializeSendPlanState = () => ({
  type: SendPlanActionTypes.INITIALIZE_SEND_PLAN_STATE,
});

export const setLastPage = (pageName) => ({
  type: SendPlanActionTypes.SET_LAST_PAGE,
  payload: pageName,
});

export const setShowDeletePopup = (state) => ({
  type: SendPlanActionTypes.SET_SHOW_DELETE_POPUP,
  payload: state,
});

export const deleteLaterPlanStart = () => ({
  type: SendPlanActionTypes.DELETE_LATER_PLAN_START,
});

export const deleteLaterPlanSuccess = (state) => ({
  type: SendPlanActionTypes.DELETE_LATER_PLAN_SUCCESS,
  payload: state,
});

export const deleteLaterPlanFailure = (state) => ({
  type: SendPlanActionTypes.DELETE_LATER_PLAN_FAILURE,
  payload: state,
});

export const setPopUpData = (state) => ({
  type: SendPlanActionTypes.SET_POPUP_DATA,
  payload: state,
});

export const sendPlanToUserAsync = (data) => {
  return (dispatch) => {
    API.post(URLS.sendPlan, data)
      .then(() => {
        dispatch(fetchSingleClientStartAsync(data.users[0]));
        dispatch(fetchAllClientsStartAsync());
        dispatch(initializeSendPlanState());
      })
      .catch(() => {
        dispatch(fetchSingleClientStartAsync(data.users[0]));
        dispatch(initializeSendPlanState());
      });
  };
};

export const deleteLaterPlansAsync = (data) => {
  return (dispatch, getState) => {
    dispatch(deleteLaterPlanStart());
    const deleteURL = `${URLS.deleteLaterPlan}${data}/?action=delete`;
    API.remove(deleteURL)
      .then(() => {
        const { laterPlans } = getState().sendPlan;
        const updatedLaterPlans = laterPlans.filter((plan) => plan.uuid !== data);
        dispatch(deleteLaterPlanSuccess(updatedLaterPlans));
        if (laterPlans.length === 0) dispatch(setIsLaterPlanAdded(false));
        dispatch(setShowDeletePopup(true));
        dispatch(
          setPopUpData({
            heading: 'Plan Deleted Successfully',
            message: 'Successfully deleted the plan scheduled for later',
            color: 'green',
          })
        );
      })
      .catch((error) => {
        dispatch(deleteLaterPlanFailure());
        dispatch(setShowDeletePopup(true));
        dispatch(
          setPopUpData({
            heading: 'Delete Unsuccessful',
            message: error.response.message,
            color: 'red',
          })
        );
      });
  };
};

export const sendPlanToMultipleUsersAsync = (data) => {
  return (dispatch) => {
    API.post(URLS.sendPlan, data)
      .then(() => {
        dispatch(fetchAllClientsStartAsync());
        dispatch(initializeSendPlanState());
        dispatch(setSendPlanState(CONSTANTS.CLIENT_LIST));
      })
      .catch(() => {
        dispatch(initializeSendPlanState());
      });
  };
};
