import { Suspense, lazy, useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import PermissionRoute from './routes/PermissionRoute';
import PublicRoute from './routes/PublicRoute';
import Spinner from './components/Spinner';
import NoMatch from './routes/NoMatch';

import { getCurrentUser } from './firebase';
import { setTrainerDocumentPopup, setTrainerDocumentTab } from './redux/trainer/TrainerAction';

import CONSTANTS from './utils/constants/CONSTANTS';

import './App.css';

const WorkoutBuilder = lazy(() => import('./pages/WorkoutBuilder'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const ChangePassword = lazy(() => import('./pages/ChangePassword'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Trainers = lazy(() => import('./pages/Trainers'));
const Calendar = lazy(() => import('./pages/Calendar'));
const Reporting = lazy(() => import('./pages/Reporting'));
const SchoolsAdminPage = lazy(() => import('./pages/SchoolList'));
const Clients = lazy(() => import('./pages/Clients'));
const SignUp = lazy(() => import('./pages/SignUp'));
const CorporateWellness = lazy(() => import('./pages/CorporateWellness'));
const Fundraiser = lazy(() => import('./pages/Fundraiser'));
const Inbox = lazy(() => import('./pages/Inbox'));
const Login = lazy(() => import('./pages/Login'));
const User = lazy(() => import('./pages/Users'));
const CompleteProfile = lazy(() => import('./pages/CompleteProfile'));

function App() {
  const dispatch = useDispatch();

  const userId = useSelector((state) => state.user.currentUser?.uuid);
  const userType = useSelector((state) => state.user.userType);
  const ndaDocument = useSelector((state) => state.user.currentUser?.profile?.non_compete_doc);
  const trainerContract = useSelector((state) => state.user.currentUser?.profile?.contractor_offer_sheet_doc);

  const logOutIfNotRememberMe = (e) => {
    e.preventDefault();
    const rememberMe = JSON.parse(localStorage.getItem('rememberMe'));

    /** if user has chosen to not be remembered we will log them out */
    if (!rememberMe) localStorage.clear();
  };

  useEffect(() => {
    if ((userType === CONSTANTS.TRAINER && !ndaDocument) || (userType === CONSTANTS.TRAINER && !trainerContract)) {
      dispatch(setTrainerDocumentPopup(true));
      if (!ndaDocument) {
        dispatch(setTrainerDocumentTab(CONSTANTS.NDA_DOCUMENT));
      } else if (ndaDocument && !trainerContract) {
        dispatch(setTrainerDocumentTab(CONSTANTS.TRAINER_CONTRACT));
      }
    } else {
      dispatch(setTrainerDocumentPopup(false));
    }
  }, [ndaDocument, trainerContract]);

  useEffect(() => {
    if (userId) getCurrentUser(userId);
  }, [userId]);

  useEffect(() => {
    // window.addEventListener('unload', logOutIfNotRememberMe);
    return () => {
      window.removeEventListener('unload', logOutIfNotRememberMe);
    };
  }, []);

  return (
    <div className="App">
      <Suspense fallback={<Spinner />}>
        <Switch>
          <PublicRoute exact path="/" component={Login} />
          <PublicRoute path="/signup" component={SignUp} />
          <PublicRoute path="/wellness" component={CorporateWellness} />
          <PublicRoute path="/fundraiser" component={Fundraiser} />
          <PublicRoute path="/forgotPassword" component={ForgotPassword} />
          <PublicRoute path="/change-password/:uidb64/:token" component={ChangePassword} />
          <PublicRoute path="/complete-profile" component={CompleteProfile} />
          <PermissionRoute path="/user" component={User} />
          <PermissionRoute path="/inbox" component={Inbox} />
          <PermissionRoute path="/clients" component={Clients} />
          <PermissionRoute path="/trainers" component={Trainers} />
          <PermissionRoute path="/dashboard" component={Dashboard} />
          <PermissionRoute path="/workoutBuilder" component={WorkoutBuilder} />
          <PermissionRoute path="/calendar" component={Calendar} />
          <PermissionRoute path="/reporting" component={Reporting} />
          <PermissionRoute path="/schoolList" component={SchoolsAdminPage} />
          <NoMatch />
        </Switch>
      </Suspense>
    </div>
  );
}

export default App;
