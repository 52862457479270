import TrainerActionTypes from './TrainerActionTypes';

import * as API from '../api';

import URLS from '../../utils/constants/URLS';

export const fetchTrainersStart = () => ({
  type: TrainerActionTypes.FETCH_ALL_TRAINERS_START,
});

export const fetchTrainersSuccess = (trainers) => ({
  type: TrainerActionTypes.FETCH_ALL_TRAINERS_SUCCESS,
  payload: trainers,
});

export const fetchTrainersFailure = (errorMessage) => ({
  type: TrainerActionTypes.FETCH_ALL_TRAINERS_FAILURE,
  payload: errorMessage,
});

export const fetchSingleTrainerStart = () => ({
  type: TrainerActionTypes.FETCH_SINGLE_TRAINER_START,
});

export const fetchSingleTrainerSuccess = (trainers) => ({
  type: TrainerActionTypes.FETCH_SINGLE_TRAINER_SUCCESS,
  payload: trainers,
});

export const fetchSingleTrainerFailure = (errorMessage) => ({
  type: TrainerActionTypes.FETCH_SINGLE_TRAINER_FAILURE,
  payload: errorMessage,
});

export const addNewTrainerStart = () => ({
  type: TrainerActionTypes.ADD_NEW_TRAINER_START,
});

export const addNewTrainerSuccess = () => ({
  type: TrainerActionTypes.ADD_NEW_TRAINER_SUCCESS,
});

export const addNewTrainerFailure = (errorMessage) => ({
  type: TrainerActionTypes.ADD_NEW_TRAINER_FAILURE,
  payload: errorMessage,
});

export const addTrainerAsRecruiterStart = () => ({
  type: TrainerActionTypes.ADD_TRAINER_AS_RECRUITER_START,
});

export const addTrainerAsRecruiterSuccess = () => ({
  type: TrainerActionTypes.ADD_TRAINER_AS_RECRUITER_SUCCESS,
});

export const addTrainerAsRecruiterFailure = (errorMessage) => ({
  type: TrainerActionTypes.ADD_TRAINER_AS_RECRUITER_FAILURE,
  payload: errorMessage,
});

export const setShowTrainerState = (state) => ({
  type: TrainerActionTypes.SET_SHOW_TRAINER_STATE,
  payload: state,
});

export const setRemovingClient = (state) => ({
  type: TrainerActionTypes.SET_REMOVING_CLIENT,
  payload: state,
});

export const setCurrentTrainer = (trainer) => ({
  type: TrainerActionTypes.SET_CURRENT_TRAINER,
  payload: trainer,
});

export const setAllTrainers = (trainers) => ({
  type: TrainerActionTypes.SET_ALL_TRAINERS,
  payload: trainers,
});

export const setAddTrainer = (state) => ({
  type: TrainerActionTypes.SET_ADD_TRAINER,
  payload: state,
});

export const setShowPopup = (state) => ({
  type: TrainerActionTypes.SET_SHOW_POPUP,
  payload: state,
});

export const setTrainerDocumentPopup = (state) => ({
  type: TrainerActionTypes.SET_TRAINER_DOCUMENTS_POPUP,
  payload: state,
});

export const setTrainerDocumentTab = (state) => ({
  type: TrainerActionTypes.SET_TRAINER_DOCUMENTS_TAB,
  payload: state,
});

export const setPopupData = (state) => ({
  type: TrainerActionTypes.SET_POPUP_DATA,
  payload: state,
});

export const fetchTeamStart = () => ({
  type: TrainerActionTypes.FETCH_TEAM_START,
});

export const fetchTeamSuccess = (team) => ({
  type: TrainerActionTypes.FETCH_TEAM_SUCCESS,
  payload: team,
});

export const fetchTeamFailure = (errorMessage) => ({
  type: TrainerActionTypes.FETCH_TEAM_FAILURE,
  payload: errorMessage,
});

export const initializeTrainerState = () => ({ type: TrainerActionTypes.INITIALIZE_TRAINER_STATE });

export const fetchTrainersStartAsync = () => {
  return (dispatch) => {
    dispatch(fetchTrainersStart());
    API.get(URLS.trainers)
      .then((response) => {
        dispatch(fetchTrainersSuccess(response.data));
        dispatch(setShowTrainerState(false));
      })
      .catch((error) => {
        dispatch(fetchTrainersFailure(error));
        dispatch(setShowTrainerState(false));
      });
  };
};

export const addNewTrainerAsync = (data, setShowError) => {
  return (dispatch, getState) => {
    dispatch(addNewTrainerStart());
    let { allTrainers } = getState().trainers;

    API.post(URLS.addTrainer, data)
      .then((response) => {
        dispatch(addNewTrainerSuccess());
        if (!allTrainers) allTrainers = {};
        allTrainers[response.data.uuid] = response.data;

        dispatch(setAllTrainers({ ...allTrainers }));
        dispatch(fetchTrainersStartAsync());
        dispatch(setAddTrainer(false));
        dispatch(setPopupData({ heading: 'Success!', message: 'Trainer created successfully!' }));
        dispatch(setShowPopup(true));
      })
      .catch(() => {
        dispatch(addNewTrainerFailure());
        setShowError(true);
      });
  };
};

export const addTrainersAsRecruiterStartAsync = (data) => {
  return (dispatch) => {
    dispatch(addTrainerAsRecruiterStart());

    API.patch(URLS.setTrainersAsRecruiter, data)
      .then(() => {
        dispatch(addTrainerAsRecruiterSuccess());
        dispatch(fetchTrainersStartAsync());
        dispatch(setAddTrainer(false));
        dispatch(setPopupData({ heading: 'Success!', message: 'Trainer added as Recruiter Successfully!' }));
        dispatch(setShowPopup(true));
      })
      .catch((error) => {
        dispatch(addTrainerAsRecruiterFailure(error.errorMessage));
      });
  };
};

export const fetchSingleTrainerStartAsync = (uuid) => {
  return (dispatch) => {
    dispatch(fetchSingleTrainerStart());

    API.get(`${URLS.trainers}${uuid}/`)
      .then((response) => {
        dispatch(fetchSingleTrainerSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchSingleTrainerFailure(error));
      });
  };
};

export const fetchTrainerTeamStartAsync = (uuid) => {
  return (dispatch) => {
    dispatch(fetchTeamStart());

    API.get(`${URLS.getTrainerTeam}${uuid}/`)
      .then((response) => {
        dispatch(fetchTeamSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchTeamFailure(error));
      });
  };
};

export const removeTrainerForClient = (clientUuid, trainerUuid) => {
  return (dispatch, getState) => {
    const { allTrainers, currentTrainer } = getState().trainers;
    const updatedClientList = currentTrainer.clients.filter((client) => client.uuid !== clientUuid);
    const updatedTrainer = { ...allTrainers[trainerUuid], clients_count: allTrainers[trainerUuid].clients_count - 1 };
    allTrainers[trainerUuid] = updatedTrainer;

    dispatch(setRemovingClient(true));
    API.patch(`${URLS.removeTrainer}${clientUuid}/`)
      .then(() => {
        dispatch(setCurrentTrainer({ ...currentTrainer, clients: updatedClientList }));
        dispatch(setAllTrainers({ ...allTrainers }));
        dispatch(setRemovingClient(false));
      })
      .catch(() => dispatch(setRemovingClient(false)));
  };
};

export const deactivateTrainer = (trainerUuid) => {
  return (dispatch, getState) => {
    const { allTrainers } = getState().trainers;
    const updatedTrainer = { ...allTrainers[trainerUuid], clients_count: 0, is_active: false };
    allTrainers[trainerUuid] = updatedTrainer;

    API.patch(`${URLS.deactivateTrainer}${trainerUuid}/`).then(() => {
      dispatch(fetchSingleTrainerStartAsync(trainerUuid));
      dispatch(setAllTrainers({ ...allTrainers }));
    });
  };
};

export const reactivateTrainer = (trainerUuid) => {
  return (dispatch, getState) => {
    const { allTrainers } = getState().trainers;
    const updatedTrainer = { ...allTrainers[trainerUuid], is_active: true };
    allTrainers[trainerUuid] = updatedTrainer;
    API.patch(`${URLS.reactivateTrainer}${trainerUuid}/`).then(() => {
      dispatch(fetchSingleTrainerStartAsync(trainerUuid));
      dispatch(setAllTrainers({ ...allTrainers }));
    });
  };
};

export const updateRatePerSession = (trainerUuid, data) => {
  return () => {
    API.patch(`${URLS.updateRatePerSession}${trainerUuid}/`, data);
  };
};
