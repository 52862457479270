const pathsAllowed = {
  client: ['/user', '/user/dashboard', '/user/workouts', '/user/profile', '/user/buySubscriptions'],
  trainer: [
    '/dashboard',
    '/dashboard/settings',
    '/workoutBuilder',
    '/workoutBuilder/createExercise',
    '/workoutBuilder/createWorkout',
    '/workoutBuilder/createPlan',
    '/workoutBuilder/sendPlan',
    '/clients',
    '/inbox',
    '/calendar',
  ],
  admin: [
    '/dashboard',
    '/dashboard/settings',
    '/dashboard/leaderboard',
    '/workoutBuilder',
    '/workoutBuilder/createExercise',
    '/workoutBuilder/createWorkout',
    '/workoutBuilder/createPlan',
    '/workoutBuilder/sendPlan',
    '/trainers',
    '/clients',
    '/inbox',
    '/reporting',
    '/schoolList',
  ],
};

export default pathsAllowed;
