const SendPlanActionTypes = {
  SEND_NEW_PLAN_TO_CLIENT: 'SEND_NEW_PLAN_TO_CLIENT',
  SET_SELECTED_CLIENT_LIST: 'SET_SELECTED_CLIENT_LIST',
  SET_CURRENT_PLAN_TO_SEND: 'SET_CURRENT_PLAN_TO_SEND',
  SET_LATER_PLAN_TO_SEND: 'SET_LATER_PLAN_TO_SEND',
  IS_CURRENT_PLAN_ADDED: 'IS_CURRENT_PLAN_ADDED',
  IS_LATER_PLAN_ADDED: 'IS_LATER_PLAN_ADDED',
  INITIALIZE_SEND_PLAN_STATE: 'INITIALIZE_SEND_PLAN_STATE',
  SET_LAST_PAGE: 'SET_LAST_PAGE',
  SET_POPUP_DATA: 'SET_POPUP_DATA',
  SET_SHOW_DELETE_POPUP: 'SET_SHOW_DELETE_POPUP',
  DELETE_LATER_PLAN_START: 'DELETE_LATER_PLAN_START',
  DELETE_LATER_PLAN_SUCCESS: 'DELETE_LATER_PLAN_SUCCESS',
  DELETE_LATER_PLAN_FAILURE: 'DELETE_LATER_PLAN_FAILURE',
};

export default SendPlanActionTypes;
