import URLS from '../../utils/constants/URLS';

import * as API from '../api';

import InboxActionTypes from './InboxActionTypes';

export const fetchChatsStart = () => ({
  type: InboxActionTypes.FETCH_ALL_CHATS_START,
});

export const fetchChatsSuccess = (chats) => ({
  type: InboxActionTypes.FETCH_ALL_CHATS_SUCCESS,
  payload: chats,
});

export const fetchChatsFailure = (errorMessage) => ({
  type: InboxActionTypes.FETCH_ALL_CHATS_FAILURE,
  payload: errorMessage,
});

export const fetchGroupChatsStart = () => ({
  type: InboxActionTypes.FETCH_GROUP_CHATS_START,
});

export const fetchGroupChatsSuccess = (chats) => ({
  type: InboxActionTypes.FETCH_GROUP_CHATS_SUCCESS,
  payload: chats,
});

export const fetchGroupChatsFailure = (errorMessage) => ({
  type: InboxActionTypes.FETCH_GROUP_CHATS_FAILURE,
  payload: errorMessage,
});

export const fetchSingleChatStart = (chat) => ({
  type: InboxActionTypes.FETCH_SINGLE_CHAT_START,
  payload: chat,
});

export const fetchSingleChatSuccess = (chat) => ({
  type: InboxActionTypes.FETCH_SINGLE_CHAT_SUCCESS,
  payload: chat,
});
export const fetchSingleChatFailure = (chat) => ({
  type: InboxActionTypes.FETCH_SINGLE_CHAT_FAILURE,
  payload: chat,
});

export const fetchGroupChatMembersStart = (chat) => ({
  type: InboxActionTypes.FETCH_GROUP_CHAT_MEMBERS_START,
  payload: chat,
});

export const fetchGroupChatMembersSuccess = (chat) => ({
  type: InboxActionTypes.FETCH_GROUP_CHAT_MEMBERS_SUCCESS,
  payload: chat,
});
export const fetchGroupChatMembersFailure = (chat) => ({
  type: InboxActionTypes.FETCH_GROUP_CHAT_MEMBERS_FAILURE,
  payload: chat,
});

export const fetchSingleGroupChatStart = (chat) => ({
  type: InboxActionTypes.FETCH_SINGLE_GROUP_CHAT_START,
  payload: chat,
});

export const fetchSingleGroupChatSuccess = (chat) => ({
  type: InboxActionTypes.FETCH_SINGLE_GROUP_CHAT_SUCCESS,
  payload: chat,
});
export const fetchSingleGroupChatFailure = (chat) => ({
  type: InboxActionTypes.FETCH_SINGLE_GROUP_CHAT_FAILURE,
  payload: chat,
});

export const setCurrentChat = (chat) => ({
  type: InboxActionTypes.SET_CURRENT_CHAT,
  payload: chat,
});

export const setNewClientId = (id) => ({
  type: InboxActionTypes.SET_NEW_CLIENT_ID,
  payload: id,
});

export const setNewClientFlag = (flag) => ({
  type: InboxActionTypes.SET_NEW_CHAT_FLAG,
  payload: flag,
});

export const setCurrentGroupChat = (chat) => ({
  type: InboxActionTypes.SET_CURRENT_GROUP_CHAT,
  payload: chat,
});

export const setShowChatState = (state) => ({
  type: InboxActionTypes.SET_SHOW_CHAT_STATE,
  payload: state,
});

export const setShowGroupChatState = (state) => ({
  type: InboxActionTypes.SET_SHOW_GROUP_CHAT_STATE,
  payload: state,
});

export const setAllChats = (chats) => ({
  type: InboxActionTypes.SET_ALL_CHATS,
  payload: chats,
});

export const setAllGroupChats = (chats) => ({
  type: InboxActionTypes.SET_ALL_GROUP_CHATS,
  payload: chats,
});

export const SetTimeoutValue = (timeout) => ({
  type: InboxActionTypes.SET_TIMEOUT_VALUE,
  payload: timeout,
});

export const initializeInboxState = () => ({ type: InboxActionTypes.INITIALIZE_INBOX_STATE });

export const fetchAllChatsStartAsync = () => {
  return (dispatch) => {
    dispatch(fetchChatsStart());

    API.get(URLS.allChats)
      .then((response) => {
        dispatch(fetchChatsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchChatsFailure(error));
      });
  };
};

export const fetchSingleChatStartAsync = (chatId) => {
  return (dispatch) => {
    dispatch(fetchSingleChatStart());

    API.get(`${URLS.singleChat}${chatId}/`)
      .then((response) => {
        dispatch(fetchSingleChatSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchSingleChatFailure(error.message));
      });
  };
};

export const sendMessage = (data, newMessage) => {
  return (dispatch, getState) => {
    const { currentChat, allChats } = getState().inbox;
    const currentIndex = allChats.findIndex((value) => value.client === data.client_uuid);
    const selectedChat = allChats.find((value) => {
      return value.client === data.client_uuid;
    });

    API.post(URLS.sendMessage, data).then((response) => {
      const messages = response.data;

      if (!newMessage) {
        const lastMessage = messages[messages.length - 1];
        currentChat.messages = [...currentChat.messages, lastMessage];
        const updatedChat = {
          ...selectedChat,
          sent_at: lastMessage.created_at,
          last_message: lastMessage.body.substring(0, 30),
        };
        allChats.splice(currentIndex, 1);
        const updatedChats = [updatedChat, ...allChats];
        dispatch(setAllChats(updatedChats));
        dispatch(setCurrentChat({ ...currentChat }));
      } else {
        dispatch(fetchAllChatsStartAsync());
      }
    });
  };
};

export const fetchAllGroupChatsStartAsync = () => {
  return (dispatch) => {
    dispatch(fetchGroupChatsStart());

    API.get(URLS.allGroupChats)
      .then((response) => {
        dispatch(fetchGroupChatsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchGroupChatsFailure(error));
      });
  };
};

export const fetchSingleGroupChatStartAsync = (groupChatId) => {
  return (dispatch) => {
    dispatch(fetchSingleGroupChatStart());

    API.get(`${URLS.groupChat}${groupChatId}/`)
      .then((response) => {
        dispatch(fetchSingleGroupChatSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchSingleGroupChatFailure(error.message));
      });
  };
};

export const fetchGroupChatMembers = (groupChatId) => {
  return (dispatch) => {
    dispatch(fetchGroupChatMembersStart());

    API.get(`${URLS.groupChatMembers}${groupChatId}/`)
      .then((response) => {
        dispatch(fetchGroupChatMembersSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchGroupChatMembersFailure(error.message));
      });
  };
};

export const sendGroupMessage = (data) => {
  return (dispatch, getState) => {
    const { currentGroupChat, allGroupChats } = getState().inbox;
    const currentIndex = allGroupChats.findIndex((chat) => chat.uuid === data.group_chat_uuid);
    const selectedChat = allGroupChats.find((value) => {
      return value.uuid === data.group_chat_uuid;
    });

    API.post(URLS.sendGroupMessage, data).then((response) => {
      const messages = response.data;
      const lastMessage = messages[messages.length - 1];
      currentGroupChat.messages = [...currentGroupChat.messages, lastMessage];
      const updatedChat = {
        ...selectedChat,
        sent_at: lastMessage.created_at,
        last_message: lastMessage.body.substring(0, 30),
      };
      allGroupChats.splice(currentIndex, 1);
      const updatedChats = [updatedChat, ...allGroupChats];
      dispatch(setAllGroupChats(updatedChats));
      dispatch(setCurrentGroupChat({ ...currentGroupChat }));
    });
  };
};

export const fetchAndShowChat = (data, uuid) => {
  return (dispatch) => {
    dispatch(setCurrentChat(data));
    dispatch(fetchSingleChatStartAsync(uuid));
    dispatch(setShowChatState(true));
    dispatch(setShowGroupChatState(false));
  };
};

export const fetchAndShowNewChat = (data, uuid) => {
  return (dispatch) => {
    dispatch(setCurrentChat(data));
    dispatch(fetchSingleChatStartAsync(uuid));
    dispatch(setShowChatState(true));
    dispatch(setShowGroupChatState(false));
  };
};

export const fetchAndShowGroupChat = (data, uuid) => {
  return (dispatch) => {
    dispatch(setCurrentGroupChat(data));
    dispatch(fetchSingleGroupChatStartAsync(uuid));
    dispatch(setShowGroupChatState(true));
    dispatch(setShowChatState(false));
  };
};

export const fetchAllChatsAndInitializeState = () => {
  return (dispatch) => {
    dispatch(fetchAllChatsStartAsync());
    dispatch(setShowChatState(false));
    dispatch(setCurrentChat(null));
  };
};

export const fetchAllGroupChatsAndInitializeState = () => {
  return (dispatch) => {
    dispatch(fetchAllGroupChatsStartAsync());
    dispatch(setShowGroupChatState(false));
    dispatch(setCurrentGroupChat(null));
  };
};

export const setUnreadCount = (index) => {
  return (dispatch, getState) => {
    const { allChats } = getState().inbox;
    allChats[index].unread_messages_count = 0;
    dispatch(setAllChats([...allChats]));
  };
};
