import DashboardActionTypes from './DashboardActionTypes';

const INITIAL_STATE = {
  topClients: [],
  activeClients: 0,
  activeClientsPerTrainer: 0,
  couponCount: 0,
  clientsReferred: 0,
  newClients: 0,
  trainerCount: 0,
  plansSent: 0,
  amountEarned: 0,
  isFetching: false,
  errorMessage: null,
  allClientsRanking: [],
  isFetchingRankings: false,
  milestones: [],
  currentMileStone: null,
  milestoneClients: {},
  showMilestoneClients: false,
  isFetchingClients: false,
  trainerReports: [],
  userReports: [],
  trainerPaymentReport: [],
  isFetchingReports: false,
  isFetchingNotifications: false,
  allNotifications: {},
  isUpdatingNotifications: false,
  subscriptionsCount: 0,
  totalEarned: 0,
  totalIncome: 0,
  showNotifications: false,
};

const DashboardReducer = (state = INITIAL_STATE, action = { type: '' }) => {
  switch (action.type) {
    case DashboardActionTypes.FETCH_DASHBOARD_DATA_START:
      return {
        ...state,
        isFetching: true,
      };
    case DashboardActionTypes.FETCH_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errorMessage: null,
        ...action.payload,
      };
    case DashboardActionTypes.FETCH_DASHBOARD_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case DashboardActionTypes.FETCH_NOTIFICATIONS_START:
      return {
        ...state,
        isFetchingNotifications: true,
      };
    case DashboardActionTypes.FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isFetchingNotifications: false,
        errorMessage: null,
        allNotifications: action.payload,
      };
    case DashboardActionTypes.FETCH_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        isFetchingNotifications: false,
        errorMessage: action.payload,
      };
    case DashboardActionTypes.UPDATE_NOTIFICATIONS_START:
      return {
        ...state,
        isUpdatingNotifications: true,
      };
    case DashboardActionTypes.UPDATE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isUpdatingNotifications: false,
        errorMessage: null,
      };
    case DashboardActionTypes.UPDATE_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        isUpdatingNotifications: false,
        errorMessage: action.payload,
      };
    case DashboardActionTypes.FETCH_ALL_CLIENTS_RANKINGS_START:
      return {
        ...state,
        isFetchingRankings: true,
      };
    case DashboardActionTypes.FETCH_ALL_CLIENTS_RANKINGS_SUCCESS:
      return {
        ...state,
        isFetchingRankings: false,
        errorMessage: null,
        ...action.payload,
      };
    case DashboardActionTypes.FETCH_ALL_CLIENTS_RANKINGS_FAILURE:
      return {
        ...state,
        isFetchingRankings: false,
        errorMessage: action.payload,
      };
    case DashboardActionTypes.SET_SHOW_MILESTONE_CLIENT:
      return {
        ...state,
        showMilestoneClients: action.payload,
      };
    case DashboardActionTypes.SET_SHOW_NOTIFICATIONS_FLAG:
      return {
        ...state,
        showNotifications: action.payload,
      };
    case DashboardActionTypes.SET_CURRENT_MILESTONE:
      return {
        ...state,
        currentMileStone: action.payload,
      };
    case DashboardActionTypes.FETCH_MILESTONE_CLIENTS_START:
      return {
        ...state,
        isFetchingClients: true,
      };
    case DashboardActionTypes.FETCH_MILESTONE_CLIENTS_SUCCESS:
      return {
        ...state,
        isFetchingClients: false,
        errorMessage: null,
        milestoneClients: action.payload,
      };
    case DashboardActionTypes.FETCH_MILESTONE_CLIENTS_FAILURE:
      return {
        ...state,
        isFetchingClients: false,
        errorMessage: action.payload,
      };
    case DashboardActionTypes.FETCH_REPORT_START:
      return {
        ...state,
        isFetchingReports: true,
      };
    case DashboardActionTypes.FETCH_TRAINER_REPORT_SUCCESS:
      return {
        ...state,
        isFetchingReports: false,
        errorMessage: null,
        trainerReports: action.payload,
      };
    case DashboardActionTypes.FETCH_USER_GROWTH_SUCCESS:
      return {
        ...state,
        isFetchingReports: false,
        errorMessage: null,
        userReports: action.payload,
      };
    case DashboardActionTypes.FETCH_REPORT_FAILURE:
      return {
        ...state,
        isFetchingReports: false,
        errorMessage: action.payload,
      };
    case DashboardActionTypes.FETCH_TRAINER_PAYMENTS_GRAPH_SUCCESS:
      return {
        ...state,
        trainerPaymentReport: action.payload,
        isFetchingReports: false,
      };
    case DashboardActionTypes.FETCH_TRAINER_PAYMENTS_GRAPH_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        isFetchingReports: false,
      };
    case DashboardActionTypes.SET_TRAINER_TOTAL_INCOME:
      return {
        ...state,
        totalIncome: action.payload,
      };
    case DashboardActionTypes.INITIALIZE_DASHBOARD_STATE:
      return {
        topClients: [],
        activeClients: 0,
        activeClientsPerTrainer: 0,
        couponCount: 0,
        clientsReferred: 0,
        newClients: 0,
        trainerCount: 0,
        plansSent: 0,
        amountEarned: 0,
        isFetching: false,
        errorMessage: null,
        allClientsRanking: [],
        isFetchingRankings: false,
        milestones: [],
        currentMileStone: null,
        milestoneClients: {},
        showMilestoneClients: false,
        isFetchingClients: false,
        trainerReports: [],
        userReports: [],
        trainerPaymentReport: [],
        isFetchingReports: false,
        isFetchingNotifications: false,
        allNotifications: {},
        isUpdatingNotifications: false,
        subscriptionsCount: 0,
        totalEarned: 0,
        totalIncome: 0,
        showNotifications: false,
      };
    default:
      return state;
  }
};

export default DashboardReducer;
