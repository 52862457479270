import { Link, useRouteMatch } from 'react-router-dom';

import CONSTANTS from '../../utils/constants/CONSTANTS';

import './index.scss';

const ClientHeaderLinks = () => {
  return (
    <>
      <Link
        to={CONSTANTS.CLIENT_ROUTES.DASHBOARD}
        className={`${useRouteMatch(CONSTANTS.CLIENT_ROUTES.DASHBOARD) ? 'selected' : ''} option`}
      >
        Home
      </Link>
      <Link
        to={CONSTANTS.CLIENT_ROUTES.WORKOUTS}
        className={`${useRouteMatch(CONSTANTS.CLIENT_ROUTES.WORKOUTS) ? 'selected' : ''} option`}
      >
        Workouts
      </Link>
      <Link
        to={CONSTANTS.CLIENT_ROUTES.PROFILE}
        className={`${useRouteMatch(CONSTANTS.CLIENT_ROUTES.PROFILE) ? 'selected' : ''} option`}
      >
        Profile
      </Link>
      <a href={CONSTANTS.shopUrl} target="_blank" className="option" rel="noreferrer noopener">
        Gear Shop
      </a>
      <Link className={`${useRouteMatch(CONSTANTS.CLIENT_ROUTES.MEAL_SERVICES) ? "selected" : ""} option`} to={CONSTANTS.CLIENT_ROUTES.MEAL_SERVICES}>
        Meal Services
      </Link>
    </>
  );
};
export default ClientHeaderLinks;
