const WorkoutActionTypes = {
  SET_CURRENT_WORKOUT: 'SET_CURRENT_WORKOUT',
  FETCH_ALL_WORKOUT_START: 'FETCH_ALL_WORKOUT_START',
  FETCH_ALL_WORKOUT_SUCCESS: 'FETCH_ALL_WORKOUT_SUCCESS',
  FETCH_ALL_WORKOUT_FAILURE: 'FETCH_ALL_WORKOUT_FAILURE',
  DELETE_CURRENT_WORKOUT_START: 'DELETE_CURRENT_WORKOUT_START',
  DELETE_CURRENT_WORKOUT_SUCCESS: 'DELETE_CURRENT_WORKOUT_SUCCESS',
  DELETE_CURRENT_WORKOUT_FAILURE: 'DELETE_CURRENT_WORKOUT_FAILURE',
  SET_SAVE_WORKOUT: 'SET_SAVE_WORKOUT',
  UPDATE_OR_ADD_NEW_WORKOUT: 'UPDATE_OR_ADD_NEW_WORKOUT',
  INITIALIZE_WORKOUT_STATE: 'INITIALIZE_WORKOUT_STATE',
  SET_SHOW_POPUP: 'SET_SHOW_POPUP',
  UPDATE_WORKOUT_FAILURE: 'UPDATE_WORKOUT_FAILURE',
  SET_POPUP_DATA: 'SET_POPUP_DATA',
};

export default WorkoutActionTypes;
