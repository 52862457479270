import axios from 'axios';

import { store } from './store';

export const get = (url) => {
  const accessToken = store.getState().user?.currentUser?.access_token;

  return axios.get(url, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
};
export const post = (url, data) => {
  const userType = store.getState().user?.userType;
  const signUpUser = store.getState().signup?.currentUser?.profile;
  const accessToken = userType ? store.getState().user?.currentUser?.access_token : signUpUser.access_token;

  return axios.post(url, data, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
};
export const patch = (url, data) => {
  const accessToken = store.getState().user?.currentUser?.access_token;

  return axios.patch(url, data, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
};
export const remove = (url) => {
  const accessToken = store.getState().user?.currentUser?.access_token;

  return axios.delete(url, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
};
