const PlanActionTypes = {
  SET_CURRENT_PLAN: 'SET_CURRENT_PLAN',
  FETCH_ALL_PLAN_START: 'FETCH_ALL_PLAN_START',
  FETCH_ALL_PLAN_SUCCESS: 'FETCH_ALL_PLAN_SUCCESS',
  FETCH_ALL_PLAN_FAILURE: 'FETCH_ALL_PLAN_FAILURE',
  DELETE_CURRENT_PLAN_START: 'DELETE_CURRENT_PLAN_START',
  DELETE_CURRENT_PLAN_SUCCESS: 'DELETE_CURRENT_PLAN_SUCCESS',
  DELETE_CURRENT_PLAN_FAILURE: 'DELETE_CURRENT_PLAN_FAILURE',
  SET_SAVE_PLAN: 'SET_SAVE_PLAN',
  UPDATE_OR_ADD_NEW_PLAN: 'UPDATE_OR_ADD_NEW_PLAN',
  SET_SAVE_WORKOUT_IN_PLAN: 'SET_SAVE_WORKOUT_IN_PLAN',
  INITIALIZE_PLAN_STATE: 'INITIALIZE_PLAN_STATE',
  SET_SHOW_POPUP: 'SET_SHOW_POPUP',
  UPDATE_PLAN_FAILURE: 'UPDATE_PLAN_FAILURE',
  SET_POPUP_DATA: 'SET_POPUP_DATA',
};

export default PlanActionTypes;
