const DashboardActionTypes = {
  FETCH_DASHBOARD_DATA_START: 'FETCH_DASHBOARD_DATA_START',
  FETCH_DASHBOARD_DATA_SUCCESS: 'FETCH_DASHBOARD_DATA_SUCCESS',
  FETCH_DASHBOARD_DATA_FAILURE: 'FETCH_DASHBOARD_DATA_FAILURE',
  FETCH_ALL_CLIENTS_RANKINGS_START: 'FETCH_ALL_CLIENTS_RANKINGS_START',
  FETCH_ALL_CLIENTS_RANKINGS_SUCCESS: 'FETCH_ALL_CLIENTS_RANKINGS_SUCCESS',
  FETCH_ALL_CLIENTS_RANKINGS_FAILURE: 'FETCH_ALL_CLIENTS_RANKINGS_FAILURE',
  SET_CURRENT_MILESTONE: 'SET_CURRENT_MILESTONE',
  FETCH_MILESTONE_CLIENTS_START: 'FETCH_MILESTONE_CLIENTS_START',
  FETCH_MILESTONE_CLIENTS_SUCCESS: 'FETCH_MILESTONE_CLIENTS_SUCCESS',
  FETCH_MILESTONE_CLIENTS_FAILURE: 'FETCH_MILESTONE_CLIENTS_FAILURE',
  SET_SHOW_MILESTONE_CLIENT: 'SET_SHOW_MILESTONE_CLIENT',
  FETCH_REPORT_START: 'FETCH_REPORT_STARTS',
  FETCH_TRAINER_REPORT_SUCCESS: 'FETCH_TRAINER_REPORT_SUCCESS',
  FETCH_USER_GROWTH_SUCCESS: 'FETCH_USER_GROWTH_SUCCESS',
  FETCH_REPORT_FAILURE: 'FETCH_REPORT_FAILURE',
  INITIALIZE_DASHBOARD_STATE: 'INITIALIZE_DASHBOARD_STATE',
  FETCH_NOTIFICATIONS_START: 'FETCH_NOTIFICATIONS_START',
  FETCH_NOTIFICATIONS_SUCCESS: 'FETCH_NOTIFICATIONS_SUCCESS',
  FETCH_NOTIFICATIONS_FAILURE: 'FETCH_NOTIFICATIONS_FAILURE',
  UPDATE_NOTIFICATIONS_START: 'UPDATE_NOTIFICATIONS_START',
  UPDATE_NOTIFICATIONS_SUCCESS: 'UPDATE_NOTIFICATIONS_SUCCESS',
  UPDATE_NOTIFICATIONS_FAILURE: 'UPDATE_NOTIFICATIONS_FAILURE',
  FETCH_TRAINER_PAYMENTS_GRAPH_SUCCESS: 'FETCH_TRAINER_PAYMENTS_GRAPH_SUCCESS',
  FETCH_TRAINER_PAYMENTS_GRAPH_FAILURE: 'FETCH_TRAINER_PAYMENTS_GRAPH_FAILURE',
  SET_TRAINER_TOTAL_INCOME: 'SET_TRAINER_TOTAL_INCOME',
  SET_SHOW_NOTIFICATIONS_FLAG: 'SET_SHOW_NOTIFICATIONS_FLAG',
};

export default DashboardActionTypes;
