const CONSTANTS = {
  shopUrl: 'https://www.gearbyfuzion.com/omni-active-fitness-client/shop/home',
  trainerUrl: 'https://www.gearbyfuzion.com/omni-active-fitness/shop/home',
  freshlyUrl: 'https://getfresh.ly/omniactive125',
  /** error states */

  UNAUTHORIZED: 401,

  /** Profile Information */

  MALE: 'male',
  FEMALE: 'female',
  SELECTED: 'selected',

  /** Default Error Message */

  DEFAULT_ERROR_MESSAGE: 'There seems to be a problem, please try again',

  /** Special Discount */
  SPECIAL_DISCOUNT: {
    NONE: 'None',
    FLAT_FIFTY: 'flat_fifty',
    TEN_OFF: 'ten_off',
  },

  TEN_OFF: 'Ten Off',
  FLAT_FIFTY: 'Flat Fifty',

  /** For Key Action */

  ENTER_KEY: 'Enter',

  /** Trainer Document Tabs */
  NDA_DOCUMENT: 'ndaDocument',
  TRAINER_CONTRACT: 'trainerContract',

  /** user types in our system */
  ADMIN: 'admin',
  TRAINER: 'trainer',
  CLIENT: 'client',

  /** Reporting */
  HIDDEN: 'hidden',
  SELECT: 'Select',

  /** types of payment plans */
  MONTH: 'month',
  YEAR: 'year',
  DAY: 'day',

  POPUP_COLORS: {
    RED: 'red',
    GREEN: 'green',
  },

  /** stripe subscription statuses */
  INCOMPLETE: 'incomplete',
  TRIALING: 'trialing',
  ACTIVE: 'active',
  INCOMPLETE_EXPIRED: 'incomplete_expired',
  PAST_DUE: 'past_due',
  CANCELLED: 'canceled',
  UNPAID: 'unpaid',

  /** a regular expression to check if the password typed by the user has at least one digit in it, has at least one uppercase letter and lowercase letters and is at least 8 letters long */
  PASSWORD_FORMAT: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,25}$/,

  /** for 12 hour timers  */
  AM: 'am',
  PM: 'pm',
  TWELVE: '12',

  /** Graph Months */

  GRAPH_MONTHS: ['Jan', 'Feb', 'Mar', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],

  /** For Subscription cancel status */
  CANCELED: 'CANCELED',
  /** for time intervals */

  TIME_INTERVALS: ['am', 'pm'],

  /** for time steps */

  TIME_STEPS: ['00', '15', '30', '45'],

  /** For session list */
  BLOCK_HEIGHT: 76,

  /** Name of months */
  MONTH_NAMES: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],

  /** different states of the signup form that are rendered */
  INITIAL_DETAILS: 'initialDetails',
  EMPLOYER_CODE: 'employerCode',
  FUNDRAISER_CODE: 'fundraiserCode',
  SPONSORSHIP_INFO: 'sponsorship',
  THANKYOU_PAGE: 'thankyou',
  VERIFY_EMAIL: 'verifyEmail',
  GENDER_INFO: 'genderInfo',
  BIRTH_DATE: 'birthDate',
  GENERAL_INFO: 'generalInfo',
  FITNESS_GOAL: 'fitnessGoal',
  EQUIPMENT_DETAILS: 'equipmentDetails',
  PROGRAM_TYPES: 'programTypes',
  SESSIONS_PER_WEEK: 'sessionsPerWeek',
  PAYMENT_DURATION: 'paymentDuration',
  SESSION_DAYS: 'sessionDays',
  SESSION_TIME: 'sessionTime',
  SESSION_ADDRESS: 'sessionAddress',
  ONBOARDING_SUMMARY: 'onboardingSummary',
  PAYMENT_OPTIONS: 'paymentOptions',
  CARD_DETAILS: 'cardDetails',
  REFERRAL_CODE: 'referralCode',

  /** tabs in client Profile page */
  CLIENT_PROFILE_TABS: {
    PROFILE: 'Profile',
    PAYMENT: 'Payment Details',
    AGREEMENTS: 'Agreements',
  },

  /** different states for the buying a new subscription page */

  VIEW_AVAILABLE_PLANS: 'viewAvailablePlans',
  REVIEW_SELECTED_PLAN: 'reviewSelectedPlan',
  PAYMENT_SUCCESSFUL: 'paymentSuccessful',

  /**  onboarding and resubscription flows */

  ONBOARDING_FLOW: 'onboardingFlow',
  RESUBSCRIPTION_FLOW: 'resubscriptionFlow',

  /** states for tab selected in users settings */

  PROFILE: 'profile',
  SUBSCRIPTION: 'subscription',

  /** Dashboard Graph types */
  GRAPH_TYPES: {
    USER_GOALS: 'User Goals',
  },

  /** Pop up colours */
  POPUP_COLOURS: {
    RED: 'red',
    GREEN: 'green',
    BLUE: 'blue',
  },

  /** different lists required in the workout builder which are used again and again */
  /** needed in create Exercise */
  MUSCLES: [
    'Chest',
    'Back',
    'Shoulders',
    'Arms',
    '_Biceps',
    '_Triceps',
    'Legs',
    '_Quads',
    '_Hamstrings',
    '_Glutes',
    'Core',
    '_Abs',
    '_Lower Back',
    'Strength',
    'Speed',
    'Conditioning',
  ],

  monthNames: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],

  EXERCISE_REQUIRED_FIELDS: {
    LISTS: ['steps', 'muscles'],
    STRINGS: ['name', 'notes', 'video', 'thumbnail'],
  },
  EQUIPMENTS: ['Dumbbell', 'Kettlebell', 'Barbells', 'Slidders', 'Band'],
  BOTH: 'both',

  ERROR_MESSAGE: 'This field is required',
  SHORT_ERROR_MESSAGE: 'Required',

  /** needed in create Workout and Plans */
  LEVEL: ['beginner', 'intermediate', 'advanced'],
  INTENSITY: ['low', 'mid', 'high'],
  TYPES: {
    weightLoss: 'weight_loss',
    muscleBuilding: 'muscle_building',
    generalFitness: 'general_fitness',
    athleticPerformance: 'athletic_performance',
  },

  SUBSCRIPTION_PLATFORMS: {
    stripe: 'Stripe',
    appStore: 'App Store',
    playStore: 'Play Store',
  },

  SUBSCRIPTION_TYPES: {
    weightLoss: 'Weight Loss',
    muscleBuilding: 'Muscle Building',
    generalFitness: 'General Fitness',
    athleticPerformance: 'Athletic Performance',
  },

  /** For Video Calling */
  AUDIO: 'audio',
  VIDEO: 'video',

  REPORT_TYPES: {
    individuals: 'Individuals',
    couponCode: 'Groups',
    both: 'Both',
  },

  /** Reporting Types */
  CLIENT_TYPE: 'client',
  TRAINER_TYPE: 'trainer',
  SALES_TYPE: 'sales',

  /** Reporting File Names */
  CLIENT_REPORT_NAME: 'Trainer/Client',
  TRAINER_REPORT_NAME: 'Payroll',

  EQUIPMENT_AVAILABILITY: {
    noEquipment: 'no_equipment_available',
    someEquipment: 'some_equipments',
    gymAccess: 'access_to_commercial_gym',
  },
  SPECIAL_DISCOUNTS: ['None', 'Ten Off', 'Flat Fifty'],
  YEARS: ['2022', '2023', '2024'],
  EXERCISE_FILE_NAMES: ['thumbnail', 'video'],
  WEIGHT_CATEGORIES: ['N/A', 'heavy', 'mod', 'low'],
  CHANGED_WEIGHT_CATEGORY_LIGHT: 'light',
  CHANGED_WEIGHT_CATEGORY_LOW: 'low',
  EXERCISE_PROPERTIES: ['sets', 'reps', 'weight', 'time'],
  /** constants for mapping boolean state */
  STRING_TO_BOOLEAN: {
    yes: true,
    no: false,
    true: true,
    false: false,
  },
  GENDER_LIST: ['Female', 'Male', 'Rather not say'],

  /** redux states that determine what to show in workout builder */
  /** used in create Exercise, create Workout and create Plan */
  VIEW_STATE: 'View',
  EDIT_STATE: 'Edit',
  ADD_STATE: 'Add',
  NO_STATE: 'None',

  /** used in send a plan */
  CLIENT_LIST: 'clientList',
  SEND_PLAN_TO_GROUP: 'sendPlanToGroup',
  SEND_PLAN_TO_INDIVIDUAL: 'sendPlanToIndividual',
  SEND_PLAN_TO_MULTIPLE: 'sendPlanToMultiple',

  /** different button types that determine the appearance of the button */
  BROWSE_TYPE: 'browse',
  DELETE_TYPE: 'delete',
  EDIT_TYPE: 'edit',
  SAVE_TYPE: 'save',
  DISABLE_TYPE: 'disable',
  COUPON_TYPE: 'coupon-save-btn',
  WIDDER_SAVE_TYPE: 'widder-save',
  FILTER_TYPE: 'filter-options',
  POPUP_TYPE: 'popup-button',
  WIDE_TYPE: 'wide',
  WIDE_SAVE_TYPE: 'wide-save',
  SINGLE_WIDE_TYPE: 'single-wide',
  REPORT_TYPE: 'report-btn',
  DOWNLOAD_TYPE: 'report-download-btn',

  /** exercise group types */
  SINGLE_SET: 'single_set',
  SUPER_SET: 'superset',
  CIRCUIT: 'circuit',

  /** names of often used inputs */
  INPUT_SECONDS: 'seconds',
  INPUT_MINUTES: 'minutes',

  /**  subscriptions: */
  monthly: 'monthly',
  yearly: 'yearly',

  MONTHLY: 'monthly',
  YEARLY: 'yearly',

  /** different categories in workout builder */
  PLAN: 'plan',
  EXERCISE: 'exercise',
  WORKOUT: 'workout',

  /** sending plan types */
  CURRENT_PLAN: 'current',
  LATER_PLAN: 'later',

  /** icon styles to be assigned to react icons  */
  ICON_STYLES: { cursor: 'pointer', fontSize: '1.8rem' },
  BIG_ICON: { fontSize: '2.5rem', cursor: 'pointer' },
  SMALL_ICON: { cursor: 'pointer', fontSize: '1.2rem' },
  EXTRA_BIG_ICON: { fontSize: '4rem', cursor: 'pointer' },
  ICON_STYLE_SPACE: { cursor: 'pointer', fontSize: '1.8rem', marginRight: '4px' },

  /** Preferred time */
  PREFERRED_TIME: [
    {
      duration: 'morning',
      time: '5AM-11AM',
    },
    {
      duration: 'afternoon',
      time: '12PM-4PM',
    },
    {
      duration: 'evening',
      time: '5PM-9PM',
    },
  ],

  /** Program Types */
  ONLINE: 'online',
  VIRTUAL: 'virtual',
  IN_PERSON: 'in_person',

  /** Days of the week */
  WEEK_DAYS: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  MORNING: 'morning',
  AFTERNOON: 'afternoon',
  EVENING: 'evening',

  /** intervals */
  INTERVAL_AM: 'AM',
  INTERVAL_PM: 'PM',

  /** For selecting ideal time for sessions */

  SESSION_INTERVALS: ['00', '15', '30', '45'],
  TWELVE_HOUR_TYPES: ['AM', 'PM'],

  MORNING_HOURS: ['05', '06', '07', '08', '09', '10', '11'],
  AFTERNOON_HOURS: ['12', '01', '02', '03'],
  EVENING_HOURS: ['05', '06', '07', '08', '09'],

  SESSION_HOURS: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
  /** different states of add trainer popup */
  STATES: {
    showAddTrainerOptions: 'showAddTrainerOptions',
    trainerInformation: 'trainerInformation',
    saveTrainerCredentials: 'saveTrainerCredentials',
    addTrainerAsRecruit: 'addTrainerAsRecruit',
  },

  /** possible times for sessions */
  TIMES: [
    '12 AM',
    '1 AM',
    '2 AM',
    '3 AM',
    '4 AM',
    '5 AM',
    '6 AM',
    '7 AM',
    '8 AM',
    '9 AM',
    '10 AM',
    '11 AM',
    '12 PM',
    '1 PM',
    '2 PM',
    '3 PM',
    '4 PM',
    '5 PM',
    '6 PM',
    '7 PM',
    '8 PM',
    '9 PM',
    '10 PM',
    '11 PM',
  ],

  HOURS: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],

  /** converting times to hours that we can input in date */
  TIMES_TO_HOURS: {
    '12 AM': 0,
    '1 AM': 1,
    '2 AM': 2,
    '3 AM': 3,
    '4 AM': 4,
    '5 AM': 5,
    '6 AM': 6,
    '7 AM': 7,
    '8 AM': 8,
    '9 AM': 9,
    '10 AM': 10,
    '11 AM': 11,
    '12 PM': 12,
    '1 PM': 13,
    '2 PM': 14,
    '3 PM': 15,
    '4 PM': 16,
    '5 PM': 17,
    '6 PM': 18,
    '7 PM': 19,
    '8 PM': 20,
    '9 PM': 21,
    '10 PM': 22,
    '11 PM': 23,
  },

  /** converting month to index to pass it to backend */
  MONTH_TO_INDEX: {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12,
  },

  /** markSession Booleans */
  IS_COMPLETE: {
    TRUE: true,
    FALSE: false,
  },
  /** types of sessions */
  TYPE_OF_SESSIONS: {
    VIRTUAL: 'virtual',
    IN_PERSON: 'in_person',
  },

  /** days of the week */
  DAYS: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],

  /** format for time and date */
  HOUR_FORMAT: { hour: 'numeric', hour12: true },
  TIME_FORMAT: { hour: 'numeric', minute: 'numeric', hour12: true },
  DATE_FORMAT: { weekday: 'long', month: 'long', day: 'numeric' },
  COMPLETE_DATE_FORMAT: { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' },

  /** moment date formats */
  DAY_MONTH_YEAR: 'DD-MM-YYYY',
  YEAR_MONTH_DAY: 'YYYY-MM-DD',
  MONTH_DAY_YEAR: 'MM-DD-YYYY',

  /** progress bar colors in client list in send a plan */
  YELLOW: '#fec00b',
  GREEN: '#3DD598',
  RED: '#f03a47',

  REG_EX: 'd{3}[-]d{2}[-]d{4}',
  /** fields for filtering that is done in client list in send plan */
  FILTER: {
    'Client Type': ['online', 'in_person', 'virtual'],
    'Plan Status': ['No Plan Assigned', 'Almost Complete'],
    Goals: ['weight_loss', 'muscle_building', 'general_fitness', 'athletic_performance'],
    Age: ['12 to 17', '18 to 25', '26 to 35', '36 to 45', '46 to 55', '55 to 100'],
    Equipment: ['no_equipment_available', 'some_equipments', 'access_to_commercial_gym'],
  },
  PLAN_STATUS: 'Plan Status',
  NO_PLAN: 'No Plan Assigned',
  ALMOST_COMPLETE: 'Almost Complete',
  AGE: 'Age',
  MAP_CATEGORY_TO_FIELD: {
    'Client Type': 'client_type',
    Goals: 'goal',
    Equipment: 'equipment_availability',
  },
  EQUIPMENT: 'Equipment',

  /** sponsorship level names */
  PLATINUM: 'Platinum',
  GOLD: 'Gold',
  SILVER: 'Silver',

  SPONSORSHIP_PER_MONTH_PRICE: 15,

  /** filter tabs for client page */
  CLIENT_FILTERS: {
    ALL: 'All',
    NEED_TRAINER: 'Need a Trainer',
    NEED_PLAN: 'Need a Plan',
    INCOMPLETE: 'Incomplete Profiles',
  },
  /** filter tabs for group page */
  GROUP_FILTERS: {
    ALL: 'All',
    ASSIGNED: 'Assigned',
    UNASSIGNED: 'Unassigned',
  },
  /** lists for dropdown in clients filters */
  LIST_OF_PROGRESS: ['All', 'no Plan', '0% to 25%', '25% to 50%', '50% to 75%', '75% to 100%'],
  SORT_LIST: ['unsorted', 'A-Z', 'Z-A'],

  /** filter list for Trainers */
  SORT_TRAINERS: {
    unsorted: 'unsorted',
    aToZ: 'A-Z',
    zToA: 'Z-A',
    moreToLess: 'Clients,  more to less',
    lessToMore: 'Clients, less  to more',
  },

  /** MailChimp Post URL */

  mailChimpPostUrl:
    'https://omniactivefitness.us4.list-manage.com/subscribe/post?u=047371c7d334d8dd205abe09b&amp;id=3c9c9dcff3',

  /** Tabs for trainer page */

  UNASSIGNED: 'Unassigned',
  ALL: 'All',

  /** client profile tabs */
  CLIENT_TABS: {
    PROFILE: 'Profile',
    HISTORY: 'History',
    ACHIEVEMENT: 'Achievements',
    PROGRESS_TRACKING: 'Progress Tracking',
    APPOINTMENTS: 'Appointments',
    PAYMENT: 'Payment',
    AGREEMENTS: 'Agreements',
  },

  TRAINER_TABS: {
    profile: 'Profile',
    clients: 'Clients',
    schedule: 'Schedule',
    team: 'Team',
    agreements: 'Agreements',
  },

  /** client profile tabs */
  CLIENT_TYPE_TABS: {
    CLIENTS: 'Clients',
    GROUPS: 'Groups',
  },

  CLIENT_ROUTES: {
    DASHBOARD: '/user/dashboard',
    WORKOUTS: '/user/workouts',
    PROFILE: '/user/profile',
    MEAL_SERVICES: '/user/mealServices',
  },
  /** client profile tabs */
  CHAT_TYPE_TABS: {
    PRIVATE: 'Private',
    GROUP: 'Group',
  },

  URL_LIST: {
    DASHBOARD: '/dashboard',
    WORKOUT_BUILDER: '/workoutBuilder',
    TRAINERS: '/trainers',
    INBOX: '/inbox',
    CLIENTS: '/clients',
    CALENDAR: '/calendar',
    REPORTING: '/reporting',
    SCHOOL_LIST: '/schoolList',
  },

  /** URL values for the top bar on all the pages for navigation */
  URL_VALUES: {
    '/dashboard': { heading: 'Dashboard', subHeading: 'My Dashboard' },
    '/dashboard/settings': { heading: 'Profile / Account Settings', subHeading: 'Account Settings' },
    '/dashboard/leaderboard': { heading: 'Dashboard / Leaderboard', subHeading: 'Leaderboard' },
    '/dashboard/couponCodes': { heading: 'Dashboard / Coupon Codes', subHeading: 'Coupon Codes' },
    '/inbox': { heading: 'Dashboard', subHeading: 'Inbox' },
    '/clients': { heading: 'Dashboard', subHeading: 'Clients' },
    '/calendar': { heading: 'Dashboard', subHeading: 'Calendar' },
    '/reporting': { heading: 'Dashboard', subHeading: 'Reporting' },
    '/schoolList': { heading: 'Dashboard / Schools', subHeading: 'Schools' },
    '/trainers': { heading: 'Dashboard', subHeading: 'Trainers' },
    '/': { heading: 'Dashboard', subHeading: 'My Dashboard' },
    '/workoutBuilder': {
      heading: 'Dashboard / Workout Builder',
      subHeading: 'Workout Builder',
    },
    '/workoutBuilder/createExercise': {
      heading: 'WorkoutBuilder / Exercises',
      subHeading: 'Exercises',
    },
    '/workoutBuilder/createPlan': {
      heading: 'WorkoutBuilder / Plans',
      subHeading: 'Plans',
    },
    '/workoutBuilder/createWorkout': {
      heading: 'WorkoutBuilder / Workouts',
      subHeading: 'Workouts',
    },
    '/workoutBuilder/sendPlan': {
      heading: 'WorkoutBuilder / Send Plan',
      subHeading: 'Send Plan',
    },
  },

  // School table row header
  SCHOOL_LIST_TABLE_HEADER: ['SCHOOL NAME', 'CONTACT', 'EMAIL', 'ADDRESS', 'DONATION', 'STATUS', 'ACTION'],
  SINGLE_SCHOOL_TABLE_HEADER: ['DONATED BY', 'DONATED ON', 'DONATION', 'STATUS'],
};

export default CONSTANTS;
