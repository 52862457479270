const ExerciseActionTypes = {
  SET_CURRENT_EXERCISE: 'SET_CURRENT_EXERCISE',
  FETCH_ALL_EXERCISE_START: 'FETCH_ALL_EXERCISE_START',
  FETCH_ALL_EXERCISE_SUCCESS: 'FETCH_ALL_EXERCISE_SUCCESS',
  FETCH_ALL_EXERCISE_FAILURE: 'FETCH_ALL_EXERCISE_FAILURE',
  DELETE_CURRENT_EXERCISE_START: 'DELETE_CURRENT_EXERCISE_START',
  DELETE_CURRENT_EXERCISE_SUCCESS: 'DELETE_CURRENT_EXERCISE_SUCCESS',
  DELETE_CURRENT_EXERCISE_FAILURE: 'DELETE_CURRENT_EXERCISE_FAILURE',
  SET_SAVE_EXERCISE: 'SET_SAVE_EXERCISE',
  UPDATE_OR_ADD_NEW_EXERCISE: 'UPDATE_OR_ADD_NEW_EXERCISE',
  INITIALIZE_EXERCISE_STATE: 'INITIALIZE_EXERCISE_STATE',
  SET_SHOW_POPUP: 'SET_SHOW_POPUP',
  UPDATE_EXERCISE_FAILURE: 'UPDATE_EXERCISE_FAILURE',
  SET_POPUP_DATA: 'SET_POPUP_DATA',
};

export default ExerciseActionTypes;
