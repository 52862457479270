import WorkoutBuilderActionTypes from './WorkoutBuilderActionTypes';

import CONSTANTS from '../../utils/constants/CONSTANTS';

const INITIAL_STATE = {
  exerciseState: CONSTANTS.NO_STATE,
  workoutState: CONSTANTS.NO_STATE,
  planState: CONSTANTS.NO_STATE,
  sendPlanState: CONSTANTS.CLIENT_LIST,
};

const WorkoutBuilderReducer = (state = INITIAL_STATE, action = { type: '' }) => {
  switch (action.type) {
    case WorkoutBuilderActionTypes.INITIALIZE_PAGE_STATE:
      return {
        ...state,
        exerciseState: CONSTANTS.NO_STATE,
        workoutState: CONSTANTS.NO_STATE,
        planState: CONSTANTS.NO_STATE,
        sendPlanState: CONSTANTS.CLIENT_LIST,
      };
    case WorkoutBuilderActionTypes.SET_CURRENT_EXERCISE_DETAILS_STATE:
      return {
        ...state,
        exerciseState: action.payload,
      };
    case WorkoutBuilderActionTypes.SET_CURRENT_WORKOUT_DETAILS_STATE:
      return {
        ...state,
        workoutState: action.payload,
      };
    case WorkoutBuilderActionTypes.SET_CURRENT_PLAN_DETAILS_STATE:
      return {
        ...state,
        planState: action.payload,
      };
    case WorkoutBuilderActionTypes.SET_SEND_PLAN_STATE:
      return {
        ...state,
        sendPlanState: action.payload,
      };
    default:
      return state;
  }
};

export default WorkoutBuilderReducer;
