import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default function useGetUnreadNotificationsCount() {
  const allNotifications = useSelector((state) => state.dashboard.allNotifications);

  const [unreadCount, setUnreadCount] = useState(0);

  const UpdateNotificationsCount = () => {
    if (allNotifications && allNotifications.notifications) {
      let sum = 0;
      allNotifications.notifications.forEach((value) => {
        if (!value.is_seen) {
          sum += 1;
        }
      });
      setUnreadCount(sum);
    }
  };

  useEffect(() => {
    UpdateNotificationsCount();
  }, [allNotifications]);
  return unreadCount;
}
