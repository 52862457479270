import CONSTANTS from '../../utils/constants/CONSTANTS';
import UserActionTypes from './UserActionTypes';

const INITIAL_STATE = {
  currentUser: null,
  userType: null,
  isLoggingIn: false,
  alreadySignedUp: false,
  updatingUser: false,
  errorMessage: null,
  rememberMe: true,
  showPopup: false,
  popupData: {},
  settingsTab: CONSTANTS.CLIENT_PROFILE_TABS.PROFILE,
  sessionsDetail: {},
  isFetching: false,
};

const userReducer = (state = INITIAL_STATE, action = { type: '' }) => {
  switch (action.type) {
    case UserActionTypes.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    case UserActionTypes.SET_USER_TYPE:
      return {
        ...state,
        userType: action.payload,
      };
    case UserActionTypes.UPDATE_USER_START:
      return {
        ...state,
        updatingUser: true,
      };
    case UserActionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        updatingUser: false,
        errorMessage: null,
      };
    case UserActionTypes.UPDATE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        updatingUser: false,
      };
    case UserActionTypes.UPDATE_USER_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        updatingUser: false,
      };

    case UserActionTypes.INITIALIZE_STATE:
      return {
        ...state,
        errorMessage: null,
      };
    case UserActionTypes.SET_ACCESS_AND_REFRESH_TOKEN:
      return {
        ...state,
        currentUser: { ...state.currentUser, ...action.payload },
      };
    case UserActionTypes.SET_ALREADY_SIGNED_UP:
      return {
        ...state,
        alreadySignedUp: action.payload,
      };
    case UserActionTypes.USER_LOGIN_START:
      return {
        ...state,
        isLoggingIn: true,
        errorMessage: null,
      };
    case UserActionTypes.USER_LOGIN_SUCCESS:
      return {
        ...state,
        isLoggingIn: false,
        errorMessage: null,
        currentUser: action.payload,
        userType: action.payload.user_type,
      };
    case UserActionTypes.USER_LOGIN_FAILURE:
      return {
        ...state,
        isLoggingIn: false,
        errorMessage: action.payload,
      };
    case UserActionTypes.SET_LOGIN_STATE:
      return {
        ...state,
        isLoggingIn: action.payload,
      };
    case UserActionTypes.SET_REMEMBER_ME:
      return {
        ...state,
        rememberMe: action.payload,
      };
    case UserActionTypes.SET_SHOW_POPUP:
      return {
        ...state,
        showPopup: action.payload,
      };
    case UserActionTypes.SET_POPUP_DATA:
      return {
        ...state,
        popupData: action.payload,
      };
    case UserActionTypes.SET_USER_SETTINGS_TAB:
      return {
        ...state,
        settingsTab: action.payload,
      };
    case UserActionTypes.FETCH_USER_SESSION_DETAIL_START:
      return {
        ...state,
        isFetching: true,
        errorMessage: null,
      };
    case UserActionTypes.FETCH_USER_SESSION_DETAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errorMessage: null,
        sessionsDetail: action.payload,
      };
    case UserActionTypes.FETCH_USER_SESSION_DETAIL_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };

    case UserActionTypes.COMPLETE_PROFILE_MESSAGE_SUCCESS:
      return {
        ...state,
        isCompleteProfileMessageNeeded: true,
      };

    case UserActionTypes.COMPLETE_PROFILE_MESSAGE_FAILURE:
      return {
        ...state,
        isCompleteProfileMessageNeeded: false,
      };

    case UserActionTypes.CANCELLED_SUBSCRIPTION_FOR_MANUAL_USER:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          profile: {
            ...state.currentUser.profile,
            is_plan_upgraded: false,
          },
        },
      };

    default:
      return state;
  }
};

export default userReducer;
