import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import CONSTANTS from '../utils/constants/CONSTANTS';

const PublicRoute = ({ component: Component, ...otherProps }) => {
  const userType = useSelector((state) => state.user.userType);

  const componentToRender = () => {
    if (userType) {
      if (userType === CONSTANTS.CLIENT) {
        return <Redirect to="/user/dashboard" />;
      }
      return <Redirect to="/dashboard" />;
    }
    return <Component />;
  };

  return <Route {...otherProps} component={componentToRender} />;
};

export default PublicRoute;
