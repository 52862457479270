const FundraiserActionTypes = {
    INITIALIZE_FUNDRAISER_STATE: 'INITIALIZE_FUNDRAISER_STATE',
    SET_CURRENT_FUNDRAISER_FORM: 'SET_CURRENT_FUNDRAISER_FORM',
    SET_FUNDRAISER_CODE: 'SET_FUNDRAISER_CODE',
    VERIFY_SCHOOL_CODE_START: 'VERIFY_SCHOOL_CODE_START',
    VERIFY_SCHOOL_CODE_SUCCESS: 'VERIFY_SCHOOL_CODE_SUCCESS',
    VERIFY_SCHOOL_CODE_FAILURE: 'VERIFY_SCHOOL_CODE_FAILURE',
    SET_SCHOOL_NAME: 'SET_SCHOOL_NAME',
    SET_SCHOOL_LOGO: 'SET_SCHOOL_LOGO',
    SET_SELECTED_SPONSORSHIP: 'SET_SELECTED_SPONSORSHIP',
    SET_SPONSORSHIP_TOTAL_AMOUNT: 'SET_SPONSORSHIP_TOTAL_AMOUNT',
    SET_SPONSORSHIP_DURATION: 'SET_SPONSORSHIP_DURATION',
    SET_DONOR_EMAIL: 'SET_DONOR_EMAIL',
    SET_SCHOOL_FETCHING_FLAG: 'SET_SCHOOL_FETCHING_FLAG',
    SET_SCHOOL_LIST: 'SET_SCHOOL_LIST',
    SET_SELECTED_SCHOOL_CODE: 'SET_SELECTED_SCHOOL_CODE',
    SET_SELECTED_SCHOOL: 'SET_SELECTED_SCHOOL',
};

export default FundraiserActionTypes;