import SendPlanActionTypes from './SendPlanActionTypes';

const INITIAL_STATE = {
  sendPlan: false,
  selectedClients: [],
  currentPlan: null,
  laterPlans: [],
  isCurrentPlanAdded: null,
  isLaterPlanAdded: null,
  lastPage: '',
  popUpData: {},
  showDeletePopup: false,
};

const SendPlanReducer = (state = INITIAL_STATE, action = { type: '' }) => {
  switch (action.type) {
    case SendPlanActionTypes.SEND_NEW_PLAN_TO_CLIENT:
      return {
        ...state,
        sendPlan: action.payload,
      };
    case SendPlanActionTypes.SET_SELECTED_CLIENT_LIST:
      return {
        ...state,
        selectedClients: action.payload,
      };
    case SendPlanActionTypes.SET_CURRENT_PLAN_TO_SEND:
      return {
        ...state,
        currentPlan: action.payload,
      };
    case SendPlanActionTypes.SET_LATER_PLAN_TO_SEND:
      return {
        ...state,
        laterPlans: action.payload,
      };
    case SendPlanActionTypes.IS_CURRENT_PLAN_ADDED:
      return {
        ...state,
        isCurrentPlanAdded: action.payload,
      };
    case SendPlanActionTypes.IS_LATER_PLAN_ADDED:
      return {
        ...state,
        isLaterPlanAdded: action.payload,
      };
    case SendPlanActionTypes.SET_POPUP_DATA:
      return {
        ...state,
        popUpData: action.payload,
      };
    case SendPlanActionTypes.SET_LAST_PAGE:
      return {
        ...state,
        lastPage: action.payload,
      };
    case SendPlanActionTypes.DELETE_LATER_PLAN_START:
      return {
        ...state,
      };
    case SendPlanActionTypes.DELETE_LATER_PLAN_SUCCESS:
      return {
        ...state,
        laterPlans: action.payload,
        showDeletePopup: true,
      };
    case SendPlanActionTypes.SET_SHOW_DELETE_POPUP:
      return {
        ...state,
        showDeletePopUp: action.payload,
      };
    case SendPlanActionTypes.DELETE_LATER_PLAN_FAILURE:
      return {
        ...state,
        showDeletePopup: true,
      };
    case SendPlanActionTypes.INITIALIZE_SEND_PLAN_STATE:
      return {
        ...state,
        sendPlan: false,
        currentPlan: null,
        laterPlans: [],
        isCurrentPlanAdded: null,
        isLaterPlanAdded: null,
        popUpData: {},
        showDeletePopup: false,
      };
    default:
      return state;
  }
};

export default SendPlanReducer;
