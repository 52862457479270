import CreatePlanActionTypes from './PlanActionTypes';

const INITIAL_STATE = {
  currentPlan: null,
  allPlan: {},
  isFetching: false,
  isDeleting: false,
  errorMessage: null,
  updateErrorMessage: null,
  savePlan: false,
  saveWorkoutCopy: false,
  showPopup: false,
  showAddPopup: false,
  showDeletePopup: false,
  popupData: {},
};

const PlanReducer = (state = INITIAL_STATE, action = { type: '' }) => {
  switch (action.type) {
    case CreatePlanActionTypes.SET_CURRENT_PLAN:
      return {
        ...state,
        currentPlan: action.payload,
      };
    case CreatePlanActionTypes.FETCH_ALL_PLAN_START:
      return {
        ...state,
        isFetching: true,
      };
    case CreatePlanActionTypes.FETCH_ALL_PLAN_SUCCESS:
      return {
        ...state,
        allPlan: action.payload.reduce((object, plan) => {
          return { ...object, [plan.uuid]: plan };
        }, {}),
        isFetching: false,
        errorMessage: null,
      };
    case CreatePlanActionTypes.FETCH_ALL_PLAN_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case CreatePlanActionTypes.DELETE_CURRENT_PLAN_START:
      return {
        ...state,
        isDeleting: true,
      };
    case CreatePlanActionTypes.DELETE_CURRENT_PLAN_SUCCESS:
      return {
        ...state,
        currentPlan: null,
        isDeleting: false,
        allPlan: action.payload,
      };
    case CreatePlanActionTypes.DELETE_CURRENT_PLAN_FAILURE:
      return {
        ...state,
        isDeleting: false,
        errorMessage: action.payload,
      };

    case CreatePlanActionTypes.SET_SAVE_PLAN:
      return {
        ...state,
        savePlan: action.payload,
      };
    case CreatePlanActionTypes.UPDATE_OR_ADD_NEW_PLAN:
      return {
        ...state,
        allPlan: action.payload,
        updateErrorMessage: null,
      };
    case CreatePlanActionTypes.SET_SAVE_WORKOUT_IN_PLAN:
      return {
        ...state,
        saveWorkoutCopy: action.payload,
      };
    case CreatePlanActionTypes.SET_SHOW_POPUP:
      return {
        ...state,
        showPopup: action.payload,
      };
    case CreatePlanActionTypes.SET_POPUP_DATA:
      return {
        ...state,
        popupData: action.payload,
      };
    case CreatePlanActionTypes.UPDATE_PLAN_FAILURE:
      return {
        ...state,
        updateErrorMessage: action.payload,
      };
    case CreatePlanActionTypes.INITIALIZE_PLAN_STATE:
      return {
        currentPlan: null,
        allPlan: {},
        isFetching: false,
        errorMessage: null,
        updateErrorMessage: null,
        savePlan: false,
        saveWorkoutCopy: false,
        showPopup: false,
        showAddPopup: false,
      };
    default:
      return state;
  }
};

export default PlanReducer;
