const getTime = (createdAt) => {
  const currentDate = new Date();
  const messageTime = new Date(createdAt);

  if (messageTime.toLocaleDateString() === currentDate.toLocaleDateString()) {
    const minutes = messageTime.getMinutes() > 9 ? messageTime.getMinutes() : `0${messageTime.getMinutes()}`;
    let hours = messageTime.getHours();
    const amOrPm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${amOrPm}`;
  }
  return messageTime.toLocaleDateString();
};

export default getTime;
