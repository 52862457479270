import { initializeApp } from 'firebase/app';
import { getFirestore, doc, onSnapshot } from 'firebase/firestore';
import { store } from './redux/store';
import { setNewClientAddedStatus } from './redux/clients/ClientAction';

import { fetchMessages, fetchGroupMessages, fetchNotifications } from './utils/helperFunctions';

const firebaseApiKey = process.env.REACT_APP_FIREBASE_API_KEY;
const firebaseAppId = process.env.REACT_APP_FIREBASE_APP_ID;

const firebaseConfig = {
  apiKey: firebaseApiKey,
  authDomain: 'omni-active-fitness-app.firebaseapp.com',
  projectId: 'omni-active-fitness-app',
  storageBucket: 'omni-active-fitness-app.appspot.com',
  messagingSenderId: '62943238722',
  appId: firebaseAppId,
  measurementId: 'G-SZB30SZYCQ',
};

const app = initializeApp(firebaseConfig);

const db = getFirestore();
const collectionName = process.env.REACT_APP_FIREBASE_COLLECTION;

/** we use this function to get the new-message-received state of the user from firebase */
export const getCurrentUser = async (userId) => {
  /** this creates a listener for the document assigned to the current user so that if there are any changes we are informed of them */

  const unSubscribe = onSnapshot(doc(db, collectionName, userId.toString()), (document) => {
    const data = document.data();
    /** if we get some data back for the user and the user has new group messages we call the fetch group messages function */
    if (data && data.have_group_messages) fetchGroupMessages();

    /** if we get some data back for the user and the user has new messages we call the fetch messages function */
    if (data && data.have_messages) fetchMessages();

    /** if we get some data back for the user and the user has new messages we call the fetch messages function */
    if (data && data.have_notifications) fetchNotifications();

    if (data && data.have_new_client) store.dispatch(setNewClientAddedStatus(true));
  });

  return unSubscribe;
};

export default app;
