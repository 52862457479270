import TrainerActionTypes from './TrainerActionTypes';

const INITIAL_STATE = {
  allTrainers: {},
  isFetching: false,
  errorMessage: null,
  currentTrainer: null,
  isFetchingSingle: false,
  errorMessageSingle: null,
  showTrainerState: false,
  removingClient: false,
  addTrainer: false,
  addNewTrainer: false,
  showPopup: false,
  popupData: {},
  trainerTeam: [],
  isFetchingTeam: false,
  addTrainerAsRecruiter: false,
  trainerPopup: false,
  trainerDocumentTab: null,
};

const trainerReducer = (state = INITIAL_STATE, action = { type: '' }) => {
  switch (action.type) {
    case TrainerActionTypes.FETCH_ALL_TRAINERS_START:
      return {
        ...state,
        isFetching: true,
        errorMessage: null,
      };
    case TrainerActionTypes.FETCH_ALL_TRAINERS_SUCCESS:
      return {
        ...state,
        allTrainers: action.payload.reduce((object, trainer) => {
          return { ...object, [trainer.uuid]: trainer };
        }, {}),
        isFetching: false,
      };
    case TrainerActionTypes.FETCH_ALL_TRAINERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case TrainerActionTypes.FETCH_SINGLE_TRAINER_START:
      return {
        ...state,
        isFetchingSingle: true,
        errorMessageSingle: null,
      };
    case TrainerActionTypes.FETCH_SINGLE_TRAINER_SUCCESS:
      return {
        ...state,
        currentTrainer: action.payload,
        isFetchingSingle: false,
      };
    case TrainerActionTypes.FETCH_SINGLE_TRAINER_FAILURE:
      return {
        ...state,
        isFetchingSingle: false,
        errorMessageSingle: action.payload,
        currentTrainer: null,
      };
    case TrainerActionTypes.ADD_NEW_TRAINER_START:
      return {
        ...state,
        addNewTrainer: true,
        errorMessageSingle: null,
      };
    case TrainerActionTypes.ADD_NEW_TRAINER_SUCCESS:
      return {
        ...state,
        addNewTrainer: false,
      };
    case TrainerActionTypes.ADD_NEW_TRAINER_FAILURE:
      return {
        ...state,
        addNewTrainer: false,
        errorMessageSingle: action.payload,
      };
    case TrainerActionTypes.SET_SHOW_TRAINER_STATE:
      return {
        ...state,
        showTrainerState: action.payload,
      };
    case TrainerActionTypes.SET_REMOVING_CLIENT:
      return {
        ...state,
        removingClient: action.payload,
      };
    case TrainerActionTypes.SET_CURRENT_TRAINER:
      return {
        ...state,
        currentTrainer: action.payload,
      };
    case TrainerActionTypes.SET_ALL_TRAINERS:
      return {
        ...state,
        allTrainers: action.payload,
      };
    case TrainerActionTypes.SET_ADD_TRAINER:
      return {
        ...state,
        addTrainer: action.payload,
      };
    case TrainerActionTypes.ADD_TRAINER_AS_RECRUITER_START:
      return {
        ...state,
        addTrainerAsRecruiter: true,
        errorMessageSingle: null,
      };
    case TrainerActionTypes.ADD_TRAINER_AS_RECRUITER_SUCCESS:
      return {
        ...state,
        addTrainerAsRecruiter: false,
      };
    case TrainerActionTypes.ADD_TRAINER_AS_RECRUITER_FAILURE:
      return {
        ...state,
        addTrainerAsRecruiter: false,
        errorMessageSingle: action.payload,
      };
    case TrainerActionTypes.FETCH_TEAM_START:
      return {
        ...state,
        isFetchingTeam: true,
        errorMessageSingle: null,
      };
    case TrainerActionTypes.FETCH_TEAM_SUCCESS:
      return {
        ...state,
        isFetchingTeam: false,
        trainerTeam: action.payload,
      };
    case TrainerActionTypes.FETCH_TEAM_FAILURE:
      return {
        ...state,
        isFetchingTeam: false,
        errorMessageSingle: action.payload,
      };
    case TrainerActionTypes.SET_SHOW_POPUP:
      return {
        ...state,
        showPopup: action.payload,
      };

    case TrainerActionTypes.SET_TRAINER_DOCUMENTS_POPUP:
      return {
        ...state,
        trainerPopup: action.payload,
      };

    case TrainerActionTypes.SET_TRAINER_DOCUMENTS_TAB:
      return {
        ...state,
        trainerDocumentTab: action.payload,
      };

    case TrainerActionTypes.SET_POPUP_DATA:
      return {
        ...state,
        popupData: action.payload,
      };
    case TrainerActionTypes.INITIALIZE_TRAINER_STATE:
      return {
        allTrainers: {},
        isFetching: false,
        errorMessage: null,
        currentTrainer: null,
        isFetchingSingle: false,
        errorMessageSingle: null,
        showTrainerState: false,
        removingClient: false,
        addTrainer: false,
        addNewTrainer: false,
        showPopup: false,
        popupData: {},
        addTrainerAsRecruiter: false,
        trainerPopup: false,
        trainerDocumentTab: null,
      };
    default:
      return state;
  }
};

export default trainerReducer;
