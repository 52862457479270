import DashboardActionTypes from './DashboardActionTypes';
import * as API from '../api';

import URLS from '../../utils/constants/URLS';

export const fetchDashboardDataStart = () => ({
  type: DashboardActionTypes.FETCH_DASHBOARD_DATA_START,
});

export const fetchDashboardDataSuccess = (data) => ({
  type: DashboardActionTypes.FETCH_DASHBOARD_DATA_SUCCESS,
  payload: data,
});

export const fetchDashboardDataFailure = (errorMessage) => ({
  type: DashboardActionTypes.FETCH_DASHBOARD_DATA_FAILURE,
  payload: errorMessage,
});

export const fetchAllClientRankingsStart = () => ({
  type: DashboardActionTypes.FETCH_ALL_CLIENTS_RANKINGS_START,
});

export const fetchAllClientRankingsSuccess = (data) => ({
  type: DashboardActionTypes.FETCH_ALL_CLIENTS_RANKINGS_SUCCESS,
  payload: data,
});

export const fetchAllClientRankingsFailure = (errorMessage) => ({
  type: DashboardActionTypes.FETCH_ALL_CLIENTS_RANKINGS_FAILURE,
  payload: errorMessage,
});

export const setShowMilestoneClients = (state) => ({
  type: DashboardActionTypes.SET_SHOW_MILESTONE_CLIENT,
  payload: state,
});

export const setShowNotificationsFlag = (state) => ({
  type: DashboardActionTypes.SET_SHOW_NOTIFICATIONS_FLAG,
  payload: state,
});

export const setCurrentMilestone = (milestone) => ({
  type: DashboardActionTypes.SET_CURRENT_MILESTONE,
  payload: milestone,
});

export const fetchMilestoneClientsStart = () => ({
  type: DashboardActionTypes.FETCH_MILESTONE_CLIENTS_START,
});

export const fetchMilestoneClientsSuccess = (data) => ({
  type: DashboardActionTypes.FETCH_MILESTONE_CLIENTS_SUCCESS,
  payload: data,
});

export const fetchMilestoneClientsFailure = (errorMessage) => ({
  type: DashboardActionTypes.FETCH_MILESTONE_CLIENTS_FAILURE,
  payload: errorMessage,
});

export const fetchNotificationsStart = () => ({
  type: DashboardActionTypes.FETCH_NOTIFICATIONS_START,
});

export const fetchNotificationsSuccess = (data) => ({
  type: DashboardActionTypes.FETCH_NOTIFICATIONS_SUCCESS,
  payload: data,
});

export const fetchNotificationsFailure = (errorMessage) => ({
  type: DashboardActionTypes.FETCH_NOTIFICATIONS_FAILURE,
  payload: errorMessage,
});

export const updateNotificationsStart = () => ({
  type: DashboardActionTypes.UPDATE_NOTIFICATIONS_START,
});

export const updateNotificationsSuccess = () => ({
  type: DashboardActionTypes.UPDATE_NOTIFICATIONS_SUCCESS,
});

export const updateNotificationsFailure = (errorMessage) => ({
  type: DashboardActionTypes.UPDATE_NOTIFICATIONS_FAILURE,
  payload: errorMessage,
});

export const fetchReportStart = () => ({
  type: DashboardActionTypes.FETCH_REPORT_START,
});

export const fetchTrainerPaymentsSuccess = (data) => ({
  type: DashboardActionTypes.FETCH_TRAINER_REPORT_SUCCESS,
  payload: data,
});

export const fetchUserGrowthSuccess = (data) => ({
  type: DashboardActionTypes.FETCH_USER_GROWTH_SUCCESS,
  payload: data,
});

export const fetchReportFailure = (errorMessage) => ({
  type: DashboardActionTypes.FETCH_REPORT_FAILURE,
  payload: errorMessage,
});

export const fetchTrainerPaymentGraphSuccess = (graphData) => ({
  type: DashboardActionTypes.FETCH_TRAINER_PAYMENTS_GRAPH_SUCCESS,
  payload: graphData,
});

export const fetchTrainerPaymentGraphFailure = (errorMessage) => ({
  type: DashboardActionTypes.FETCH_TRAINER_PAYMENTS_GRAPH_FAILURE,
  payload: errorMessage,
});

export const setTrainerTotalIncome = (total) => ({
  type: DashboardActionTypes.SET_TRAINER_TOTAL_INCOME,
  payload: total,
});

export const initializeDashboardState = () => ({ type: DashboardActionTypes.INITIALIZE_DASHBOARD_STATE });

export const fetchTrainerPaymentGraphDataAsync = () => {
  return (dispatch) => {
    dispatch(fetchReportStart());

    API.get(URLS.trainerPaymentGrowth)
      .then((response) => {
        const labels = ['Months', ...Object.keys(response.data.values[0])];
        const lists = [labels];

        let total = 0;

        response.data.values.forEach((element, ind) => {
          lists.push([response.data.labels[ind], ...Object.values(element)]);
          total += Object.values(element).reduce((sum, earning) => sum + earning, 0);
        });

        dispatch(setTrainerTotalIncome(total));
        dispatch(fetchTrainerPaymentGraphSuccess(lists));
      })
      .catch((error) => {
        dispatch(fetchTrainerPaymentGraphFailure(error.message));
      });
  };
};

export const fetchTrainerPaymentsReportStartAsync = () => {
  return (dispatch) => {
    dispatch(fetchReportStart());
    API.get(URLS.trainerPayments)
      .then((response) => {
        dispatch(fetchTrainerPaymentsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchReportFailure(error.message));
      });
  };
};

export const fetchNotificationsStartAsync = () => {
  return (dispatch) => {
    dispatch(fetchNotificationsStart());
    API.get(URLS.getNotifications)
      .then((response) => {
        dispatch(fetchNotificationsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchNotificationsFailure(error.message));
      });
  };
};

export const updateNotificationsReadStatus = () => {
  return (dispatch) => {
    dispatch(updateNotificationsStart());
    API.patch(URLS.setNotificationsSeen)
      .then(() => {
        dispatch(updateNotificationsSuccess());
      })
      .catch((error) => {
        dispatch(updateNotificationsFailure(error.message));
      });
  };
};

export const fetchUserGrowthGraphStartAsync = (months) => {
  return (dispatch) => {
    dispatch(fetchReportStart());
    API.get(`${URLS.userGrowth}?months=${months}`)
      .then((response) => {
        const labels = ['Day', ...Object.keys(response.data.values[0])];
        const lists = [labels];

        response.data.values.forEach((element, ind) => {
          lists.push([response.data.labels[ind], ...Object.values(element)]);
        });

        dispatch(fetchUserGrowthSuccess(lists));
      })
      .catch((error) => {
        dispatch(fetchReportFailure(error.message));
      });
  };
};

export const fetchDashboardDataStartAsync = () => {
  return (dispatch) => {
    dispatch(fetchDashboardDataStart());
    API.get(URLS.dashboard)
      .then((response) => {
        const reducerData = {
          topClients: response.data.top_clients,
          activeClients: response.data.active_clients,
          activeClientsPerTrainer: response.data.active_clients_per_trainer,
          couponCount: response.data.coupon_count,
          clientsReferred: response.data.clients_referred,
          newClients: response.data.new_clients,
          sessionsTaken: response.data.sessions_taken,
          trainerCount: response.data.trainer_count,
          plansSent: response.data.plans_sent,
          amountEarned: response.data.amount_earned,
          subscriptionsCount: response.data.subscriptions_count,
          totalEarned: response.data.total_earned,
        };
        dispatch(fetchDashboardDataSuccess(reducerData));
      })
      .catch((error) => {
        dispatch(fetchDashboardDataFailure(error));
      });
  };
};

export const fetchAllClientRankingsStartAsync = () => {
  return (dispatch) => {
    dispatch(fetchAllClientRankingsStart());
    API.get(URLS.clientRankings)
      .then((response) => {
        const data = { allClientsRanking: response.data.clients, milestones: response.data.milestones };
        dispatch(fetchAllClientRankingsSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchAllClientRankingsFailure(error));
      });
  };
};
export const fetchMilestoneClientsStartAsync = (uuid) => {
  return (dispatch, getState) => {
    let { milestoneClients } = getState().dashboard;

    dispatch(fetchMilestoneClientsStart());
    API.get(`${URLS.milestoneUsers}${uuid}/`)
      .then((response) => {
        if (!milestoneClients) milestoneClients = {};
        milestoneClients[uuid] = response.data;
        dispatch(fetchMilestoneClientsSuccess({ ...milestoneClients }));
      })
      .catch((error) => {
        dispatch(fetchMilestoneClientsFailure(error));
      });
  };
};
export const showMilestoneListAndFetchClients = (milestone) => {
  return (dispatch) => {
    dispatch(setShowMilestoneClients(true));
    dispatch(setCurrentMilestone(milestone));
    dispatch(fetchMilestoneClientsStartAsync(milestone.uuid));
  };
};
