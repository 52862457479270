import WorkoutBuilderActionTypes from './WorkoutBuilderActionTypes';

export const initializePageState = () => ({
  type: WorkoutBuilderActionTypes.INITIALIZE_PAGE_STATE,
});

export const setCurrentExerciseDetailsState = (pageName) => ({
  type: WorkoutBuilderActionTypes.SET_CURRENT_EXERCISE_DETAILS_STATE,
  payload: pageName,
});

export const setCurrentWorkoutDetailsState = (pageName) => ({
  type: WorkoutBuilderActionTypes.SET_CURRENT_WORKOUT_DETAILS_STATE,
  payload: pageName,
});

export const setCurrentPlanDetailsState = (pageName) => ({
  type: WorkoutBuilderActionTypes.SET_CURRENT_PLAN_DETAILS_STATE,
  payload: pageName,
});

export const setSendPlanState = (state) => ({
  type: WorkoutBuilderActionTypes.SET_SEND_PLAN_STATE,
  payload: state,
});
