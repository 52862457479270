const PaymentActionTypes = {
  SET_PAYMENT_PLAN: 'SET_PAYMENT_PLAN',
  SET_PAYMENT_OPTIONS: 'SET_PAYMENT_OPTIONS',
  SENDING_STRIPE_DATA_START: 'SENDING_STRIPE_DATA_START',
  SENDING_STRIPE_DATA_SUCCESS: 'SENDING_STRIPE_DATA_SUCCESS',
  SENDING_STRIPE_DATA_FAILURE: 'SENDING_STRIPE_DATA_FAILURE',
  PLAN_FETCHING_START: 'PLAN_FETCHING_START',
  PLAN_FETCHING_SUCCESS: 'PLAN_FETCHING_SUCCESS',
  PLAN_FETCHING_FAILURE: 'PLAN_FETCHING_FAILURE',
  SET_USERS_CARD: 'SET_USERS_CARD',
  SET_USERS_SUBSCRIPTION: 'SET_USERS_SUBSCRIPTION',
  SET_BUY_SUBSCRIPTION_STATE: 'SET_BUY_SUBSCRIPTION_STATE',
  UPDATE_CARD_DETAILS_START: 'UPDATE_CARD_DETAILS_START',
  UPDATE_CARD_DETAILS_SUCCESS: 'UPDATE_CARD_DETAILS_SUCCESS',
  UPDATE_CARD_DETAILS_FAILURE: 'UPDATE_CARD_DETAILS_FAILURE',
  SUBSCRIBE_NEW_PLAN_START: 'SUBSCRIBE_NEW_PLAN_START',
  SUBSCRIBE_NEW_PLAN_SUCCESS: 'SUBSCRIBE_NEW_PLAN_SUCCESS',
  SUBSCRIBE_NEW_PLAN_FAILURE: 'SUBSCRIBE_NEW_PLAN_FAILURE',
  CANCEL_SUBSCRIPTION_START: 'CANCEL_SUBSCRIPTION_START',
  CANCEL_SUBSCRIPTION_SUCCESS: 'CANCEL_SUBSCRIPTION_SUCCESS',
  CANCEL_SUBSCRIPTION_FAILURE: 'CANCEL_SUBSCRIPTION_FAILURE',
  INITIALIZE_PAYMENT_REDUCER: 'INITIALIZE_PAYMENT_REDUCER',
  SET_STRIPE_DATA_FLAG: 'SET_STRIPE_DATA_FLAG',
  CARD_RETRIEVE_FAIL: 'CARD_RETRIEVE_FAIL',
  CARD_RETRIEVE_SUCCESSFUL: 'CARD_RETRIEVE_SUCCESSFUL',
};

export default PaymentActionTypes;
