/* eslint-disable no-underscore-dangle */
import React from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { loadStripe } from '@stripe/stripe-js';
import { BrowserRouter } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { store, persistor } from './redux/store';

import { logOut, setAccessAndRefreshToken } from './redux/user/UserAction';

import URLS from './utils/constants/URLS';
import CONSTANTS from './utils/constants/CONSTANTS';

import './index.css';

const { dispatch, getState } = store;

axios.interceptors.request.use((request) => {
  return request;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const user = getState().user.currentUser;
    const refreshToken = user ? user.refresh_token : null;

    /** storing the data of the failed API so that we can call that API again after access token updates */
    const originalRequest = error.config;

    /** if the user is unauthorized and we haven't already tried to resend the request */
    if (refreshToken && error.response.status === CONSTANTS.UNAUTHORIZED && !originalRequest._retry) {
      originalRequest._retry = true;
      axios
        .post(URLS.refreshAccessToken, { refresh: refreshToken })
        .then((response) => {
          dispatch(setAccessAndRefreshToken(response.data));
          /** resend the failed API after updating its access token */
          originalRequest.headers.authorization = `Bearer ${response.data.access_token}`;
          return axios(originalRequest);
        })
        /** if refreshing access token also fails */
        .catch(() => {
          dispatch(logOut());
        });
    }
    return Promise.reject(error);
  }
);

const stripeKey = process.env.REACT_APP_STRIPE_KEY;

const stripePromise = loadStripe(stripeKey);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Elements stripe={stripePromise}>
          <BrowserRouter>
            <DndProvider backend={HTML5Backend}>
              <App />
            </DndProvider>
          </BrowserRouter>
        </Elements>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
