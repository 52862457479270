const TrainerActionTypes = {
  FETCH_ALL_TRAINERS_START: 'FETCH_ALL_TRAINERS_START',
  FETCH_ALL_TRAINERS_SUCCESS: 'FETCH_ALL_TRAINERS_SUCCESS',
  FETCH_ALL_TRAINERS_FAILURE: 'FETCH_ALL_TRAINERS_FAILURE',
  FETCH_SINGLE_TRAINER_START: 'FETCH_SINGLE_TRAINER_START',
  FETCH_SINGLE_TRAINER_SUCCESS: 'FETCH_SINGLE_TRAINER_SUCCESS',
  FETCH_SINGLE_TRAINER_FAILURE: 'FETCH_SINGLE_TRAINER_FAILURE',
  SET_SHOW_TRAINER_STATE: 'SET_SHOW_TRAINER_STATE',
  SET_REMOVING_CLIENT: 'SET_REMOVING_CLIENT',
  SET_CURRENT_TRAINER: 'SET_CURRENT_TRAINER',
  SET_ALL_TRAINERS: 'SET_ALL_TRAINERS',
  SET_ADD_TRAINER: 'SET_ADD_TRAINER',
  INITIALIZE_TRAINER_STATE: 'INITIALIZE_TRAINER_STATE',
  ADD_NEW_TRAINER_START: 'ADD_NEW_TRAINER_START',
  ADD_NEW_TRAINER_SUCCESS: 'ADD_NEW_TRAINER_SUCCESS',
  ADD_NEW_TRAINER_FAILURE: 'ADD_NEW_TRAINER_FAILURE',
  ADD_TRAINER_AS_RECRUITER_START: 'ADD_TRAINER_AS_RECRUITER_START',
  ADD_TRAINER_AS_RECRUITER_SUCCESS: 'ADD_TRAINER_AS_RECRUITER_SUCCESS',
  ADD_TRAINER_AS_RECRUITER_FAILURE: 'ADD_TRAINER_AS_RECRUITER_FAILURE',
  SET_SHOW_POPUP: 'SET_SHOW_POPUP',
  SET_POPUP_DATA: 'SET_POPUP_DATA',
  FETCH_TEAM_START: 'FETCH_TEAM_START',
  FETCH_TEAM_SUCCESS: 'FETCH_TEAM_SUCCESS',
  FETCH_TEAM_FAILURE: 'FETCH_TEAM_FAILURE',
  SET_TRAINER_DOCUMENTS_POPUP: 'SET_TRAINER_DOCUMENTS_POPUP',
  SET_TRAINER_DOCUMENTS_TAB: 'SET_TRAINER_DOCUMENTS_TAB',
};

export default TrainerActionTypes;
