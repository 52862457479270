import CreateWorkoutActionTypes from './WorkoutActionTypes';
import * as API from '../api';

import URLS from '../../utils/constants/URLS';
import CONSTANTS from '../../utils/constants/CONSTANTS';

import { setCurrentWorkoutDetailsState } from '../workoutBuilder/WorkoutBuilderAction';

export const setCurrentWorkout = (workout) => ({
  type: CreateWorkoutActionTypes.SET_CURRENT_WORKOUT,
  payload: workout,
});

export const fetchWorkoutStart = () => ({
  type: CreateWorkoutActionTypes.FETCH_ALL_WORKOUT_START,
});

export const fetchWorkoutSuccess = (workout) => ({
  type: CreateWorkoutActionTypes.FETCH_ALL_WORKOUT_SUCCESS,
  payload: workout,
});

export const fetchWorkoutFailure = (errorMessage) => ({
  type: CreateWorkoutActionTypes.FETCH_ALL_WORKOUT_FAILURE,
  payload: errorMessage,
});
export const deleteCurrentWorkoutStart = () => ({
  type: CreateWorkoutActionTypes.DELETE_CURRENT_WORKOUT_START,
});
export const deleteCurrentWorkoutSuccess = (newList) => ({
  type: CreateWorkoutActionTypes.DELETE_CURRENT_WORKOUT_SUCCESS,
  payload: newList,
});
export const deleteCurrentWorkoutFailure = (errorMessage) => ({
  type: CreateWorkoutActionTypes.DELETE_CURRENT_WORKOUT_FAILURE,
  payload: errorMessage,
});
export const setSaveWorkout = (state) => ({
  type: CreateWorkoutActionTypes.SET_SAVE_WORKOUT,
  payload: state,
});

export const updateOrAddNewWorkout = (workout) => ({
  type: CreateWorkoutActionTypes.UPDATE_OR_ADD_NEW_WORKOUT,
  payload: workout,
});

export const setShowPopup = (state) => ({
  type: CreateWorkoutActionTypes.SET_SHOW_POPUP,
  payload: state,
});

export const updateWorkoutFailure = (state) => ({
  type: CreateWorkoutActionTypes.UPDATE_WORKOUT_FAILURE,
  payload: state,
});
export const setPopupData = (state) => ({
  type: CreateWorkoutActionTypes.SET_POPUP_DATA,
  payload: state,
});

export const initializeWorkoutState = () => ({ type: CreateWorkoutActionTypes.INITIALIZE_WORKOUT_STATE });

export const editCurrentWorkout = (updatedWorkout) => {
  return (dispatch, getState) => {
    const { allWorkout } = getState().workout;

    API.patch(`${URLS.workout}${updatedWorkout.uuid}/`, updatedWorkout)
      .then((response) => {
        dispatch(setCurrentWorkout(response.data));
        allWorkout[updatedWorkout.uuid] = response.data;
        dispatch(updateOrAddNewWorkout(allWorkout));
        dispatch(setSaveWorkout(false));
        dispatch(
          setPopupData({
            heading: 'Edit Workout',
            message: 'Workout updated successfully',
            color: CONSTANTS.POPUP_COLORS.GREEN,
          })
        );
        dispatch(setShowPopup(true));
        dispatch(setCurrentWorkoutDetailsState(CONSTANTS.VIEW_STATE));
      })
      .catch((error) => {
        const errorMsg = error.response.data;
        dispatch(updateWorkoutFailure(errorMsg ? Object.values(errorMsg) : 'Workout has not been edited'));
        dispatch(setSaveWorkout(false));
        dispatch(
          setPopupData({
            heading: 'Edit Workout',
            message: 'Workout could not be edited',
            color: CONSTANTS.POPUP_COLORS.RED,
          })
        );
        dispatch(setShowPopup(true));
        dispatch(setCurrentWorkoutDetailsState(CONSTANTS.VIEW_STATE));
      });
  };
};

export const addNewWorkoutAsync = (newWorkout) => {
  return (dispatch, getState) => {
    const { allWorkout } = getState().workout;

    API.post(URLS.workout, newWorkout)
      .then((response) => {
        dispatch(setCurrentWorkout(response.data));
        allWorkout[response.data.uuid] = response.data;
        dispatch(updateOrAddNewWorkout(allWorkout));
        dispatch(setSaveWorkout(false));
        dispatch(
          setPopupData({
            heading: 'Add Workout',
            message: 'Workout was added successfully',
            color: CONSTANTS.POPUP_COLORS.GREEN,
          })
        );
        dispatch(setShowPopup(true));
        dispatch(setCurrentWorkoutDetailsState(CONSTANTS.VIEW_STATE));
      })
      .catch((error) => {
        const errorMsg = error.response.data;
        dispatch(updateWorkoutFailure(errorMsg ? Object.values(errorMsg) : 'Workout has not been edited'));
        dispatch(setSaveWorkout(false));
        dispatch(
          setPopupData({
            heading: 'Add Workout',
            message: 'Workout could not be added',
            color: CONSTANTS.POPUP_COLORS.RED,
          })
        );
        dispatch(setShowPopup(true));
        dispatch(setCurrentWorkoutDetailsState(CONSTANTS.VIEW_STATE));
      });
  };
};

export const deleteWorkoutAsync = () => {
  return (dispatch, getState) => {
    const { allWorkout } = getState().workout;
    const id = getState().workout.currentWorkout?.uuid;
    dispatch(deleteCurrentWorkoutStart());
    API.remove(`${URLS.workout}${id}/`)
      .then((response) => {
        if (response.status === 204) {
          dispatch(setCurrentWorkoutDetailsState(CONSTANTS.NO_STATE));
          delete allWorkout[id];
          dispatch(deleteCurrentWorkoutSuccess({ ...allWorkout }));
          dispatch(
            setPopupData({
              heading: 'Delete Workout',
              message: 'Workout was deleted successfully',
              color: CONSTANTS.POPUP_COLORS.GREEN,
            })
          );
          dispatch(setShowPopup(true));
        }
      })
      .catch((error) => {
        dispatch(deleteCurrentWorkoutFailure(error));
        dispatch(
          setPopupData({
            heading: 'Delete Workout',
            message: 'Deleting current workout was  unsuccessful',
            color: CONSTANTS.POPUP_COLORS.RED,
          })
        );
        dispatch(setShowPopup(true));
      });
  };
};

export const fetchAllWorkoutStartAsync = () => {
  return (dispatch) => {
    dispatch(fetchWorkoutStart());
    API.get(URLS.workout)
      .then((response) => {
        dispatch(fetchWorkoutSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchWorkoutFailure(error));
      });
  };
};
