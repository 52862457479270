import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default function useGetUnreadChatCount() {
  const allChats = useSelector((state) => state.inbox.allChats);

  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    if (allChats.length) {
      let sum = 0;
      allChats.forEach((chat) => {
        if (chat.unread_messages_count) {
          sum += 1;
        }
      });
      setUnreadCount(sum);
    }
  }, [allChats]);
  return unreadCount;
}
