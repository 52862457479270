import CalendarActionTypes from './CalendarActionType';

import * as API from '../api';

import URLS from '../../utils/constants/URLS';
import CONSTANTS from '../../utils/constants/CONSTANTS';

import { fetchSingleClientStartAsync } from '../clients/ClientAction';

export const setCurrentDate = (date) => ({
  type: CalendarActionTypes.SET_SELECTED_DATE,
  payload: date,
});

export const fetchSessionsStart = () => ({
  type: CalendarActionTypes.FETCH_SESSIONS_START,
});

export const fetchSessionsSuccess = (sessions) => ({
  type: CalendarActionTypes.FETCH_SESSIONS_SUCCESS,
  payload: sessions,
});

export const fetchSessionsFailure = (errorMessage) => ({
  type: CalendarActionTypes.FETCH_SESSIONS_FAILURE,
  payload: errorMessage,
});

export const createSessionStart = () => ({
  type: CalendarActionTypes.CREATE_SESSION_START,
});

export const createSessionSuccess = () => ({
  type: CalendarActionTypes.CREATE_SESSION_SUCCESS,
});

export const createSessionFailure = (errorMessage) => ({
  type: CalendarActionTypes.CREATE_SESSION_FAILURE,
  payload: errorMessage,
});

export const updateOrDeleteSessionStart = () => ({
  type: CalendarActionTypes.UPDATE_OR_DELETE_SESSION_START,
});

export const updateOrDeleteSessionSuccess = () => ({
  type: CalendarActionTypes.UPDATE_OR_DELETE_SESSION_SUCCESS,
});

export const updateOrDeleteSessionFailure = (errorMessage) => ({
  type: CalendarActionTypes.UPDATE_OR_DELETE_SESSION_FAILURE,
  payload: errorMessage,
});

export const setSessions = (state) => ({
  type: CalendarActionTypes.SET_SESSIONS,
  payload: state,
});

export const setCurrentSession = (session) => ({
  type: CalendarActionTypes.SET_CURRENT_SESSION,
  payload: session,
});

export const setShowSessionDetails = (state) => ({
  type: CalendarActionTypes.SET_SHOW_SESSION_DETAILS,
  payload: state,
});

export const setEditSession = (state) => ({
  type: CalendarActionTypes.SET_EDIT_SESSION,
  payload: state,
});

export const setAddSession = (state) => ({
  type: CalendarActionTypes.SET_ADD_SESSION,
  payload: state,
});

export const setErrorMessage = (value) => ({
  type: CalendarActionTypes.SET_ERROR_MESSAGE,
  payload: value,
});

export const setShowPopup = (state) => ({
  type: CalendarActionTypes.SET_SHOW_POPUP,
  payload: state,
});

export const setPopupData = (state) => ({
  type: CalendarActionTypes.SET_POPUP_DATA,
  payload: state,
});

export const setJoinCall = (state) => ({
  type: CalendarActionTypes.SET_JOIN_CALL,
  payload: state,
});

export const fetchCallTokenStart = () => ({
  type: CalendarActionTypes.FETCH_CALL_TOKEN_START,
});

export const fetchCallTokenSuccess = (token) => ({
  type: CalendarActionTypes.FETCH_CALL_TOKEN_SUCCESS,
  payload: token,
});
export const fetchCallTokenFailure = (error) => ({
  type: CalendarActionTypes.FETCH_CALL_TOKEN_FAILURE,
  payload: error,
});

export const initializeCalenderState = () => ({
  type: CalendarActionTypes.INITIALIZE_CALENDAR_STATE,
});

export const initializeSessionState = () => {
  return (dispatch) => {
    dispatch(setShowSessionDetails(false));
    dispatch(setEditSession(false));
    dispatch(setAddSession(false));
    dispatch(setJoinCall(false));
    dispatch(setShowPopup(false));
    dispatch(setErrorMessage(''));
  };
};

export const fetchAllSessionsStartAsync = (date) => {
  return (dispatch, getState) => {
    const { userType } = getState().user;
    const { currentTrainer } = getState().trainers;

    /** when trainer is calling the API we do not need to send the trainer param and if it is an admin
     * the we need to send the trainer uuid
     */

    let url = date ? `${URLS.session}?date=${date}` : `${URLS.session}`;
    if (userType === CONSTANTS.ADMIN) url = `${URLS.session}?date=${date}&trainer_uuid=${currentTrainer.uuid}`;

    dispatch(fetchSessionsStart());

    API.get(url)
      .then((response) => {
        dispatch(fetchSessionsSuccess(response.data.sessions));
      })
      .catch((error) => {
        dispatch(fetchSessionsFailure(error.message));
      });
  };
};

export const addNewSessionAsync = (data, client) => {
  return (dispatch) => {
    const time = data.start_time;
    const startDate = new Date(time * 1000);
    startDate.setHours(0, 0, 0, 0);
    const timestamp = Math.floor(startDate.getTime() / 1000);

    dispatch(createSessionStart());

    API.post(URLS.session, data)
      .then(() => {
        dispatch(createSessionSuccess());
        if (!client) dispatch(fetchAllSessionsStartAsync(timestamp));
        dispatch(setPopupData({ heading: 'Success!', message: 'Session created Successfully!' }));
        dispatch(setShowPopup(true));
        dispatch(setAddSession(false));
        if (client) dispatch(fetchSingleClientStartAsync(client.uuid, true));
      })
      .catch((error) => {
        const errorMessage = error?.response?.data && Object.values(error.response.data);
        dispatch(createSessionFailure(errorMessage ? errorMessage[0] : 'session creation failed'));
      });
  };
};

export const updateSessionStartAsync = (data, id, client) => {
  return (dispatch, getState) => {
    const time = data.start_time;
    const startDate = new Date(time * 1000);
    startDate.setHours(0, 0, 0, 0);
    const timestamp = Math.floor(startDate.getTime() / 1000);
    const { sessions } = getState().calendar;

    dispatch(updateOrDeleteSessionStart());

    API.patch(`${URLS.session}${id}/`, data)
      .then(() => {
        dispatch(updateOrDeleteSessionSuccess());
        if (!client) dispatch(fetchAllSessionsStartAsync(timestamp));
        const updatedSessions = sessions.map((obj) => {
          if (obj.uuid === id) {
            return { ...obj, ...data };
          }
          return obj;
        });
        dispatch(setSessions(updatedSessions));
        dispatch(setPopupData({ heading: 'Success!', message: 'Session Updated Successfully!' }));
        dispatch(setShowPopup(true));
        dispatch(setEditSession(false));
        dispatch(setShowSessionDetails(false));
        if (client) dispatch(fetchSingleClientStartAsync(client.uuid, true));
      })
      .catch((error) => {
        const errorMessage = error?.response?.data && Object.values(error?.response?.data);
        dispatch(updateOrDeleteSessionFailure(errorMessage ? errorMessage[0][0] : 'session updation failed'));
      });
  };
};

export const deleteSessionStartAsync = (data, id, client) => {
  return (dispatch) => {
    const time = data.start_time;
    const startDate = new Date(time * 1000);
    startDate.setHours(0, 0, 0, 0);
    const timestamp = Math.floor(startDate.getTime() / 1000);

    dispatch(updateOrDeleteSessionStart());

    API.remove(`${URLS.session}${id}/`)
      .then(() => {
        dispatch(updateOrDeleteSessionSuccess());
        if (!client) dispatch(fetchAllSessionsStartAsync(timestamp));
        dispatch(setPopupData({ heading: 'Success!', message: 'Session Deleted Successfully!' }));
        dispatch(setShowPopup(true));
        dispatch(setShowSessionDetails(false));
        if (client) dispatch(fetchSingleClientStartAsync(client.uuid, true));
      })
      .catch((error) => {
        const errorMessage = error?.response?.data && Object.values(error?.response?.data);
        dispatch(updateOrDeleteSessionFailure(errorMessage ? errorMessage[0][0] : 'session deletion failed'));
        dispatch(setPopupData({ heading: 'Failure!', message: 'Unable to delete Session' }));
        dispatch(setShowPopup(true));
        dispatch(setShowSessionDetails(false));
      });
  };
};
export const toggleMarkSessionCompleteAsync = (id, isMarkComplete, client) => {
  return (dispatch, getState) => {
    const { sessions, currentSession } = getState().calendar;
    const url = isMarkComplete ? URLS.markSessionAsComplete : URLS.markSessionAsIncomplete;

    dispatch(updateOrDeleteSessionStart());

    API.patch(`${url}${id}/`)
      .then(() => {
        const updatedSessions = sessions.map((obj) => {
          if (obj.uuid === id) {
            return { ...obj, is_complete: isMarkComplete };
          }
          return obj;
        });
        dispatch(setSessions(updatedSessions));
        dispatch(setCurrentSession({ ...currentSession, is_complete: isMarkComplete }));
        dispatch(updateOrDeleteSessionSuccess());
        if (client) dispatch(fetchSingleClientStartAsync(client.uuid, true));
        dispatch(
          setPopupData({
            heading: 'Success!',
            message: `Session marked as ${isMarkComplete ? 'Complete' : 'In-complete'}`,
          })
        );
        dispatch(setShowPopup(true));
      })
      .catch((errorMessage) => {
        dispatch(updateOrDeleteSessionFailure(errorMessage ? errorMessage[0] : 'session updation failed'));
        dispatch(
          setPopupData({
            heading: 'Failure',
            message: `Session was not ${isMarkComplete ? 'Complete' : 'In-complete'}`,
          })
        );
        dispatch(setShowPopup(true));
      });
  };
};

export const fetchVideoCallTokenStart = (sessionId) => {
  return (dispatch) => {
    dispatch(fetchCallTokenStart());

    API.get(`${URLS.getToken}${sessionId}/`)
      .then((response) => {
        dispatch(fetchCallTokenSuccess(response.data.token));
      })
      .catch((error) => {
        dispatch(fetchSessionsFailure(error.message));
      });
  };
};
