import ReportingActionTypes from './ReportingActionTypes';

const INITIAL_STATE = {
  trainerReportSummary: {},
  isFetchingTrainerReport: false,
  trainerDetailedReport: {},
  isFetchingTrainerDetailedReport: false,
  salesReportSummary: {},
  isFetchingSalesReport: false,
  salesDetailedReport: {},
  isFetchingSalesDetailedReport: false,
  clientReportSummary: {},
  isFetchingClientReport: false,
  clientDetailedReport: {},
  isFetchingClientDetailedReport: false,
  salesGraphData: {},
  isFetchingSalesGraph: false,
  errorMessage: null,
};

const PlanReducer = (state = INITIAL_STATE, action = { type: '' }) => {
  switch (action.type) {
    case ReportingActionTypes.GET_TRAINER_REPORT_SUMMARY_START:
      return {
        ...state,
        isFetchingTrainerReport: true,
      };
    case ReportingActionTypes.GET_TRAINER_REPORT_SUMMARY_SUCCESS:
      return {
        ...state,
        trainerReportSummary: action.payload,
        isFetchingTrainerReport: false,
        errorMessage: null,
      };
    case ReportingActionTypes.GET_TRAINER_REPORT_SUMMARY_FAILURE:
      return {
        ...state,
        isFetchingTrainerReport: false,
        errorMessage: action.payload,
      };

    case ReportingActionTypes.GET_TRAINER_DETAILED_REPORT_START:
      return {
        ...state,
        isFetchingTrainerDetailedReport: true,
      };
    case ReportingActionTypes.GET_TRAINER_DETAILED_REPORT_SUCCESS:
      return {
        ...state,
        trainerDetailedReport: action.payload,
        isFetchingTrainerDetailedReport: false,
        errorMessage: null,
      };
    case ReportingActionTypes.GET_TRAINER_DETAILED_REPORT_FAILURE:
      return {
        ...state,
        isFetchingTrainerDetailedReport: false,
        errorMessage: action.payload,
      };

    case ReportingActionTypes.GET_SALES_DETAILED_REPORT_START:
      return {
        ...state,
        isFetchingSalesDetailedReport: true,
      };
    case ReportingActionTypes.GET_SALES_DETAILED_REPORT_SUCCESS:
      return {
        ...state,
        salesDetailedReport: action.payload,
        isFetchingSalesDetailedReport: false,
        errorMessage: null,
      };
    case ReportingActionTypes.GET_SALES_DETAILED_REPORT_FAILURE:
      return {
        ...state,
        isFetchingSalesDetailedReport: false,
        errorMessage: action.payload,
      };

    case ReportingActionTypes.GET_SALES_GRAPH_START:
      return {
        ...state,
        isFetchingSalesGraph: true,
      };
    case ReportingActionTypes.GET_SALES_GRAPH_SUCCESS:
      return {
        ...state,
        salesGraphData: action.payload,
        isFetchingSalesGraph: false,
        errorMessage: null,
      };
    case ReportingActionTypes.GET_SALES_GRAPH_FAILURE:
      return {
        ...state,
        isFetchingSalesGraph: false,
        errorMessage: action.payload,
      };

    case ReportingActionTypes.GET_CLIENT_REPORT_SUMMARY_START:
      return {
        ...state,
        isFetchingClientReport: true,
      };
    case ReportingActionTypes.GET_CLIENT_REPORT_SUMMARY_SUCCESS:
      return {
        ...state,
        clientReportSummary: action.payload,
        isFetchingClientReport: false,
        errorMessage: null,
      };
    case ReportingActionTypes.GET_CLIENT_REPORT_SUMMARY_FAILURE:
      return {
        ...state,
        isFetchingClientReport: false,
        errorMessage: action.payload,
      };

    case ReportingActionTypes.GET_CLIENT_DETAILED_REPORT_START:
      return {
        ...state,
        isFetchingClientDetailedReport: true,
      };
    case ReportingActionTypes.GET_CLIENT_DETAILED_REPORT_SUCCESS:
      return {
        ...state,
        clientDetailedReport: action.payload,
        isFetchingClientDetailedReport: false,
        errorMessage: null,
      };
    case ReportingActionTypes.GET_CLIENT_DETAILED_REPORT_FAILURE:
      return {
        ...state,
        isFetchingClientDetailedReport: false,
        errorMessage: action.payload,
      };

    case ReportingActionTypes.GET_SALES_REPORT_SUMMARY_START:
      return {
        ...state,
        isFetchingSalesReport: true,
      };
    case ReportingActionTypes.GET_SALES_REPORT_SUMMARY_SUCCESS:
      return {
        ...state,
        salesReportSummary: action.payload,
        isFetchingSalesReport: false,
        errorMessage: null,
      };
    case ReportingActionTypes.GET_SALES_REPORT_SUMMARY_FAILURE:
      return {
        ...state,
        isFetchingSalesReport: false,
        errorMessage: action.payload,
      };

    case ReportingActionTypes.INITIALIZE_REPORTING_STATE:
      return {
        trainerReportSummary: {},
        isFetchingTrainerReport: false,
        trainerDetailedReport: {},
        isFetchingTrainerDetailedReport: false,
        salesReportSummary: {},
        isFetchingSalesReport: false,
        salesDetailedReport: {},
        isFetchingSalesDetailedReport: false,
        clientReportSummary: {},
        isFetchingClientReport: false,
        clientDetailedReport: {},
        isFetchingClientDetailedReport: false,
        errorMessage: null,
      };
    default:
      return state;
  }
};

export default PlanReducer;
