import CONSTANTS from '../../utils/constants/CONSTANTS';
import SignupActionTypes from './SignupActionTypes';

const INITIAL_STATE = {
  isSigningUp: false,
  errorMessage: null,
  currentUser: null,
  idealTime: null,
  userDetails: {},
  userEmail: null,
  sessionDetails: {},
  currentForm: CONSTANTS.INITIAL_DETAILS,
  currentEmployeeForm: CONSTANTS.EMPLOYEE_CODE,
  isFetching: false,
  isFetchingPrograms: false,
  programsDetails: {},
  programType: null,
  sessionDuration: null,
  idealSummaryTime: null,
  preferredTime: null,
  timeValues: {},
  couponProgramType: {},
  isFetchingProgramType: false,
  sessionPrice: {},
  isApplyingCoupon: false,
  forgotPassword: false,
  isSendingEmail: false,
  changePassword: false,
  isChangingPassword: false,
  isVerifying: false,
};

const signupReducer = (state = INITIAL_STATE, action = { type: '' }) => {
  switch (action.type) {
    case SignupActionTypes.REGISTER_USER_START:
      return {
        ...state,
        isSigningUp: true,
        errorMessage: null,
      };
    case SignupActionTypes.REGISTER_USER_SUCCESS:
      return {
        ...state,
        isSigningUp: false,
        errorMessage: null,
        currentUser: action.payload,
      };
    case SignupActionTypes.REGISTER_USER_FAILURE:
      return {
        ...state,
        isSigningUp: false,
        errorMessage: action.payload,
      };

    case SignupActionTypes.FORGOT_PASSWORD_START:
      return {
        ...state,
        isSendingEmail: true,
        errorMessage: null,
      };
    case SignupActionTypes.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isSendingEmail: false,
        errorMessage: null,
        forgotPassword: action.payload,
      };

    case SignupActionTypes.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        isSendingEmail: false,
        errorMessage: action.payload,
      };

    case SignupActionTypes.CHANGE_PASSWORD_START:
      return {
        ...state,
        isChangingPassword: true,
        errorMessage: null,
      };
    case SignupActionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isChangingPassword: false,
        errorMessage: null,
        changePassword: action.payload,
      };

    case SignupActionTypes.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isChangingPassword: false,
        errorMessage: action.payload,
      };

    case SignupActionTypes.APPLY_DISCOUNT_COUPON_START:
      return {
        ...state,
        isApplyingCoupon: true,
        errorMessage: null,
      };
    case SignupActionTypes.APPLY_DISCOUNT_COUPON_SUCCESS:
      return {
        ...state,
        isApplyingCoupon: false,
        errorMessage: null,
      };

    case SignupActionTypes.APPLY_DISCOUNT_COUPON_FAILURE:
      return {
        ...state,
        isApplyingCoupon: false,
        errorMessage: action.payload,
      };

    case SignupActionTypes.GET_PROGRAMS_START:
      return {
        ...state,
        isFetchingPrograms: true,
        errorMessage: null,
      };

    case SignupActionTypes.GET_PROGRAM_TYPE_START:
      return {
        ...state,
        isFetchingProgramType: true,
        errorMessage: null,
      };

    case SignupActionTypes.GET_PROGRAMS_SUCCESS:
      return {
        ...state,
        isFetchingPrograms: false,
        errorMessage: null,
        programsDetails: action.payload,
      };

    case SignupActionTypes.GET_PROGRAM_TYPE_SUCCESS:
      return {
        ...state,
        isFetchingProgramType: false,
        errorMessage: null,
        couponProgramType: action.payload,
      };

    case SignupActionTypes.GET_PROGRAMS_FAILURE:
      return {
        ...state,
        isFetchingPrograms: false,
        errorMessage: action.payload,
      };

    case SignupActionTypes.GET_PROGRAM_TYPE_FAILURE:
      return {
        ...state,
        isFetchingProgramType: false,
        errorMessage: action.payload,
      };

    case SignupActionTypes.FETCH_USER_START:
      return {
        ...state,
        isFetching: true,
        errorMessage: null,
      };
    case SignupActionTypes.FETCH_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errorMessage: null,
        currentUser: action.payload,
      };
    case SignupActionTypes.FETCH_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case SignupActionTypes.SET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
      };
    case SignupActionTypes.SET_USER_EMAIL:
      return {
        ...state,
        userEmail: action.payload,
      };

    case SignupActionTypes.SET_IS_SIGNING_UP:
      return {
        ...state,
        isSigningUp: action.payload,
      };

    case SignupActionTypes.SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
      };

    case SignupActionTypes.SET_SESSIONS_DETAILS:
      return {
        ...state,
        sessionDetails: action.payload,
      };

    case SignupActionTypes.SET_SESSION_PRICE:
      return {
        ...state,
        sessionPrice: action.payload,
      };

    case SignupActionTypes.SET_PROGRAM_TYPE:
      return {
        ...state,
        programType: action.payload,
      };

    case SignupActionTypes.SET_TIME_VALUES:
      return {
        ...state,
        timeValues: action.payload,
      };

    case SignupActionTypes.SET_CURRENT_FORM:
      return {
        ...state,
        currentForm: action.payload,
      };

    case SignupActionTypes.SET_EMPLOYEE_FORM:
      return {
        ...state,
        currentEmployeeForm: action.payload,
      };

    case SignupActionTypes.SET_SESSION_DURATION:
      return {
        ...state,
        sessionDuration: action.payload,
      };

    case SignupActionTypes.SET_IDEAL_SUMMARY_TIME:
      return {
        ...state,
        idealSummaryTime: action.payload,
      };

    case SignupActionTypes.SET_IDEAL_TIME:
      return {
        ...state,
        idealTime: action.payload,
      };

    case SignupActionTypes.SET_PREFERRED_TIME:
      return {
        ...state,
        preferredTime: action.payload,
      };

    case SignupActionTypes.VERIFY_REFERRAL_CODE_START:
      return {
        ...state,
        isVerifying: true,
      };

    case SignupActionTypes.VERIFY_REFERRAL_CODE_SUCCESS:
      return {
        ...state,
        isVerifying: false,
      };

    case SignupActionTypes.VERIFY_REFERRAL_CODE_FAILURE:
      return {
        ...state,
        isVerifying: false,
        errorMessage: action.payload,
      };
    case SignupActionTypes.INITIALIZE_SIGNUP_STATE:
      return {
        isSigningUp: false,
        errorMessage: null,
        currentUser: null,
        idealTime: null,
        userDetails: {},
        sessionDetails: {},
        currentForm: CONSTANTS.INITIAL_DETAILS,
        currentEmployeeForm: CONSTANTS.EMPLOYEE_CODE,
        isFetching: false,
        isFetchingPrograms: false,
        programsDetails: {},
        programType: null,
        sessionDuration: null,
        idealSummaryTime: null,
        preferredTime: null,
        timeValues: {},
        couponProgramType: {},
        isFetchingProgramType: false,
        sessionPrice: {},
        isApplyingCoupon: false,
      };

    default:
      return state;
  }
};

export default signupReducer;
