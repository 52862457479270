import { Link } from 'react-router-dom';

import './index.scss';

const SideNavOption = ({ handleClick, heading, selected, location }) => {
  return (
    <Link className={`${selected ? 'builder-selected' : ''} side-nav-options`} to={location} onClick={handleClick}>
      <div className="side-nav-heading"> {heading}</div>
    </Link>
  );
};

export default SideNavOption;
