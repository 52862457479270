import CalendarActionTypes from './CalendarActionType';

const INITIAL_STATE = {
  selectedDate: null,
  fetchingSessions: false,
  creatingSession: false,
  updatingSession: false,
  sessions: [],
  errorMessage: null,
  currentSession: null,
  showSessionDetails: false,
  editSession: false,
  addSession: false,
  showPopup: false,
  popupData: {},
  joinCall: false,
  fetchingToken: false,
  callToken: null,
};

const calendarReducer = (state = INITIAL_STATE, action = { type: '' }) => {
  switch (action.type) {
    case CalendarActionTypes.SET_SELECTED_DATE:
      return {
        ...state,
        selectedDate: action.payload,
      };
    case CalendarActionTypes.FETCH_SESSIONS_START:
      return {
        ...state,
        fetchingSessions: true,
        errorMessage: null,
      };
    case CalendarActionTypes.FETCH_SESSIONS_SUCCESS:
      return {
        ...state,
        fetchingSessions: false,
        sessions: action.payload,
        errorMessage: null,
      };
    case CalendarActionTypes.FETCH_SESSIONS_FAILURE:
      return {
        ...state,
        fetchingSessions: false,
        errorMessage: action.payload,
      };
    case CalendarActionTypes.CREATE_SESSION_START:
      return {
        ...state,
        creatingSession: true,
        errorMessage: null,
      };
    case CalendarActionTypes.CREATE_SESSION_SUCCESS:
      return {
        ...state,
        creatingSession: false,
        errorMessage: null,
      };
    case CalendarActionTypes.CREATE_SESSION_FAILURE:
      return {
        ...state,
        creatingSession: false,
        errorMessage: action.payload,
      };
    case CalendarActionTypes.SET_SESSIONS:
      return {
        ...state,
        sessions: action.payload,
      };
    case CalendarActionTypes.SET_CURRENT_SESSION:
      return {
        ...state,
        currentSession: action.payload,
      };
    case CalendarActionTypes.SET_SHOW_SESSION_DETAILS:
      return {
        ...state,
        showSessionDetails: action.payload,
      };
    case CalendarActionTypes.UPDATE_OR_DELETE_SESSION_SUCCESS:
      return {
        ...state,
        updatingSession: false,
      };
    case CalendarActionTypes.UPDATE_OR_DELETE_SESSION_START:
      return {
        ...state,
        updatingSession: true,
      };
    case CalendarActionTypes.UPDATE_OR_DELETE_SESSION_FAILURE:
      return {
        ...state,
        updatingSession: false,
        errorMessage: action.payload,
      };
    case CalendarActionTypes.SET_EDIT_SESSION:
      return {
        ...state,
        editSession: action.payload,
      };
    case CalendarActionTypes.SET_ADD_SESSION:
      return {
        ...state,
        addSession: action.payload,
      };
    case CalendarActionTypes.SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case CalendarActionTypes.SET_SHOW_POPUP:
      return {
        ...state,
        showPopup: action.payload,
      };
    case CalendarActionTypes.SET_POPUP_DATA:
      return {
        ...state,
        popupData: action.payload,
      };
    case CalendarActionTypes.SET_JOIN_CALL:
      return {
        ...state,
        joinCall: action.payload,
      };
    case CalendarActionTypes.FETCH_CALL_TOKEN_START:
      return {
        ...state,
        fetchingToken: true,
      };
    case CalendarActionTypes.FETCH_CALL_TOKEN_SUCCESS:
      return {
        ...state,
        fetchingToken: false,
        callToken: action.payload,
      };
    case CalendarActionTypes.FETCH_CALL_TOKEN_FAILURE:
      return {
        ...state,
        fetchingToken: false,
        errorMessage: action.payload,
      };
    case CalendarActionTypes.INITIALIZE_CALENDAR_STATE:
      return {
        selectedDate: null,
        fetchingSessions: false,
        creatingSession: false,
        updatingSession: false,
        sessions: [],
        errorMessage: null,
        currentSession: null,
        showSessionDetails: false,
        editSession: false,
        addSession: false,
        showPopup: false,
        popupData: {},
        joinCall: false,
        fetchingToken: false,
        callToken: null,
      };

    default:
      return state;
  }
};
export default calendarReducer;
