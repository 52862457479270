/* eslint-disable import/no-cycle */
import axios from 'axios';

import SignupActionTypes from './SignupActionTypes';

import CONSTANTS from '../../utils/constants/CONSTANTS';
import URLS from '../../utils/constants/URLS';

import { setPaymentPlan, getPaymentOptions } from '../payments/PaymentAction';
import { isObject, handleErrorMessage } from '../../utils/helperFunctions';

import { userLoginSuccess } from '../user/UserAction';
import { clientSignedUp } from '../clients/ClientAction';
import { setEmployerCode } from '../employerCode/EmployerCodeActions';

export const registerUserStart = () => ({
  type: SignupActionTypes.REGISTER_USER_START,
});

export const initializeSignUpState = () => ({
  type: SignupActionTypes.INITIALIZE_SIGNUP_STATE,
});

export const registerUserSuccess = (user) => ({
  type: SignupActionTypes.REGISTER_USER_SUCCESS,
  payload: user,
});

export const registerUserFailure = (error) => ({
  type: SignupActionTypes.REGISTER_USER_FAILURE,
  payload: error,
});

export const changePasswordStart = () => ({
  type: SignupActionTypes.CHANGE_PASSWORD_START,
});

export const changePasswordSuccess = (user) => ({
  type: SignupActionTypes.CHANGE_PASSWORD_SUCCESS,
  payload: user,
});

export const changePasswordFailure = (error) => ({
  type: SignupActionTypes.CHANGE_PASSWORD_FAILURE,
  payload: error,
});

export const forgotPasswordStart = () => ({
  type: SignupActionTypes.FORGOT_PASSWORD_START,
});

export const forgotPasswordSuccess = (user) => ({
  type: SignupActionTypes.FORGOT_PASSWORD_SUCCESS,
  payload: user,
});

export const forgotPasswordFailure = (error) => ({
  type: SignupActionTypes.FORGOT_PASSWORD_FAILURE,
  payload: error,
});

export const applyDiscountCouponStart = () => ({
  type: SignupActionTypes.APPLY_DISCOUNT_COUPON_START,
});

export const applyDiscountCouponSuccess = () => ({
  type: SignupActionTypes.APPLY_DISCOUNT_COUPON_SUCCESS,
});

export const applyDiscountCouponFailure = (error) => ({
  type: SignupActionTypes.APPLY_DISCOUNT_COUPON_FAILURE,
  payload: error,
});

export const setIsSigningUp = (flag) => ({
  type: SignupActionTypes.SET_IS_SIGNING_UP,
  payload: flag,
});

export const getProgramsStart = () => ({
  type: SignupActionTypes.GET_PROGRAMS_START,
});

export const getProgramTypeStart = () => ({
  type: SignupActionTypes.GET_PROGRAM_TYPE_START,
});

export const getProgramsSuccess = (programs) => ({
  type: SignupActionTypes.GET_PROGRAMS_SUCCESS,
  payload: programs,
});

export const getProgramTypeSuccess = (programType) => ({
  type: SignupActionTypes.GET_PROGRAM_TYPE_SUCCESS,
  payload: programType,
});

export const getProgramsFailure = (error) => ({
  type: SignupActionTypes.GET_PROGRAMS_FAILURE,
  payload: error,
});

export const getProgramTypeFailure = (error) => ({
  type: SignupActionTypes.GET_PROGRAM_TYPE_FAILURE,
  payload: error,
});

export const fetchUserStart = () => ({
  type: SignupActionTypes.FETCH_USER_START,
});

export const fetchUserSuccess = (user) => ({
  type: SignupActionTypes.FETCH_USER_SUCCESS,
  payload: user,
});

export const fetchUserFailure = (error) => ({
  type: SignupActionTypes.FETCH_USER_FAILURE,
  payload: error,
});

export const setUserDetails = (user) => ({
  type: SignupActionTypes.SET_USER_DETAILS,
  payload: user,
});
export const setUserEmail = (email) => ({
  type: SignupActionTypes.SET_USER_EMAIL,
  payload: email,
});
export const setSessionDetails = (session) => ({
  type: SignupActionTypes.SET_SESSIONS_DETAILS,
  payload: session,
});

export const setSessionPrice = (price) => ({
  type: SignupActionTypes.SET_SESSION_PRICE,
  payload: price,
});

export const setProgramType = (program) => ({
  type: SignupActionTypes.SET_PROGRAM_TYPE,
  payload: program,
});

export const setTimeValues = (time) => ({
  type: SignupActionTypes.SET_TIME_VALUES,
  payload: time,
});

export const setCurrentForm = (form) => ({
  type: SignupActionTypes.SET_CURRENT_FORM,
  payload: form,
});

export const setCurrentEmployeeForm = (form) => ({
  type: SignupActionTypes.SET_EMPLOYEE_FORM,
  payload: form,
});

export const setSessionDuration = (duration) => ({
  type: SignupActionTypes.SET_SESSION_DURATION,
  payload: duration,
});

export const setErrorMsg = (error) => ({
  type: SignupActionTypes.SET_ERROR_MESSAGE,
  payload: error,
});

export const setIdealSummaryTime = (idealTime) => ({
  type: SignupActionTypes.SET_IDEAL_SUMMARY_TIME,
  payload: idealTime,
});

export const setPreferredTime = (preferredTime) => ({
  type: SignupActionTypes.SET_PREFERRED_TIME,
  payload: preferredTime,
});

export const setIdealTime = (time) => ({
  type: SignupActionTypes.SET_IDEAL_TIME,
  payload: time,
});

export const verifyEmailStart = () => ({
  type: SignupActionTypes.VERIFY_EMAIL_START,
});

export const verifyEmailSuccess = () => ({
  type: SignupActionTypes.VERIFY_EMAIL_SUCCESS,
});

export const verifyEmailFailure = (errorMessage) => ({
  type: SignupActionTypes.VERIFY_EMAIL_FAILURE,
  payload: errorMessage,
});

export const verifyReferralCodeStart = () => ({
  type: SignupActionTypes.VERIFY_REFERRAL_CODE_START,
});

export const verifyReferralCodeSuccess = () => ({
  type: SignupActionTypes.VERIFY_REFERRAL_CODE_SUCCESS,
});

export const verifyReferralCodeFailure = (error) => ({
  type: SignupActionTypes.VERIFY_REFERRAL_CODE_FAILURE,
  payload: error,
});

export const verifyReferralCodeAsync = (referralCode, setShowPopup, moveToNextPage) => {
  return (dispatch, getState) => {
    const accessToken = getState().signup.currentUser?.access_token;

    dispatch(verifyReferralCodeStart());
    axios
      .post(
        URLS.addReferralCode,
        { referral_code: referralCode },
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(() => {
        dispatch(verifyReferralCodeSuccess());
        setShowPopup(true);
        if (moveToNextPage) dispatch(setCurrentForm(CONSTANTS.VERIFY_EMAIL));
      })
      .catch((error) => {
        dispatch(verifyReferralCodeFailure(error.response.data.detail));
        dispatch(setCurrentForm(CONSTANTS.REFERRAL_CODE));
      });
  };
};

export const getProgramPackages = (setErrorMessage, isSignedIn) => {
  return (dispatch, getState) => {
    let accessToken = getState().signup.currentUser?.profile.access_token;
    if (isSignedIn) {
      accessToken = getState().user.currentUser.access_token;
    }

    setErrorMessage(null);
    dispatch(getProgramsStart());
    axios
      .get(URLS.getPrograms, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        setErrorMessage(null);
        dispatch(getProgramsSuccess(response.data));
      })
      .catch((error) => {
        setErrorMessage('Program Packages Not Fetched');
        dispatch(getProgramsFailure(error.message));
      });
  };
};

export const getProgramType = (setErrorMessage) => {
  return (dispatch, getState) => {
    const accessToken = getState().signup.currentUser?.access_token;

    setErrorMessage(null);
    dispatch(getProgramTypeStart());
    axios
      .get(URLS.getProgramTypes, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        dispatch(getProgramTypeSuccess(response.data));
      })
      .catch((error) => {
        setErrorMessage('Coupon code program type not fetched');
        dispatch(getProgramTypeFailure(error.message));
      });
  };
};

export const applyDiscountCoupon = (data, setErrorMessage, setCouponCodeSuccess, isResubscribePage, moveToNextPage) => {
  return (dispatch, getState) => {
    let accessToken = getState().signup.currentUser?.profile.access_token;
    if (isResubscribePage) {
      accessToken = getState().user.currentUser.access_token;
    }
    dispatch(applyDiscountCouponStart());

    axios
      .post(URLS.applyCoupon, data, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      })
      .then(() => {
        dispatch(applyDiscountCouponSuccess());
        dispatch(getProgramPackages(setErrorMessage, isResubscribePage));
        dispatch(getPaymentOptions(isResubscribePage));
        setErrorMessage('');
        setCouponCodeSuccess('Coupon Code Applied');
        if (moveToNextPage) dispatch(setCurrentForm(CONSTANTS.VERIFY_EMAIL));
      })
      .catch((error) => {
        setCouponCodeSuccess('');
        setErrorMessage('Invalid Code. Contact sales@omniactive.com');
        dispatch(setErrorMsg('Invalid Code. Contact sales@omniactive.com'));
        dispatch(applyDiscountCouponFailure(error.message));
        dispatch(setCurrentEmployeeForm(CONSTANTS.EMPLOYER_CODE));
      });
  };
};

export const verifyCouponCodeAsync = (data, setShowPopup, handleFailure, isCorporate, setCouponCodeSuccess) => {
  return (dispatch) => {
    dispatch(verifyReferralCodeStart());
    axios
      .post(URLS.validateCoupon, data)
      .then(() => {
        handleFailure('');
        if (setCouponCodeSuccess) setCouponCodeSuccess('Coupon Code Verified');
        dispatch(verifyReferralCodeSuccess());
        if (isCorporate) {
          setShowPopup(true);
          dispatch(setEmployerCode(data.coupon_code));
        }
      })
      .catch((error) => {
        if (!isCorporate) setCouponCodeSuccess('');
        const errorMessage = handleErrorMessage(error);
        dispatch(verifyReferralCodeFailure(errorMessage || 'Invalid Coupon Code'));
        handleFailure(errorMessage || 'Invalid Coupon Code');
      });
  };
};

export const registerUserStartAsync = (account, referralCode, setErrorMessage, setShowPopup) => {
  return (dispatch) => {
    setErrorMessage(null);
    dispatch(registerUserStart());

    axios
      .post(URLS.registerUser, account)
      .then((response) => {
        dispatch(registerUserSuccess(response.data));
        dispatch(setUserDetails({ date_of_birth: account.date_of_birth }));
        dispatch(setUserEmail(account.email));
        dispatch(setPaymentPlan(null));
        if (referralCode) dispatch(verifyReferralCodeAsync(referralCode, setShowPopup, true));
        else {
          dispatch(setCurrentForm(CONSTANTS.VERIFY_EMAIL));
          dispatch(setCurrentEmployeeForm(CONSTANTS.VERIFY_EMAIL));
        }
      })
      .catch((error) => {
        const errorMessage = error?.response?.data ? Object.values(error.response.data) : null;
        setErrorMessage(errorMessage || 'Signup Failed. ');
        dispatch(registerUserFailure(error.message));
      });
  };
};

export const verifyEmailStartAsync = (otp, setShowPopup, setError, onValidated, mailChimpData, setVerifyingOtp) => {
  return (dispatch, getState) => {
    const accessToken = getState().signup.currentUser?.access_token;

    dispatch(verifyEmailStart());
    axios
      .post(
        URLS.verifyEmail,
        { otp },
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(() => {
        dispatch(verifyEmailSuccess());
        onValidated(mailChimpData);
        setShowPopup(true);
        if (setVerifyingOtp) setVerifyingOtp(false);
      })
      .catch((error) => {
        const errorMessage =
          error?.response?.data && Object.values(error.response.data)
            ? error.response.data
            : CONSTANTS.DEFAULT_ERROR_MESSAGE;
        setError(errorMessage.detail.toString());
        if (setVerifyingOtp) setVerifyingOtp(false);
        dispatch(verifyEmailFailure(errorMessage));
      });
  };
};

export const resendVerificationCodeStart = (setSendingCode, setTimer, setErrorMessage) => {
  return (dispatch, getState) => {
    const accessToken = getState().signup.currentUser?.access_token;

    if (setSendingCode) setSendingCode(true);
    axios
      .post(
        URLS.resendVerificationEmail,
        {},
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(() => {
        if (setSendingCode) setSendingCode(false);
        if (setTimer) setTimer(30);
      })
      .catch((error) => {
        setErrorMessage(error?.response || CONSTANTS.DEFAULT_ERROR_MESSAGE);
      });
  };
};

export const createUserProfileStartAsync = (setErrorMessage) => {
  return (dispatch, getState) => {
    const { userDetails } = getState().signup;
    let accessToken = getState().signup.currentUser?.access_token;
    const profileComplete = getState().signup.currentUser.profile.is_profile_complete;
    if (profileComplete) {
      accessToken = getState().signup.currentUser?.profile.access_token;
    }

    setErrorMessage(null);
    dispatch(registerUserStart());
    axios
      .patch(URLS.onboardingSurvey, userDetails, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        dispatch(registerUserSuccess(response.data));
        const updatedCurrentUser = {
          email: response.data.email,
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          middle_name: response.data.middle_name,
          name: response.data.name,
          profile: response.data.profile,
          user_type: response.data.user_type,
          uuid: response.data.uuid,
          access_token: response.data.profile.access_token,
          refresh_token: response.data.profile.refresh_token,
        };
        dispatch(userLoginSuccess(updatedCurrentUser));
        clientSignedUp();
      })
      .catch((error) => {
        const errorResponse = error?.response?.data;
        const errorMessage = isObject(errorResponse) ? Object.values(errorResponse)[0] : null;
        setErrorMessage(errorMessage || 'Signup Failed');
        dispatch(registerUserFailure(error.message));
      });
  };
};

export const forgotPasswordStartAsync = (emailAddress, setErrorMessage, setSuccessMessage) => {
  return (dispatch) => {
    setErrorMessage(null);
    setSuccessMessage(null);
    dispatch(forgotPasswordStart());
    axios
      .post(URLS.forgotPassword, emailAddress)
      .then((response) => {
        dispatch(forgotPasswordSuccess(response.data));
        setSuccessMessage('Password reset email has been sent successfully');
      })
      .catch((error) => {
        setErrorMessage(error.message);
        dispatch(forgotPasswordFailure(error.message));
      });
  };
};

export const changePasswordStartAsync = (parameters, setErrorMessage, setSuccessMessage) => {
  return (dispatch) => {
    setErrorMessage(null);
    setSuccessMessage(null);
    dispatch(changePasswordStart());
    axios
      .post(URLS.changePassword, parameters)
      .then((response) => {
        dispatch(changePasswordSuccess(response.data));
        setSuccessMessage('Your password has been updated successfully');
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.detail || 'something went wrong, please try again later';
        setErrorMessage(errorMessage);
        dispatch(changePasswordFailure(errorMessage));
      });
  };
};
