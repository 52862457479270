const CalendarActionTypes = {
  SET_SELECTED_DATE: 'SET_SELECTED_DATE',
  FETCH_SESSIONS_START: 'FETCH_SESSIONS_START',
  FETCH_SESSIONS_SUCCESS: 'FETCH_SESSIONS_SUCCESS',
  FETCH_SESSIONS_FAILURE: 'FETCH_SESSIONS_FAILURE',
  CREATE_SESSION_START: 'CREATE_SESSION_START',
  CREATE_SESSION_SUCCESS: 'CREATE_SESSION_SUCCESS',
  CREATE_SESSION_FAILURE: 'CREATE_SESSION_FAILURE',
  UPDATE_OR_DELETE_SESSION_START: 'UPDATE_OR_DELETE_SESSION_START',
  UPDATE_OR_DELETE_SESSION_SUCCESS: 'UPDATE_OR_DELETE_SESSION_SUCCESS',
  UPDATE_OR_DELETE_SESSION_FAILURE: 'UPDATE_OR_DELETE_SESSION_FAILURE',
  SET_SESSIONS: 'SET_SESSIONS',
  SET_CURRENT_SESSION: 'SET_CURRENT_SESSION',
  SET_SHOW_SESSION_DETAILS: 'SET_SHOW_SESSION_DETAILS',
  SET_EDIT_SESSION: 'SET_EDIT_SESSION',
  SET_ADD_SESSION: 'SET_ADD_SESSION',
  SET_AVAILABLE_SESSIONS: 'SET_AVAILABLE_SESSIONS',
  SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
  SET_SHOW_POPUP: 'SET_SHOW_POPUP',
  SET_POPUP_DATA: 'SET_POPUP_DATA',
  SET_JOIN_CALL: 'SET_JOIN_CALL',
  FETCH_CALL_TOKEN_START: 'FETCH_CALL_TOKEN_START',
  FETCH_CALL_TOKEN_SUCCESS: 'FETCH_CALL_TOKEN_SUCCESS',
  FETCH_CALL_TOKEN_FAILURE: 'FETCH_CALL_TOKEN_FAILURE',
  INITIALIZE_CALENDAR_STATE: 'INITIALIZE_CALENDAR_STATE',
};

export default CalendarActionTypes;
