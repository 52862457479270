const SignupActionTypes = {
  REGISTER_USER_START: 'REGISTER_USER_START',
  REGISTER_USER_SUCCESS: 'REGISTER_USER_SUCCESS',
  REGISTER_USER_FAILURE: 'REGISTER_USER_FAILURE',
  FETCH_USER_START: 'FETCH_USER_START',
  FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
  FETCH_USER_FAILURE: 'FETCH_USER_FAILURE',
  SET_USER_DETAILS: 'SET_USER_DETAILS',
  SET_USER_EMAIL: 'SET_USER_EMAIL',
  SET_PAYMENT_PLAN: 'SET_PAYMENT_PLAN',
  SET_PAYMENT_OPTIONS: 'SET_PAYMENT_OPTIONS',
  SET_CURRENT_FORM: 'SET_CURRENT_FORM',
  SET_EMPLOYEE_FORM: 'SET_EMPLOYEE_FORM',
  SET_IDEAL_TIME: 'SET_IDEAL_TIME',
  GET_PROGRAMS_START: 'GET_PROGRAMS_START',
  GET_PROGRAMS_SUCCESS: 'GET_PROGRAMS_SUCCESS',
  GET_PROGRAMS_FAILURE: 'GET_PROGRAMS_FAILURE',
  SET_PROGRAM_TYPE: 'SET_PROGRAM_TYPE',
  SET_SESSIONS_DETAILS: 'SET_SESSIONS_DETAILS',
  SET_SESSION_DURATION: 'SET_SESSION_DURATION',
  SET_IDEAL_SUMMARY_TIME: 'SET_IDEAL_SUMMARY_TIME',
  SET_PREFERRED_TIME: 'SET_PREFERRED_TIME',
  SET_TIME_VALUES: 'SET_TIME_VALUES',
  GET_PROGRAM_TYPE_START: 'GET_PROGRAM_TYPE_START',
  GET_PROGRAM_TYPE_SUCCESS: 'GET_PROGRAM_TYPE_SUCCESS',
  GET_PROGRAM_TYPE_FAILURE: 'GET_PROGRAM_TYPE_FAILURE',
  SET_SESSION_PRICE: 'SET_SESSION_PRICE',
  SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
  APPLY_DISCOUNT_COUPON_START: 'APPLY_DISCOUNT_COUPON_START',
  APPLY_DISCOUNT_COUPON_SUCCESS: 'APPLY_DISCOUNT_COUPON_SUCCESS',
  APPLY_DISCOUNT_COUPON_FAILURE: 'APPLY_DISCOUNT_COUPON_FAILURE',
  FORGOT_PASSWORD_START: 'FORGOT_PASSWORD_START',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',
  CHANGE_PASSWORD_START: 'CHANGE_PASSWORD_START',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',
  SET_IS_SIGNING_UP: 'SET_IS_SIGNING_UP',
  INITIALIZE_SIGNUP_STATE: 'INITIALIZE_SIGNUP_STATE',
  VERIFY_EMAIL_START: 'VERIFY_EMAIL_START',
  VERIFY_EMAIL_SUCCESS: 'VERIFY_EMAIL_SUCCESS',
  VERIFY_EMAIL_FAILURE: 'VERIFY_EMAIL_FAILURE',
  VERIFY_REFERRAL_CODE_START: 'VERIFY_REFERRAL_START',
  VERIFY_REFERRAL_CODE_SUCCESS: 'VERIFY_REFERRAL_CODE_SUCCESS',
  VERIFY_REFERRAL_CODE_FAILURE: 'VERIFY_REFERRAL_CODE_FAILURE',
};

export default SignupActionTypes;
