import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import TopBar from '../components/TopBar';
import Header from '../components/Header';

import CONSTANTS from '../utils/constants/CONSTANTS';
import pathsAllowed from './pathsAllowed';

const PermissionRoute = ({ component: Component, ...otherProps }) => {
  const userType = useSelector((state) => state.user.userType);

  const componentToRender = () => {
    if (userType) {
      if (pathsAllowed[userType].includes(otherProps.path)) {
        return <Component />;
      }
      if (userType === CONSTANTS.CLIENT) return <Redirect to="/user/dashboard" />;
      return <Redirect to="/dashboard" />;
    }
    return <Redirect to="/" />;
  };
  return (
    <>
      <Header />
      <TopBar />
      <Route {...otherProps} component={componentToRender} />
    </>
  );
};
export default PermissionRoute;
