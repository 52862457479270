import CreateExerciseActionTypes from './ExerciseActionTypes';
import * as API from '../api';

import URLS from '../../utils/constants/URLS';
import CONSTANTS from '../../utils/constants/CONSTANTS';

import { setCurrentExerciseDetailsState } from '../workoutBuilder/WorkoutBuilderAction';

export const setCurrentExercise = (exercise) => ({
  type: CreateExerciseActionTypes.SET_CURRENT_EXERCISE,
  payload: exercise,
});

export const fetchExerciseStart = () => ({
  type: CreateExerciseActionTypes.FETCH_ALL_EXERCISE_START,
});

export const fetchExerciseSuccess = (exercises) => ({
  type: CreateExerciseActionTypes.FETCH_ALL_EXERCISE_SUCCESS,
  payload: exercises,
});

export const fetchExerciseFailure = (errorMessage) => ({
  type: CreateExerciseActionTypes.FETCH_ALL_EXERCISE_FAILURE,
  payload: errorMessage,
});
export const deleteCurrentExerciseStart = () => ({
  type: CreateExerciseActionTypes.DELETE_CURRENT_EXERCISE_START,
});
export const deleteCurrentExerciseSuccess = (newList) => ({
  type: CreateExerciseActionTypes.DELETE_CURRENT_EXERCISE_SUCCESS,
  payload: newList,
});
export const deleteCurrentExerciseFailure = (errorMessage) => ({
  type: CreateExerciseActionTypes.DELETE_CURRENT_EXERCISE_FAILURE,
  payload: errorMessage,
});

export const setSaveExercise = (state) => ({
  type: CreateExerciseActionTypes.SET_SAVE_EXERCISE,
  payload: state,
});

export const updateOrAddNewExercises = (exercise) => ({
  type: CreateExerciseActionTypes.UPDATE_OR_ADD_NEW_EXERCISE,
  payload: exercise,
});

export const setShowPopup = (state) => ({
  type: CreateExerciseActionTypes.SET_SHOW_POPUP,
  payload: state,
});
export const setPopupData = (state) => ({
  type: CreateExerciseActionTypes.SET_POPUP_DATA,
  payload: state,
});
export const updateExerciseFailure = (error) => ({
  type: CreateExerciseActionTypes.UPDATE_EXERCISE_FAILURE,
  payload: error,
});

export const initializeExerciseState = () => ({ type: CreateExerciseActionTypes.INITIALIZE_EXERCISE_STATE });

export const editCurrentExercise = (updatedExercise) => {
  return (dispatch, getState) => {
    const { allExercise } = getState().exercise;
    const uuid = updatedExercise.get('uuid');

    API.patch(`${URLS.exercise}${uuid}/`, updatedExercise)
      .then((response) => {
        dispatch(setCurrentExercise(response.data));
        allExercise[uuid] = response.data;
        dispatch(updateOrAddNewExercises({ ...allExercise }));
        dispatch(setSaveExercise(false));
        dispatch(setCurrentExerciseDetailsState(CONSTANTS.VIEW_STATE));
        dispatch(
          setPopupData({
            heading: 'Edit Exercise',
            message: 'Exercise updated successfully',
            color: CONSTANTS.POPUP_COLORS.GREEN,
          })
        );
        dispatch(setShowPopup(true));
      })
      .catch((error) => {
        const errorMsg = error.response.data;
        dispatch(updateExerciseFailure(errorMsg ? Object.values(errorMsg) : 'Exercise has not been edited'));
        dispatch(setSaveExercise(false));
        dispatch(setCurrentExerciseDetailsState(CONSTANTS.VIEW_STATE));
        dispatch(
          setPopupData({
            heading: 'Edit Exercise',
            message: 'Exercise could not be edited',
            color: CONSTANTS.POPUP_COLORS.RED,
          })
        );
        dispatch(setShowPopup(true));
      });
  };
};

export const addNewExerciseAsync = (newExercise) => {
  return (dispatch, getState) => {
    const { allExercise } = getState().exercise;

    API.post(URLS.exercise, newExercise)
      .then((response) => {
        dispatch(setCurrentExercise(response.data));
        allExercise[response.data.uuid] = response.data;
        dispatch(updateOrAddNewExercises({ ...allExercise }));
        dispatch(setSaveExercise(false));
        dispatch(
          setPopupData({
            heading: 'Add Exercise',
            message: 'Exercise was added successfully',
            color: CONSTANTS.POPUP_COLORS.GREEN,
          })
        );
        dispatch(setShowPopup(true));
        dispatch(setCurrentExerciseDetailsState(CONSTANTS.VIEW_STATE));
      })
      .catch((error) => {
        const errorMsg = error.response.data;
        dispatch(updateExerciseFailure(errorMsg ? Object.values(errorMsg) : 'Exercise has not been added'));
        dispatch(setSaveExercise(false));
        dispatch(
          setPopupData({
            heading: 'Add Exercise',
            message: 'Exercise could not be added',
            color: CONSTANTS.POPUP_COLORS.RED,
          })
        );
        dispatch(setShowPopup(true));
        dispatch(setCurrentExerciseDetailsState(CONSTANTS.VIEW_STATE));
      });
  };
};

export const deleteExerciseAsync = () => {
  return (dispatch, getState) => {
    const { allExercise } = getState().exercise;
    const id = getState().exercise.currentExercise?.uuid;
    dispatch(deleteCurrentExerciseStart());
    API.remove(`${URLS.exercise}${id}/`)
      .then((response) => {
        if (response.status === 204) {
          dispatch(setCurrentExerciseDetailsState(CONSTANTS.NO_STATE));
          delete allExercise[id];
          dispatch(deleteCurrentExerciseSuccess({ ...allExercise }));
          dispatch(
            setPopupData({
              heading: 'Delete Exercise',
              message: 'Exercise was deleted successfully',
              color: CONSTANTS.POPUP_COLORS.GREEN,
            })
          );
          dispatch(setShowPopup(true));
        }
      })
      .catch((error) => {
        dispatch(deleteCurrentExerciseFailure(error));
        dispatch(
          setPopupData({
            heading: 'Delete Exercise',
            message: 'Exercise could not be deleted',
            color: CONSTANTS.POPUP_COLORS.RED,
          })
        );
        dispatch(setShowPopup(true));
      });
  };
};

export const fetchAllExerciseStartAsync = () => {
  return (dispatch) => {
    dispatch(fetchExerciseStart());
    API.get(URLS.exercise)
      .then((response) => {
        dispatch(fetchExerciseSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchExerciseFailure(error));
      });
  };
};
