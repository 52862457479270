import CreatePlanActionTypes from './PlanActionTypes';

import * as API from '../api';

import URLS from '../../utils/constants/URLS';
import CONSTANTS from '../../utils/constants/CONSTANTS';

import { setCurrentPlanDetailsState } from '../workoutBuilder/WorkoutBuilderAction';

export const setCurrentPlan = (plan) => ({
  type: CreatePlanActionTypes.SET_CURRENT_PLAN,
  payload: plan,
});

export const fetchPlanStart = () => ({
  type: CreatePlanActionTypes.FETCH_ALL_PLAN_START,
});

export const fetchPlanSuccess = (plan) => ({
  type: CreatePlanActionTypes.FETCH_ALL_PLAN_SUCCESS,
  payload: plan,
});

export const fetchPlanFailure = (errorMessage) => ({
  type: CreatePlanActionTypes.FETCH_ALL_PLAN_FAILURE,
  payload: errorMessage,
});

export const deleteCurrentPlanStart = () => ({
  type: CreatePlanActionTypes.DELETE_CURRENT_PLAN_START,
});

export const deleteCurrentPlanSuccess = (newList) => ({
  type: CreatePlanActionTypes.DELETE_CURRENT_PLAN_SUCCESS,
  payload: newList,
});

export const deleteCurrentPlanFailure = (errorMessage) => ({
  type: CreatePlanActionTypes.DELETE_CURRENT_PLAN_FAILURE,
  payload: errorMessage,
});

export const setSavePlan = (state) => ({
  type: CreatePlanActionTypes.SET_SAVE_PLAN,
  payload: state,
});

export const updateOrAddNewPlan = (plan) => ({
  type: CreatePlanActionTypes.UPDATE_OR_ADD_NEW_PLAN,
  payload: plan,
});

export const setSaveWorkoutInPlan = (state) => ({
  type: CreatePlanActionTypes.SET_SAVE_WORKOUT_IN_PLAN,
  payload: state,
});

export const setShowPopup = (state) => ({
  type: CreatePlanActionTypes.SET_SHOW_POPUP,
  payload: state,
});

export const setPopupData = (state) => ({
  type: CreatePlanActionTypes.SET_POPUP_DATA,
  payload: state,
});

export const updatePlanFailure = (state) => ({
  type: CreatePlanActionTypes.UPDATE_PLAN_FAILURE,
  payload: state,
});

export const initializePlanState = () => ({ type: CreatePlanActionTypes.INITIALIZE_PLAN_STATE });

export const editCurrentPlan = (updatedPlan) => {
  return (dispatch, getState) => {
    const { allPlan } = getState().plan;

    API.patch(`${URLS.plan}${updatedPlan.uuid}/`, updatedPlan)
      .then((response) => {
        dispatch(setCurrentPlan(response.data));
        allPlan[updatedPlan.uuid] = response.data;
        dispatch(updateOrAddNewPlan({ ...allPlan }));
        dispatch(setSavePlan(false));
        dispatch(
          setPopupData({
            heading: 'Edit Plan',
            message: 'Plan updated successfully',
            color: CONSTANTS.POPUP_COLORS.GREEN,
          })
        );
        dispatch(setShowPopup(true));
        dispatch(setCurrentPlanDetailsState(CONSTANTS.VIEW_STATE));
      })
      .catch((error) => {
        const errorMsg = error.response.data?.detail;
        dispatch(updatePlanFailure(errorMsg ? Object.values(errorMsg)[0] : 'Plan has not been edited'));
        dispatch(setSavePlan(false));
        dispatch(
          setPopupData({
            heading: 'Edit Plan',
            message: 'Plan could not be edited',
            color: CONSTANTS.POPUP_COLORS.RED,
          })
        );
        dispatch(setShowPopup(true));
        dispatch(setCurrentPlanDetailsState(CONSTANTS.NO_STATE));
      });
  };
};

export const addNewPlanAsync = (newPlan) => {
  return (dispatch, getState) => {
    const { allPlan } = getState().plan;

    API.post(URLS.plan, newPlan)
      .then((response) => {
        dispatch(setCurrentPlan(response.data));
        allPlan[response.data.uuid] = response.data;
        dispatch(updateOrAddNewPlan({ ...allPlan }));
        dispatch(setSavePlan(false));
        dispatch(
          setPopupData({
            heading: 'Add Plan',
            message: 'Plan was added successfully',
            color: CONSTANTS.POPUP_COLORS.GREEN,
          })
        );
        dispatch(setShowPopup(true));
        dispatch(setCurrentPlanDetailsState(CONSTANTS.VIEW_STATE));
      })
      .catch((error) => {
        const errorMsg = error.response.data?.detail;
        dispatch(updatePlanFailure(errorMsg ? Object.values(errorMsg)[0] : 'Plan has not been created'));
        dispatch(setSavePlan(false));
        dispatch(
          setPopupData({
            heading: 'Add Plan',
            message: 'Plan could not be added',
            color: CONSTANTS.POPUP_COLORS.RED,
          })
        );
        dispatch(setShowPopup(true));
        dispatch(setCurrentPlanDetailsState(CONSTANTS.NO_STATE));
      });
  };
};

export const deletePlanAsync = () => {
  return (dispatch, getState) => {
    const { allPlan } = getState().plan;
    const id = getState().plan.currentPlan?.uuid;
    dispatch(deleteCurrentPlanStart());
    API.remove(`${URLS.plan}${id}/`)
      .then((response) => {
        if (response.status === 204) {
          dispatch(setCurrentPlanDetailsState(CONSTANTS.NO_STATE));
          delete allPlan[id];
          dispatch(deleteCurrentPlanSuccess({ ...allPlan }));
          dispatch(
            setPopupData({
              heading: 'Delete Plan',
              message: 'Plan was deleted successfully',
              color: CONSTANTS.POPUP_COLORS.GREEN,
            })
          );
          dispatch(setShowPopup(true));
        }
      })
      .catch((error) => {
        dispatch(deleteCurrentPlanFailure(error));
        dispatch(
          setPopupData({
            heading: 'Delete Plan',
            message: 'Deleting current plan was  unsuccessful',
            color: CONSTANTS.POPUP_COLORS.RED,
          })
        );
        dispatch(setShowPopup(true));
      });
  };
};

export const fetchAllPlanStartAsync = () => {
  return (dispatch) => {
    dispatch(fetchPlanStart());

    API.get(URLS.plan)
      .then((response) => {
        dispatch(fetchPlanSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchPlanFailure(error));
      });
  };
};
