import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import SideNav from '../SideNav';
import ProfileIcon from './ProfileIcon';
import Notification from './Notification';
import AdminHeaderLinks from './adminHeaderLinks';
import ClientHeaderLinks from './clientHeaderLinks';
import TrainerHeaderLinks from './trainerHeaderLinks';

import OmniLogo from '../../assets/images/png/OmniLogoNew.png';

import useGetUnreadChatCount from '../../customHooks/useGetUnreadChatCount';

import CONSTANTS from '../../utils/constants/CONSTANTS';

import './index.scss';

const Header = () => {
  const userType = useSelector((state) => state.user.userType);

  const unreadChatCount = useGetUnreadChatCount();

  return (
    <div className="header">
      {userType === CONSTANTS.CLIENT ? <SideNav /> : null}
      <Link to={`${CONSTANTS.URL_LIST.DASHBOARD}`}>
        <img src={OmniLogo} className="logo-container" alt="website logo" />
      </Link>

      <div className="options">
        {userType === CONSTANTS.CLIENT && <ClientHeaderLinks />}
        {userType === CONSTANTS.ADMIN && <AdminHeaderLinks unreadChatCount={unreadChatCount} />}
        {userType === CONSTANTS.TRAINER && <TrainerHeaderLinks unreadChatCount={unreadChatCount} />}
      </div>

      <div className="login-status">
        <ProfileIcon />
        <Notification />
      </div>
    </div>
  );
};

export default Header;
