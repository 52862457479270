import CONSTANTS from '../../utils/constants/CONSTANTS';
import PaymentActionTypes from './PaymentActionTypes';

const INITIAL_STATE = {
  paymentPlan: {},
  paymentOptions: [],
  sendingStripeData: false,
  usersCard: {},
  updatingCardDetails: false,
  subscription: {},
  subscriptionActive: false,
  buySubscriptionState: CONSTANTS.VIEW_AVAILABLE_PLANS,
  errorMessage: null,
  isPlanFetching: false,
  subscribePlan: false,
  subscriptionError: null,
  cancellingSubscription: false,
};

const paymentReducer = (state = INITIAL_STATE, action = { type: '' }) => {
  switch (action.type) {
    case PaymentActionTypes.GET_CLIENT_SECRET_SUCCESS:
      return {
        ...state,
        clientSecret: action.payload,
      };
    case PaymentActionTypes.SET_PAYMENT_PLAN:
      return {
        ...state,
        paymentPlan: action.payload,
      };
    case PaymentActionTypes.SET_PAYMENT_OPTIONS:
      return {
        ...state,
        paymentOptions: action.payload,
      };
    case PaymentActionTypes.SET_STRIPE_DATA_FLAG:
      return {
        ...state,
        sendingStripeData: action.payload,
      };
    case PaymentActionTypes.SENDING_STRIPE_DATA_START:
      return {
        ...state,
        sendingStripeData: true,
      };
    case PaymentActionTypes.SENDING_STRIPE_DATA_SUCCESS:
      return {
        ...state,
        sendingStripeData: false,
        errorMessage: null,
      };
    case PaymentActionTypes.SENDING_STRIPE_DATA_FAILURE:
      return {
        ...state,
        sendingStripeData: false,
        errorMessage: action.payload,
      };
    case PaymentActionTypes.PLAN_FETCHING_START:
      return {
        ...state,
        isPlanFetching: true,
      };
    case PaymentActionTypes.PLAN_FETCHING_SUCCESS:
      return {
        ...state,
        isPlanFetching: false,
        errorMessage: null,
      };
    case PaymentActionTypes.PLAN_FETCHING_FAILURE:
      return {
        ...state,
        isPlanFetching: false,
        errorMessage: action.payload,
      };
    case PaymentActionTypes.SUBSCRIBE_NEW_PLAN_START:
      return {
        ...state,
        subscribePlan: true,
      };
    case PaymentActionTypes.SUBSCRIBE_NEW_PLAN_SUCCESS:
      return {
        ...state,
        subscribePlan: false,
        subscriptionError: null,
      };
    case PaymentActionTypes.SUBSCRIBE_NEW_PLAN_FAILURE:
      return {
        ...state,
        subscribePlan: false,
        subscriptionError: action.payload,
      };
    case PaymentActionTypes.SET_USERS_CARD:
      return {
        ...state,
        usersCard: action.payload,
      };
    case PaymentActionTypes.SET_USERS_SUBSCRIPTION:
      return {
        ...state,
        subscriptionActive: action.payload.subscription_active,
        subscription: action.payload.subscription,
      };
    case PaymentActionTypes.SET_BUY_SUBSCRIPTION_STATE:
      return {
        ...state,
        buySubscriptionState: action.payload,
      };
    case PaymentActionTypes.UPDATE_CARD_DETAILS_START:
      return {
        ...state,
        updatingCardDetails: true,
        errorMessage: null,
      };
    case PaymentActionTypes.UPDATE_CARD_DETAILS_SUCCESS:
      return {
        ...state,
        updatingCardDetails: false,
      };
    case PaymentActionTypes.UPDATE_CARD_DETAILS_FAILURE:
      return {
        ...state,
        updatingCardDetails: false,
        errorMessage: action.payload,
      };
    case PaymentActionTypes.CANCEL_SUBSCRIPTION_START:
      return {
        ...state,
        cancellingSubscription: true,
      };
    case PaymentActionTypes.CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        cancellingSubscription: false,
      };
    case PaymentActionTypes.CANCEL_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        cancellingSubscription: false,
        errorMessage: action.payload,
      };

    case PaymentActionTypes.INITIALIZE_PAYMENT_REDUCER:
      return {
        paymentPlan: {},
        paymentOptions: [],
        sendingStripeData: false,
        usersCard: {},
        updatingCardDetails: false,
        subscription: {},
        subscriptionActive: false,
        buySubscriptionState: CONSTANTS.VIEW_AVAILABLE_PLANS,
        errorMessage: null,
        isPlanFetching: false,
        subscribePlan: false,
        subscriptionError: null,
        cancellingSubscription: false,
      };

    case PaymentActionTypes.CARD_RETRIEVE_FAIL:
      return {
        ...state,
        isCardRetrieveSuccessful: false,
      };

    case PaymentActionTypes.CARD_RETRIEVE_SUCCESSFUL:
      return {
        ...state,
        isCardRetrieveSuccessful: true,
      };

    default:
      return state;
  }
};

export default paymentReducer;
