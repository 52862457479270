const ReportingActionTypes = {
  GET_TRAINER_REPORT_SUMMARY_START: 'GET_TRAINER_REPORT_SUMMARY_START',
  GET_TRAINER_REPORT_SUMMARY_SUCCESS: 'GET_TRAINER_REPORT_SUMMARY_SUCCESS',
  GET_TRAINER_REPORT_SUMMARY_FAILURE: 'GET_TRAINER_REPORT_SUMMARY_FAILURE',
  GET_TRAINER_DETAILED_REPORT_START: 'GET_TRAINER_DETAILED_REPORT_START',
  GET_TRAINER_DETAILED_REPORT_SUCCESS: 'GET_TRAINER_DETAILED_REPORT_SUCCESS',
  GET_TRAINER_DETAILED_REPORT_FAILURE: 'GET_TRAINER_DETAILED_REPORT_FAILURE',
  INITIALIZE_REPORTING_STATE: 'INITIALIZE_REPORTING_STATE',
  GET_SALES_REPORT_SUMMARY_START: 'GET_SALES_REPORT_SUMMARY_START',
  GET_SALES_REPORT_SUMMARY_SUCCESS: 'GET_SALES_REPORT_SUMMARY_SUCCESS',
  GET_SALES_REPORT_SUMMARY_FAILURE: 'GET_SALES_REPORT_SUMMARY_FAILURE',
  GET_SALES_GRAPH_START: 'GET_SALES_GRAPH_START',
  GET_SALES_GRAPH_SUCCESS: 'GET_SALES_GRAPH_SUCCESS',
  GET_SALES_GRAPH_FAILURE: 'GET_SALES_GRAPH_FAILURE',
  GET_SALES_DETAILED_REPORT_START: 'GET_SALES_DETAILED_REPORT_START',
  GET_SALES_DETAILED_REPORT_SUCCESS: 'GET_SALES_DETAILED_REPORT_SUCCESS',
  GET_SALES_DETAILED_REPORT_FAILURE: 'GET_SALES_DETAILED_REPORT_FAILURE',
  GET_CLIENT_REPORT_SUMMARY_START: 'GET_CLIENT_REPORT_SUMMARY_START',
  GET_CLIENT_REPORT_SUMMARY_SUCCESS: 'GET_CLIENT_REPORT_SUMMARY_SUCCESS',
  GET_CLIENT_REPORT_SUMMARY_FAILURE: 'GET_CLIENT_REPORT_SUMMARY_FAILURE',
  GET_CLIENT_DETAILED_REPORT_START: 'GET_CLIENT_DETAILED_REPORT_START',
  GET_CLIENT_DETAILED_REPORT_SUCCESS: 'GET_CLIENT_DETAILED_REPORT_SUCCESS',
  GET_CLIENT_DETAILED_REPORT_FAILURE: 'GET_CLIENT_DETAILED_REPORT_FAILURE',
};

export default ReportingActionTypes;
