/* eslint-disable import/no-cycle */
import EmployerCodeActionTypes from './EmployerCodeActionTypes';

export const setEmployerCode = (code) => ({
  type: EmployerCodeActionTypes.SET_EMPLOYER_CODE,
  payload: code,
});

export const initializeEmployerCodeState = () => ({
    type: EmployerCodeActionTypes.INITIALIZE_EMPLOYERCODE_STATE,
});
