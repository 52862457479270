import InboxActionTypes from './InboxActionTypes';

const INITIAL_STATE = {
  allChats: [],
  allGroupChats: [],
  groupChatMembers: [],
  isFetching: false,
  isFetchingGroupChats: false,
  isFetchingGroupMembers: false,
  errorMessage: null,
  currentChat: null,
  currentGroupChat: null,
  showChatState: false,
  showGroupChatState: false,
  isFetchingSingle: false,
  isFetchingSingleGroup: false,
  errorMessageSingle: null,
  errorMessageSingleGroup: null,
  timeout: null,
  clientId: null,
  newChatExists: null,
};

const inboxReducer = (state = INITIAL_STATE, action = { type: '' }) => {
  switch (action.type) {
    case InboxActionTypes.FETCH_ALL_CHATS_START:
      return {
        ...state,
        isFetching: true,
        errorMessage: null,
      };
    case InboxActionTypes.FETCH_ALL_CHATS_SUCCESS:
      return {
        ...state,
        allChats: action.payload,
        isFetching: false,
        errorMessage: null,
      };
    case InboxActionTypes.FETCH_ALL_CHATS_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        isFetching: false,
      };

    case InboxActionTypes.FETCH_GROUP_CHATS_START:
      return {
        ...state,
        isFetchingGroupChats: true,
        errorMessage: null,
      };
    case InboxActionTypes.FETCH_GROUP_CHATS_SUCCESS:
      return {
        ...state,
        allGroupChats: action.payload,
        isFetchingGroupChats: false,
        errorMessage: null,
      };
    case InboxActionTypes.FETCH_GROUP_CHATS_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        isFetchingGroupChats: false,
      };

    case InboxActionTypes.FETCH_GROUP_CHAT_MEMBERS_START:
      return {
        ...state,
        isFetchingGroupMembers: true,
        errorMessage: null,
      };
    case InboxActionTypes.FETCH_GROUP_CHAT_MEMBERS_SUCCESS:
      return {
        ...state,
        groupChatMembers: action.payload,
        isFetchingGroupMembers: false,
        errorMessage: null,
      };
    case InboxActionTypes.FETCH_GROUP_CHAT_MEMBERS_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        isFetchingGroupMembers: false,
      };

    case InboxActionTypes.FETCH_SINGLE_CHAT_START:
      return {
        ...state,
        isFetchingSingle: true,
        errorMessageSingle: null,
      };
    case InboxActionTypes.FETCH_SINGLE_CHAT_SUCCESS:
      return {
        ...state,
        currentChat: { ...state.currentChat, messages: action.payload },
        isFetchingSingle: false,
        errorMessageSingle: null,
      };
    case InboxActionTypes.FETCH_SINGLE_CHAT_FAILURE:
      return {
        ...state,
        errorMessageSingle: action.payload,
        isFetchingSingle: false,
        currentChat: null,
      };

    case InboxActionTypes.FETCH_SINGLE_GROUP_CHAT_START:
      return {
        ...state,
        isFetchingSingleGroup: true,
        errorMessageSingleGroup: null,
      };
    case InboxActionTypes.FETCH_SINGLE_GROUP_CHAT_SUCCESS:
      return {
        ...state,
        currentGroupChat: { ...state.currentGroupChat, messages: action.payload },
        isFetchingSingleGroup: false,
        errorMessageSingleGroup: null,
      };
    case InboxActionTypes.FETCH_SINGLE_GROUP_CHAT_FAILURE:
      return {
        ...state,
        errorMessageSingleGroup: action.payload,
        isFetchingSingleGroup: false,
        currentGroupChat: null,
      };

    case InboxActionTypes.SET_CURRENT_CHAT:
      return {
        ...state,
        currentChat: action.payload,
      };

    case InboxActionTypes.SET_NEW_CLIENT_ID:
      return {
        ...state,
        clientId: action.payload,
      };

    case InboxActionTypes.SET_NEW_CHAT_FLAG:
      return {
        ...state,
        newChatExists: action.payload,
      };

    case InboxActionTypes.SET_CURRENT_GROUP_CHAT:
      return {
        ...state,
        currentGroupChat: action.payload,
      };
    case InboxActionTypes.SET_SHOW_CHAT_STATE:
      return {
        ...state,
        showChatState: action.payload,
      };
    case InboxActionTypes.SET_SHOW_GROUP_CHAT_STATE:
      return {
        ...state,
        showGroupChatState: action.payload,
      };
    case InboxActionTypes.SET_ALL_CHATS:
      return {
        ...state,
        allChats: action.payload,
      };
    case InboxActionTypes.SET_ALL_GROUP_CHATS:
      return {
        ...state,
        allGroupChats: action.payload,
      };
    case InboxActionTypes.SET_TIMEOUT_VALUE:
      return {
        ...state,
        timeout: action.payload,
      };
    case InboxActionTypes.INITIALIZE_INBOX_STATE:
      return {
        allChats: [],
        allGroupChats: [],
        groupChatMembers: [],
        isFetching: false,
        isFetchingGroupChats: false,
        isFetchingGroupMembers: false,
        errorMessage: null,
        currentChat: null,
        currentGroupChat: null,
        showChatState: false,
        showGroupChatState: false,
        isFetchingSingle: false,
        isFetchingSingleGroup: false,
        errorMessageSingle: null,
        errorMessageSingleGroup: null,
        timeout: null,
      };
    default:
      return state;
  }
};

export default inboxReducer;
