import axios from 'axios';
import { store } from '../store';
import CustomerAgreementType from './CustomerAgreementTypes';

import URLS from '../../utils/constants/URLS';

export const setCustomerAgreementDataSuccess = (data) => ({
  type: CustomerAgreementType.SET_CUSTOMER_AGREEMENT_DATA,
  payload: data,
});

export const fetchCustomerAgreementData = () => {
  const accessToken = store.getState().user?.currentUser?.access_token;
  return (dispatch) => {
    axios
      .get(URLS.customerAgreementData, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const { data } = response;
        dispatch(setCustomerAgreementDataSuccess(data[0]));
      });
  };
};

export const updateCustomerAgreementData = (data) => {
  return (dispatch) => {
    dispatch(setCustomerAgreementDataSuccess(data));
  };
};

export const setCustomerAgreementData = (data) => {
  return (dispatch) => {
    dispatch(setCustomerAgreementDataSuccess(data));
  };
};

export const initializeCustomerAgreementState = () => ({
  type: CustomerAgreementType.INITIALIZE_CUSTOMER_AGREEMENT_STATE,
});
