import CreateExerciseActionTypes from './ExerciseActionTypes';

const INITIAL_STATE = {
  currentExercise: null,
  allExercise: {},
  isFetching: false,
  isDeleting: false,
  errorMessage: null,
  saveExercise: false,
  showPopup: false,
  popupData: {},
};

const ExerciseReducer = (state = INITIAL_STATE, action = { type: '' }) => {
  switch (action.type) {
    case CreateExerciseActionTypes.SET_CURRENT_EXERCISE:
      return {
        ...state,
        currentExercise: action.payload,
      };
    case CreateExerciseActionTypes.FETCH_ALL_EXERCISE_START:
      return {
        ...state,
        isFetching: true,
      };
    case CreateExerciseActionTypes.FETCH_ALL_EXERCISE_SUCCESS:
      return {
        ...state,
        allExercise: action.payload.reduce((object, exercise) => {
          return { ...object, [exercise.uuid]: exercise };
        }, {}),
        isFetching: false,
        errorMessage: null,
      };
    case CreateExerciseActionTypes.FETCH_ALL_EXERCISE_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case CreateExerciseActionTypes.DELETE_CURRENT_EXERCISE_START:
      return {
        ...state,
        isDeleting: true,
      };
    case CreateExerciseActionTypes.DELETE_CURRENT_EXERCISE_SUCCESS:
      return {
        ...state,
        currentExercise: null,
        allExercise: action.payload,
        isDeleting: false,
      };
    case CreateExerciseActionTypes.DELETE_CURRENT_EXERCISE_FAILURE:
      return {
        ...state,
        isDeleting: false,
        errorMessage: action.payload,
      };
    case CreateExerciseActionTypes.SET_SAVE_EXERCISE:
      return {
        ...state,
        saveExercise: action.payload,
      };
    case CreateExerciseActionTypes.UPDATE_OR_ADD_NEW_EXERCISE:
      return {
        ...state,
        allExercise: action.payload,
      };
    case CreateExerciseActionTypes.SET_SHOW_POPUP:
      return {
        ...state,
        showPopup: action.payload,
      };
    case CreateExerciseActionTypes.SET_POPUP_DATA:
      return {
        ...state,
        popupData: action.payload,
      };
    case CreateExerciseActionTypes.UPDATE_EXERCISE_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case CreateExerciseActionTypes.INITIALIZE_EXERCISE_STATE:
      return {
        currentExercise: null,
        allExercise: {},
        isFetching: false,
        errorMessage: null,
        saveExercise: false,
        showPopup: false,
      };
    default:
      return state;
  }
};

export default ExerciseReducer;
