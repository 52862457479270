import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import WorkoutBuilderReducer from './workoutBuilder/WorkoutBuilderReducer';
import DashboardReducer from './dashboard/DashboardReducer';
import ExerciseReducer from './exercise/ExerciseReducer';
import SendPlanReducer from './sendPlan/SendPlanReducer';
import PaymentReducer from './payments/PaymentReducer';
import ReportingReducer from './reporting/ReportingReducer';
import WorkoutReducer from './workout/WorkoutReducer';
import CalendarReducer from './calendar/CalendarReducer';
import trainerReducer from './trainer/TrainerReducer';
import clientReducer from './clients/ClientReducer';
import signupReducer from './signup/SignupReducer';
import inboxReducer from './inbox/InboxReducer';
import userReducer from './user/UserReducer';
import customerAgreementReducer from './customerAgreement/CustomerAgreementReducer';
import PlanReducer from './plan/PlanReducer';
import employerCodeReducer from './employerCode/EmployerCodeReducer';
import fundraiserReducer from './fundraiser/FundraiserReducer';

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  workoutBuilder: WorkoutBuilderReducer,
  dashboard: DashboardReducer,
  exercise: ExerciseReducer,
  calendar: CalendarReducer,
  sendPlan: SendPlanReducer,
  payments: PaymentReducer,
  trainers: trainerReducer,
  workout: WorkoutReducer,
  clients: clientReducer,
  signup: signupReducer,
  employerCode: employerCodeReducer,
  fundraiser: fundraiserReducer,
  inbox: inboxReducer,
  plan: PlanReducer,
  user: userReducer,
  customerAgreement: customerAgreementReducer,
  reporting: ReportingReducer,
});

export default persistReducer(persistConfig, rootReducer);
