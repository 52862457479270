const InboxActionTypes = {
  FETCH_ALL_CHATS_START: 'FETCH_ALL_CHATS_START',
  FETCH_ALL_CHATS_SUCCESS: 'FETCH_ALL_CHATS_SUCCESS',
  FETCH_ALL_CHATS_FAILURE: 'FETCH_ALL_CHATS_FAILURE',
  FETCH_SINGLE_CHAT_START: 'FETCH_SINGLE_CHAT_START',
  FETCH_SINGLE_CHAT_SUCCESS: 'FETCH_SINGLE_CHAT_SUCCESS',
  FETCH_SINGLE_CHAT_FAILURE: 'FETCH_SINGLE_CHAT_FAILURE',
  SET_CURRENT_CHAT: 'SET_CURRENT_CHAT',
  SET_SHOW_CHAT_STATE: 'SET_SHOW_CHAT_STATE',
  SET_ALL_CHATS: 'SET_ALL_CHATS',
  SET_ALL_GROUP_CHATS: 'SET_ALL_GROUP_CHATS',
  SET_TIMEOUT_VALUE: 'SET_TIMEOUT_VALUE',
  INITIALIZE_INBOX_STATE: 'INITIALIZE_INBOX_STATE',
  FETCH_GROUP_CHATS_START: 'FETCH_GROUP_CHATS_START',
  FETCH_GROUP_CHATS_SUCCESS: 'FETCH_GROUP_CHATS_SUCCESS',
  FETCH_GROUP_CHATS_FAILURE: 'FETCH_GROUP_CHATS_FAILURE',
  FETCH_SINGLE_GROUP_CHAT_START: 'FETCH_SINGLE_GROUP_CHAT_START',
  FETCH_SINGLE_GROUP_CHAT_SUCCESS: 'FETCH_SINGLE_GROUP_CHAT_SUCCESS',
  FETCH_SINGLE_GROUP_CHAT_FAILURE: 'FETCH_SINGLE_GROUP_CHAT_FAILURE',
  FETCH_GROUP_CHAT_MEMBERS_START: 'FETCH_GROUP_CHAT_MEMBERS_START',
  FETCH_GROUP_CHAT_MEMBERS_SUCCESS: 'FETCH_GROUP_CHAT_MEMBERS_SUCCESS',
  FETCH_GROUP_CHAT_MEMBERS_FAILURE: 'FETCH_GROUP_CHAT_MEMBERS_FAILURE',
  SET_CURRENT_GROUP_CHAT: 'SET_CURRENT_GROUP_CHAT',
  SET_SHOW_GROUP_CHAT_STATE: 'SET_SHOW_GROUP_CHAT_STATE',
  SET_NEW_CLIENT_ID: 'SET_NEW_CLIENT_ID',
  SET_NEW_CHAT_FLAG: 'SET_NEW_CHAT_FLAG',
};

export default InboxActionTypes;
