import URLS from '../../utils/constants/URLS';
import * as API from '../api';

import ClientActionTypes from './ClientActionTypes';

import { keysToCamel } from '../../utils/helperFunctions';

export const fetchClientsStart = () => ({
  type: ClientActionTypes.FETCH_ALL_CLIENTS_START,
});

export const fetchClientsSuccess = (clients) => ({
  type: ClientActionTypes.FETCH_ALL_CLIENTS_SUCCESS,
  payload: clients,
});

export const fetchClientsFailure = (errorMessage) => ({
  type: ClientActionTypes.FETCH_ALL_CLIENTS_FAILURE,
  payload: errorMessage,
});

export const toggleCouponCodeStart = () => ({
  type: ClientActionTypes.TOGGLE_COUPON_CODE_START,
});

export const toggleCouponCodeSuccess = (coupons) => ({
  type: ClientActionTypes.TOGGLE_COUPON_CODE_SUCCESS,
  payload: coupons,
});

export const toggleCouponCodeFailure = (errorMessage) => ({
  type: ClientActionTypes.TOGGLE_COUPON_CODE_FAILURE,
  payload: errorMessage,
});

export const generateCouponStart = () => ({
  type: ClientActionTypes.GENERATE_COUPON_START,
});

export const generateCouponSuccess = (coupon) => ({
  type: ClientActionTypes.GENERATE_COUPON_SUCCESS,
  payload: coupon,
});

export const generateCouponFailure = (errorMessage) => ({
  type: ClientActionTypes.GENERATE_COUPON_FAILURE,
  payload: errorMessage,
});

export const fetchProgressDataStart = () => ({
  type: ClientActionTypes.FETCH_PROGRESS_DATA_START,
});

export const fetchProgressDataSuccess = (progress) => ({
  type: ClientActionTypes.FETCH_PROGRESS_DATA_SUCCESS,
  payload: progress,
});

export const fetchProgressDataFailure = (errorMessage) => ({
  type: ClientActionTypes.FETCH_PROGRESS_DATA_FAILURE,
  payload: errorMessage,
});

export const fetchGroupsStart = () => ({
  type: ClientActionTypes.FETCH_ALL_GROUPS_START,
});

export const fetchGroupsSuccess = (groups) => ({
  type: ClientActionTypes.FETCH_ALL_GROUPS_SUCCESS,
  payload: groups,
});

export const fetchGroupsFailure = (errorMessage) => ({
  type: ClientActionTypes.FETCH_ALL_GROUPS_FAILURE,
  payload: errorMessage,
});
export const fetchSingleClientStart = (client) => ({
  type: ClientActionTypes.FETCH_SINGLE_CLIENT_START,
  payload: client,
});

export const fetchSingleClientSuccess = (client) => ({
  type: ClientActionTypes.FETCH_SINGLE_CLIENT_SUCCESS,
  payload: client,
});
export const fetchSingleClientFailure = (client) => ({
  type: ClientActionTypes.FETCH_SINGLE_CLIENT_FAILURE,
  payload: client,
});

export const fetchSingleGroupStart = (client) => ({
  type: ClientActionTypes.FETCH_SINGLE_GROUP_START,
  payload: client,
});

export const fetchSingleGroupSuccess = (client) => ({
  type: ClientActionTypes.FETCH_SINGLE_GROUP_SUCCESS,
  payload: client,
});

export const fetchSingleGroupFailure = (client) => ({
  type: ClientActionTypes.FETCH_SINGLE_GROUP_FAILURE,
  payload: client,
});

export const setCurrentClient = (client) => ({
  type: ClientActionTypes.SET_CURRENT_CLIENT,
  payload: client,
});

export const setEditAvailableSessions = (state) => ({
  type: ClientActionTypes.SET_EDIT_AVAILABLE_SESSIONS,
  payload: state,
});

export const updateAvailableSessionStart = (state) => ({
  type: ClientActionTypes.UPDATE_AVAILABLE_SESSIONS_START,
  payload: state,
});

export const updateAvailableSessionSuccess = (state) => ({
  type: ClientActionTypes.UPDATE_AVAILABLE_SESSIONS_SUCCESS,
  payload: state,
});

export const updateAvailableSessionFailure = (state) => ({
  type: ClientActionTypes.UPDATE_AVAILABLE_SESSIONS_FAILURE,
  payload: state,
});

export const setShowPopup = (state) => ({
  type: ClientActionTypes.SET_POPUP,
  payload: state,
});

export const setPopupData = (state) => ({
  type: ClientActionTypes.SET_POPUP_DATA,
  payload: state,
});

export const sortClientData = (clients) => ({
  type: ClientActionTypes.SET_SORTED_DATA,
  payload: clients,
});

export const setShowClientState = (state) => ({
  type: ClientActionTypes.SET_SHOW_CLIENT_STATE,
  payload: state,
});

export const setShowGroupState = (state) => ({
  type: ClientActionTypes.SET_SHOW_GROUP_STATE,
  payload: state,
});

export const setCurrentGroup = (state) => ({
  type: ClientActionTypes.SET_CURRENT_GROUP,
  payload: state,
});

export const setCurrentCoupon = (state) => ({
  type: ClientActionTypes.SET_CURRENT_COUPON,
  payload: state,
});

export const setAllClients = (clients) => ({
  type: ClientActionTypes.SET_ALL_CLIENTS,
  payload: clients,
});

export const setAllGroups = (groups) => ({
  type: ClientActionTypes.SET_ALL_GROUPS,
  payload: groups,
});

export const setShowAssignTrainer = (state) => ({
  type: ClientActionTypes.SHOW_ASSIGN_TRAINER,
  payload: state,
});

export const fetchTrainerInfo = (state) => ({
  type: ClientActionTypes.FETCH_TRAINER_INFO,
  payload: state,
});

export const setNewClientAdded = (state) => ({
  type: ClientActionTypes.SET_NEW_CLIENT_ADDED,
  payload: state,
});

export const initializeClientState = () => ({ type: ClientActionTypes.INITIALIZE_CLIENT_STATE });

/** the caller prop determines which url to use for client API, either the send a plan client list or the clients page list */
export const fetchAllClientsStartAsync = (caller) => {
  return (dispatch) => {
    dispatch(fetchClientsStart());

    const url = caller ? URLS.clientManagement : URLS.clients;

    API.get(url)
      .then((response) => {
        const data = response.data.reduce((object, client) => {
          return { ...object, [client.uuid]: client };
        }, {});
        dispatch(fetchClientsSuccess(data));
        dispatch(sortClientData(response.data));
      })
      .catch((error) => {
        dispatch(fetchClientsFailure(error));
      });
  };
};

/** the caller prop determines which url to use for client API, either the send a plan client list or the clients page list */
export const fetchSingleClientStartAsync = (uuid, caller) => {
  return (dispatch) => {
    const url = caller ? URLS.clientManagement : URLS.singleClient;

    dispatch(fetchSingleClientStart());
    dispatch(fetchProgressDataStart());
    API.get(`${url}${uuid}/`)
      .then((response) => {
        dispatch(fetchSingleClientSuccess(response.data));
        if (response.data.progress_data) dispatch(fetchProgressDataSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchProgressDataFailure(error));
        dispatch(fetchSingleClientFailure(error));
      });
  };
};

export const assignTrainerToClient = (uuid, data) => {
  return (dispatch, getState) => {
    const currentClientInList = getState().clients.allClients[uuid];
    const { allClients, currentClient } = getState().clients;
    const selectedTrainer = getState().trainers.allTrainers[data.trainer_uuid];

    API.patch(`${URLS.assignTrainer}${uuid}/`, data).then(() => {
      currentClientInList.trainer = selectedTrainer;
      currentClient.trainer = selectedTrainer;
      allClients[uuid] = currentClientInList;
      dispatch(setCurrentClient({ ...currentClient }));
      dispatch(setAllClients({ ...allClients }));
    });
  };
};

export const assignTrainerToGroup = (uuid, data) => {
  return (dispatch, getState) => {
    const currentGroupInList = getState().clients.allGroups[uuid];
    const { allGroups, groupId } = getState().clients;
    const selectedTrainer = getState().trainers.allTrainers[data.trainer_uuid];

    API.post(`${URLS.assignTrainerToGroup}`, data).then(() => {
      currentGroupInList.trainer = selectedTrainer;
      groupId.trainer = selectedTrainer;
      allGroups[uuid] = currentGroupInList;
      dispatch(setCurrentGroup({ ...groupId }));
      dispatch(setAllGroups({ ...allGroups }));
    });
  };
};

export const fetchAllGroups = () => {
  return (dispatch) => {
    dispatch(fetchGroupsStart());

    API.get(URLS.fetchAllGroups)
      .then((response) => {
        const updatedData = keysToCamel(response.data);
        dispatch(fetchGroupsSuccess(updatedData));
      })
      .catch((error) => {
        dispatch(fetchGroupsFailure(error));
      });
  };
};

export const fetchSingleGroupStartAsync = (uuid) => {
  return (dispatch) => {
    dispatch(fetchSingleGroupStart());
    API.get(`${URLS.fetchSingleGroup}${uuid}/`)
      .then((response) => {
        dispatch(fetchSingleGroupSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchSingleGroupFailure(error));
      });
  };
};

export const getTrainerInfo = () => {
  return (dispatch) => {
    API.get(URLS.getTrainerInfo).then((response) => {
      dispatch(fetchTrainerInfo(response.data));
    });
  };
};

export const generateCoupon = (data, setShowCouponPopup) => {
  return (dispatch) => {
    dispatch(generateCouponStart());
    API.post(`${URLS.generateCoupon}`, data)
      .then((response) => {
        dispatch(fetchAllGroups());
        dispatch(generateCouponSuccess(response.data));
        setShowCouponPopup(true);
      })
      .catch((error) => {
        dispatch(generateCouponFailure(error.message));
        setShowPopup(true);
      });
  };
};

export const updateRemainingSessions = (userId, remainingSessions) => {
  return (dispatch, getState) => {
    const { currentClient } = getState().clients;

    const { profile } = currentClient;

    dispatch(updateAvailableSessionStart());
    API.patch(`${URLS.updateRemainingSessions}${userId}/`, { remaining_sessions: remainingSessions })
      .then(() => {
        dispatch(updateAvailableSessionSuccess());
        dispatch(
          setPopupData({
            heading: 'Success!',
            message: `Remaining sessions successfully updated`,
          })
        );
        profile.remaining_sessions = remainingSessions;
        dispatch(setEditAvailableSessions(false));
        dispatch(setCurrentClient({ ...currentClient, profile }));
        dispatch(setShowPopup(true));
      })
      .catch((errorMessage) => {
        dispatch(
          setPopupData({
            heading: 'Failure',
            message: errorMessage ? errorMessage[0] : 'Oops something went wrong, please try again later',
          })
        );
        dispatch(setEditAvailableSessions(false));
        dispatch(setShowPopup(true));
        dispatch(updateAvailableSessionFailure());
      });
  };
};

export const toggleCouponCodeStartAsync = (uuid) => {
  return (dispatch) => {
    dispatch(toggleCouponCodeStart());
    API.patch(`${URLS.toggleCouponCode}${uuid}/`)
      .then((response) => {
        dispatch(toggleCouponCodeSuccess(response.data));
        dispatch(fetchAllGroups());
      })
      .catch((error) => {
        dispatch(toggleCouponCodeFailure(error.message));
      });
  };
};

export const setNewClientAddedStatus = (data) => {
  return (dispatch) => {
    if (!data) {
      API.post(URLS.clientNotificationSeen, data).then(() => {
        return dispatch(setNewClientAdded(data));
      });
    }
    dispatch(setNewClientAdded(data));
  };
};

export const clientSignedUp = () => {
  return API.post(URLS.clientSignedUp);
};
